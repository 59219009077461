import React, { useEffect, useRef, useState } from "react";

// icons
import { UploadCloud, Loader2 } from 'lucide-react';
import Modal from "Common/Components/Modal";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import {
    addBrand as onAddBrand,
    updateBrand as onUpdateBrand,
    uploadBrandImage
} from '../../../helpers/backend_helper';
import Dropzone from "react-dropzone";
import { RegionType } from "types/RegionType";
// fetch lists for countries, cities and states
import { getCities, getDistricts, getStates } from '../../../helpers/backend_helper';
import { Common } from "helpers/common";
import Select, { SelectInstance } from 'react-select';

type Props = { onHide: () => void; data: any, onSave: (result: any) => void }

const genderSelect = [
    { value: '', label: 'please select' },
    { value: 'Male', label: 'Boy' },
    { value: 'Female', label: 'Girl' },
    { value: 'Unisex', label: 'Unisex' }
];
const COUNTRYID = 1;//'f2b762bf-d8b7-4817-a752-3c02a7cbc5f7';


const AddBrand: React.FC<Props> = ({ onHide, data, onSave }) => {

    const districtRef = useRef<SelectInstance<RegionType> | null>(null)
    const cityRef = useRef<SelectInstance<RegionType> | null>(null)

    const [brandData, setBrandData] = useState<any>(data);

    const [states, setStates] = useState<RegionType[]>([]);
    const [districts, setDistricts] = useState<RegionType[]>([]);
    const [cities, setCities] = useState<RegionType[]>([]);

    const [isSaving, setIsSaving] = useState<boolean>(false);

    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedFiles, setSelectedFiles] = React.useState<any>([])
    const [selectedState, setSelectedState] = useState<any>();
    const [selectedCity, setSelectedCity] = useState<any>();
    const [selectedDistrict, setSelectedDistrict] = useState<any>();

    // Image
    const [selectedImage, setSelectedImage] = useState<any>();
    const commonHelper = new Common();

    const handleAcceptedFiles = (files: any) => {
        files.map((file: any) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: commonHelper.formatBytes(file.size),
            })
        )
        setSelectedFiles(files)
        setSelectedImage(files[0]);
        validation.setFieldValue('logo', 'htttp://sample.com');
    }


    useEffect(() => {
        if (brandData?.id) {
            setIsEdit(brandData?.id);
            let obj = {
                preview: brandData.logo,
                formattedSize: '',
                name: ''
            }
            setSelectedFiles((prev: any) => [{ ...obj }])
        }
        loadStates();

    }, [])

    const loadStates = async () => {
        if (states.length === 0) {

            let response = await getStates();
            console.log('states', response)

            if (response.success) {
                const items = [{ value: '', label: 'Select' }, ...(response.data as any[]).map(item => ({ value: item.id, label: item.name }))]
                setStates(items);
                if (brandData?.stateId) {
                    setSelectedState(items?.find(i => i.value == brandData?.stateId));
                }
            }

            if (brandData?.id) {
                await loadDistrict(brandData.stateId);
                await loadCities(brandData.districtId);
            }
        }
    }
    const loadDistrict = async (stateId: string) => {
        if (!stateId)
            return;

        var params = 'value=' + stateId;
        console.log(params);
        try {
            let response = await getDistricts(params)
            console.log('districts', response)

            if (response.success) {
                const items = [{ value: '', label: 'Select' }, ...(response.data as any[]).map(item => ({ value: item.id, label: item.name }))]
                setDistricts(items);
                if (brandData?.districtId) {
                    setSelectedDistrict(items?.find(i => i.value == brandData?.districtId));
                }
            }
        } catch (err) {
            console.log(err);
            commonHelper.showErrorMessage('No district found for selected state.')
        }
    }

    const loadCities = async (districtId: string) => {
        if (!districtId)
            return;

        var params = 'value=' + districtId;
        try {

            let response = await getCities(params)
            console.log('cities', response)

            if (response.success) {
                const items = [{ value: '', label: 'Select' }, ...(response.data as any[]).map(item => ({ value: item.id, label: item.name }))]
                setCities(items);
                if (brandData?.cityId) {
                    setSelectedCity(items?.find(i => i.value == brandData?.cityId));
                }
            }
        } catch (err) {
            console.log(err);
            commonHelper.showErrorMessage('No city found for selected district.')
        }
    }

    const handleStateSelection = async (item: any) => {
        districtRef?.current?.clearValue();
        setSelectedState(item);
        setDistricts([]);
        setSelectedDistrict({});
        validation.setFieldValue(
            'stateId',
            item.value
        );

        loadDistrict(item.value)
    }

    const handleDistrictSelection = async (item: any) => {
        if (item) {
            cityRef?.current?.clearValue();
            setSelectedDistrict(item);
            setSelectedCity({} as RegionType);
            setCities([]);
            validation.setFieldValue(
                'districtId',
                item.value
            )
            loadCities(item.value);
        }
    }

    const handleCitySelection = (item: any) => {
        setSelectedCity(item);

        if (item) {
            console.log('selected state', item.value)
            validation.setFieldValue('cityId', item.value);
        }
    }

    // validation
    const validation: any = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            logo: (brandData && brandData.logo) || '',
            description: (brandData && brandData.description) || '',
            name: (brandData && brandData.name) || '',
            address1: (brandData && brandData.address1) || '',
            address2: (brandData && brandData.address2) || '',
            website: (brandData && brandData.website) || '',
            cityId: (brandData && brandData.cityId) || '',
            stateId: (brandData && brandData.stateId) || '',
            districtId: (brandData && brandData.districtId) || '',
            contact: (brandData && brandData.contact) || '',
            phone: (brandData && brandData.phone) || '',
            gendersegment: (brandData && brandData.gendersegment) || '',
            minordervalue: (brandData && brandData.minordervalue) || '',
            zip: (brandData && brandData.zip) || '',
            isActive: (brandData && brandData.isActive) || true,
            countryId: COUNTRYID,
            discount: (brandData && brandData.discount) || 0
        },
        validationSchema: Yup.object({
            logo: Yup.string().required("Please add logo"),
            description: Yup.string().required("Please enter company details").max(500),
            name: Yup.string().required("Please enter company name").max(100),
            website: Yup.string().required("Please enter website").max(100),
            cityId: Yup.string().required("Please select city"),
            stateId: Yup.string().required("Please select state"),
            districtId: Yup.string().required("Please select district"),
            contact: Yup.string().required("Please enter contact").max(50),
            phone: Yup.string().required("Please enter phone").min(10).max(10),
            minordervalue: Yup.number().required("Please enter minimum order amount").min(0).max(100000),
            gendersegment: Yup.string().required("Please select gender segment"),
            isActive: Yup.boolean().required("Please choose active"),
            zip: Yup.string().required("Please enter zip code").min(6).max(10),
            address1: Yup.string().required("Please enter address 1").min(5).max(50),
            address2: Yup.string().required("Please enter address 2"),
        }),

        onSubmit: async (values: any) => {
            setIsSaving(true);
            let logo = null;
            console.log(selectedImage)

            // upload if any new images is selected..
            if (selectedImage?.name) {
                var imgResult = await uploadBrandImage({ fileCollection: selectedImage });
                console.log(imgResult);
                logo = imgResult?.data.rows[0].url;
            }

            let dataToSave = {
                ...values,
                logo: logo ?? values.logo
            };

            if (isEdit && brandData) {
                dataToSave = { ...dataToSave, id: brandData.id }
            }

            try {
                var result = await (isEdit ? onUpdateBrand(dataToSave) : onAddBrand(dataToSave));

                commonHelper.showSuccessMessage(`Brand has been successfully ${isEdit ? "updated" : "added"}.`);

                onSave(result.data.rows[0]);

            } catch (error) {
                console.log('API-Error', error);
                commonHelper.showErrorMessage(error as string);
                setIsSaving(false);
            }

            setIsEdit(false);
            setIsSaving(false);
        },

    });

    return (
        <React.Fragment>
            <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
                <Modal.Title className="text-16">{!!isEdit ? "Edit Brand" : "Add Brand"}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
                <form action="#!" onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                    <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                        <div className="xl:col-span-6">
                            <label htmlFor="name" className="text-base font-medium">Company Name<span className='redColor'>*</span></label>
                            <input type="text" id="name" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Company Name"
                                name="name"

                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""}
                            />
                            {validation.touched.name && validation.errors.name ? (
                                <p className="text-red-400">{validation.errors.name}</p>
                            ) : null}
                        </div>


                        <div className="xl:col-span-6">
                            <label htmlFor="website" className="inline-block mb-1 text-base font-medium">Website<span className='redColor'>*</span></label>
                            <input id="website" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Website"
                                name="website"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.website || ""}
                            />
                            {validation.touched.website && validation.errors.website ? (
                                <p className="text-red-400">{validation.errors.website}</p>
                            ) : null}
                        </div>
                        <div className="xl:col-span-12">
                            <label htmlFor="description" className="inline-block mb-1 text-base font-medium">Company Details<span className='redColor'>*</span></label>
                            <textarea id="description" 
                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" 
                            placeholder="Description"
                                name="description"
                                rows={1}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.description || ""}
                            />
                            {validation.touched.description && validation.errors.description ? (
                                <p className="text-red-400">{validation.errors.description}</p>
                            ) : null}
                        </div>


                        <div className="xl:col-span-6">
                            <label htmlFor="address1" className="inline-block mb-1 text-base font-medium">Address 1<span className='redColor'>*</span></label>
                            <input id="address1" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Address1"
                                name="address1"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address1 || ""}
                            />
                            {validation.touched.address1 && validation.errors.address1 ? (
                                <p className="text-red-400">{validation.errors.address1}</p>
                            ) : null}
                        </div>
                        <div className="xl:col-span-6">
                            <label htmlFor="address2" className="inline-block mb-1 text-base font-medium">Address 2<span className='redColor'>*</span></label>
                            <input id="address2" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Address2"
                                name="address2"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.address2 || ""}
                            />
                            {validation.touched.address2 && validation.errors.address2 ? (
                                <p className="text-red-400">{validation.errors.address2}</p>
                            ) : null}
                        </div>

                        <div className="xl:col-span-4">
                            <label htmlFor="stateId" className="inline-block mb-2 text-base font-medium">State<span className='redColor'>*</span></label>

                            {/* <select className='form-select' data-choices data-choices-sorting-false id="state" name="state"
                                value={validation.values.state}
                                onBlur={validation.handleBlur}
                                onChange={e => { handleStateSelection(e) }}>

                                {
                                    states.map((item, i) =>
                                        <option key={i} value={item.id}> {item.name} </option>
                                    )
                                }
                            </select> */}

                            <Select
                                className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                defaultValue={selectedState}
                                options={states}
                                isSearchable={true} // To disable search
                                name="stateId"
                                id="stateId"
                                value={selectedState}
                                onChange={e => { handleStateSelection(e) }}
                            />


                        </div>
                        <div className="xl:col-span-4">
                            <label htmlFor="districtId" className="inline-block mb-2 text-base font-medium">District<span className='redColor'>*</span></label>
                            <Select
                                className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                options={districts}
                                ref={districtRef}
                                isSearchable={true} // To disable search
                                name="districtId"
                                id="districtId"
                                value={selectedDistrict}
                                onChange={e => { handleDistrictSelection(e) }}
                            />


                        </div>
                        <div className="xl:col-span-4">
                            <label htmlFor="cityId" className="inline-block mb-2 text-base font-medium">City<span className='redColor'>*</span></label>
                            <Select
                                className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                options={cities}
                                ref={cityRef}
                                isSearchable={true} // To disable search
                                name="cityId"
                                id="cityId"
                                value={selectedCity}
                                onChange={e => { handleCitySelection(e) }}
                            />

                        </div>
                        <div className="xl:col-span-4">
                            <label htmlFor="zip" className="inline-block text-base font-medium">Zip<span className='redColor'>*</span></label>
                            <input id="zip" type="number" maxLength={1} className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Phone"
                                name="zip"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.zip || ""}
                            />
                            {validation.touched.zip && validation.errors.zip ? (
                                <p className="text-red-400">{validation.errors.zip}</p>
                            ) : null}
                        </div>
                        <div className="xl:col-span-4">
                            <label htmlFor="contact" className="inline-block mb-1 text-base font-medium">Contact<span className='redColor'>*</span></label>
                            <input id="contact" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Contact"
                                name="contact"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.contact || ""}
                            />
                            {validation.touched.contact && validation.errors.contact ? (
                                <p className="text-red-400">{validation.errors.contact}</p>
                            ) : null}
                        </div>
                        <div className="xl:col-span-4">
                            <label htmlFor="phone" className="inline-block text-base font-medium">Phone<span className='redColor'>*</span></label>
                            <input id="phone" type="number" maxLength={1} className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="phone"
                                name="phone"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.phone || ""}
                            />
                            {validation.touched.phone && validation.errors.phone ? (
                                <p className="text-red-400">{validation.errors.phone}</p>
                            ) : null}
                        </div>

                        <div className="xl:col-span-6">
                            <label htmlFor="minordervalue" className="inline-block text-base font-medium">Minimum Order Amount<span className='redColor'>*</span></label>
                            <input id="minordervalue" type="number" maxLength={1}
                                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                placeholder="minimum order value"
                                name="minordervalue"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.minordervalue || ""}
                            />
                            {validation.touched.minordervalue && validation.errors.minordervalue ? (
                                <p className="text-red-400">{validation.errors.minordervalue}</p>
                            ) : null}
                        </div>
                        <div className="xl:col-span-6 row-span-3">
                            <label htmlFor="selectedImage" className="inline-block mb-2 text-base font-medium">Brand Image</label> 
                            <div className="flex items-center justify-center bg-white border border-dashed rounded-md cursor-pointer  border-slate-300 dark:bg-zink-700 dark:border-zink-500">
                                <Dropzone
                                    onDrop={(acceptedFiles: any) => {
                                        handleAcceptedFiles(acceptedFiles)
                                    }}
                                >
                                    {({ getRootProps, getInputProps }: any) => (
                                        <div
                                            className="w-full py-5 text-lg text-center dz-message needsclick"
                                            {...getRootProps()}
                                        >
                                            <input {...getInputProps()} />
                                            <div className="mb-3">
                                                <UploadCloud className="block size-12 mx-auto text-slate-500 fill-slate-200 dark:text-zink-200 dark:fill-zink-500"></UploadCloud>
                                            </div>

                                            <h5 className="mb-0 font-normal text-slate-500 text-15">Drag and drop brand image or <a href="#!">browse</a></h5>
                                        </div>
                                    )}
                                </Dropzone>
                                <ul className="flex flex-wrap mb-0 gap-x-5" id="dropzone-preview2">
                                    {
                                        (selectedFiles || [])?.map((f: any, i: any) => {
                                            return (
                                                <li className="mt-2" id="dropzone-preview-list" key={i + "-file"}>
                                                    <div className="border rounded border-slate-200 dark:border-zink-500">
                                                        <div className="p-2 text-center">
                                                            <div>
                                                                <div className="p-2 mx-auto rounded-md size-14 bg-slate-100 dark:bg-zink-600">
                                                                    <img data-dz-thumbnail className="block w-full h-full rounded-md" src={f.preview} alt={f.name} />
                                                                </div>
                                                            </div>
                                                            <div className="mt-2">
                                                                <button data-dz-remove
                                                                    className="px-2 py-1.5 text-xs text-white bg-red-500 border-red-500 btn hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:border-red-600 active:ring active:ring-red-100 dark:ring-custom-400/20"
                                                                    onClick={() => {
                                                                        const newImages = [...selectedFiles];
                                                                        newImages.splice(i, 1);
                                                                        setSelectedFiles(newImages);
                                                                    }}
                                                                >Delete</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>


                            <input type="hidden" id="logo" name="logo" />
                            {validation.touched.logo && validation.errors.logo ? (
                                <div id="logo-error" className="mt-1 text-sm text-red-500">{validation.errors.logo}</div>
                            ) : null}
                        </div>

                        <div className="xl:col-span-6">
                            <label htmlFor="gendersegment" className="inline-block text-base font-medium">Gender Segment<span className='redColor'>*</span></label>
                            <select className='form-select border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"' name="gendersegment"
                                id="gendersegment"
                                value={validation.values.gendersegment || ""}
                                onBlur={validation.handleBlur}
                                onChange={validation.handleChange} >
                                {
                                    genderSelect.map((item, i) =>
                                        <option key={i} value={item.value}> {item.label} </option>
                                    )
                                }
                            </select>
                            {validation.touched.gendersegment && validation.errors.gendersegment ? (
                                <p className="text-red-400">{validation.errors.gendersegment}</p>
                            ) : null}
                        </div>
                        <div className="xl:col-span-6">
                            <label htmlFor="isActive" className="inline-block  text-base font-medium">IsActive</label> <br></br>
                            <div className="shrink-0">
                                <div className="relative inline-block w-10 align-middle transition duration-200 ease-in">
                                    <input type="checkbox" name="isActive"
                                        id="isActive"
                                        className="absolute block size-5 transition duration-300 ease-linear border-2 rounded-full appearance-none cursor-pointer border-slate-200 dark:border-zink-600 bg-white/80 dark:bg-zink-400 peer/published checked:bg-custom-500 dark:checked:bg-custom-500 ltr:checked:right-0 rtl:checked:left-0 checked:border-custom-100 dark:checked:border-custom-900 arrow-none checked:bg-none"
                                        title="isActive"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.isActive || true} />
                                    {validation.touched.isActive && validation.errors.isActive ? (
                                        <div id="isActive-error" className="mt-1 text-sm text-red-500">{validation.errors.isActive}</div>
                                    ) : null}
                                    <label htmlFor="carePackage" className="block h-5 overflow-hidden duration-300 ease-linear border rounded-full cursor-pointer cursor-pointertransition border-slate-200 dark:border-zink-500 bg-slate-200 dark:bg-zink-600 peer-checked/published:bg-custom-100 dark:peer-checked/published:bg-custom-900 peer-checked/published:border-custom-100 dark:peer-checked/published:border-custom-900"></label>
                                </div>
                            </div>
                        </div>
                        <div className="xl:col-span-6">
                            <label htmlFor="discount" className="inline-block text-base font-medium">Discount %<span className='redColor'>*</span></label>
                            <input id="discount" type="number" maxLength={1}
                                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                placeholder="discount percent"
                                name="discount"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.discount || ""}
                            />
                            {validation.touched.discount && validation.errors.discount ? (
                                <p className="text-red-400">{validation.errors.discount}</p>
                            ) : null}
                        </div>


                    </div>
                    <div className="flex justify-end gap-2 mt-4">
                        <button type="reset" data-modal-close="addDocuments" className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500" onClick={onHide}>Cancel</button>

                        {!isSaving ?
                            <button type="submit" className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                {!!isEdit ? "Update Brand" : "Add Brand"}
                            </button>
                            : <button type="button" disabled className="flex items-center text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                <Loader2 className="size-4 ltr:mr-2 rtl:ml-2 animate-spin" />
                                Saving...
                            </button>
                        }
                    </div>
                </form>
            </Modal.Body>
        </React.Fragment>
    );
};

export default AddBrand;




