import React, { useEffect, useState } from 'react'
import Modal from 'Common/Components/Modal';
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import { Common } from 'helpers/common';
import {
    addCMSList as onAddCMSList,
    updateCMSList as onUpdateCMSList,
} from '../../../helpers/backend_helper';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'; import { Loader2 } from 'lucide-react';

type Props = { onHide: () => void; data: any, onSave: (result: any) => void }

const AddCMS: React.FC<Props> = ({ onHide, data, onSave }) => {

    const [CMSData, setCMSData] = useState<any>(data);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [editorData, setEditorData] = useState('<br/><br/>');
    const commonHelper = new Common();

    useEffect(() => {
        if (CMSData?.id) {
            setIsEdit(CMSData?.id);
            setEditorData(CMSData.description);
        }
    }, [])



    // validation
    const validation: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: (CMSData && CMSData.title) || '',
            description: (CMSData && CMSData.description) || '',
            url: (CMSData && CMSData.url) || '',
            isActive: (CMSData && CMSData.isActive) || true,
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Please Enter Title !"),
            description: Yup.string().required("Please Enter Description !"),
            url: Yup.string().required("Please Enter URL !"),
            isActive: Yup.string().required("Please choose yes or no!")
        }),

        onSubmit: async (values: any) => {
            setIsSaving(true);

            if (isEdit && CMSData) {
                try {
                    // update user
                    const updateData = {
                        ...values,
                        id: CMSData.id,
                    };

                    var result = await onUpdateCMSList(updateData);
                    commonHelper.showSuccessMessage('CMS has been successfully updated.');
                    onSave(result.data.rows[0]);

                } catch (error) {
                    console.log('API-Error', error);
                    commonHelper.showErrorMessage(error as string);
                    setIsSaving(false);
                }

                setIsEdit(false);
            } else {
                // save new user

                try {
                    const newData = { ...values };
                    var result = await onAddCMSList(newData);
                    commonHelper.showSuccessMessage('CMS has been successfully saved.');
                    onSave(result.data.rows[0]);
                } catch (error) {
                    console.log('API-Error', error);
                    commonHelper.showErrorMessage(error as string);
                    setIsSaving(false);
                }
            }
            setIsSaving(false);
        },

    });

    const onEditorChange = (event: any, editor: any) => {
   //     console.log('Editor content:', editor.getData());
        validation.setFieldValue('description', editor.getData())
   };

    return (
        <React.Fragment>
            <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
                <Modal.Title className="text-16">{!!isEdit ? "Edit CMS" : "Add CMS"}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">

                <form action="#!" onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>

                            <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-6">
                                <div className="lg:col-span-2 xl:col-span-12">
                                    <label htmlFor="title" className="inline-block mb-2 text-base font-medium">Title</label>
                                    <input type="text" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                        id="title"
                                        name="title"
                                        placeholder="Title"

                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.title || ""} />
                                    {validation.touched.title && validation.errors.title ? (
                                        <div id="title-error" className="mt-1 text-sm text-red-500">{validation.errors.title}</div>
                                    ) : null}
                                </div>

                                <div className="lg:col-span-2 xl:col-span-12">
                                    <label htmlFor="title" className="inline-block mb-2 text-base font-medium">Url</label>
                                    <input type="text" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                        id="url"
                                        name="url"
                                        placeholder="url"

                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.url || ""} />
                                    {validation.touched.url && validation.errors.url ? (
                                        <div id="url-error" className="mt-1 text-sm text-red-500">{validation.errors.url}</div>
                                    ) : null}
                                </div>

                                <div className="lg:col-span-2 xl:col-span-12">
                                    <h6 className="mb-4 text-15">Description</h6>
                                    <div className="ckeditor-classic text-slate-800">
                                        <CKEditor
                                            id="description1"
                                            editor={ClassicEditor}
                                            onChange={onEditorChange}
                                            data={editorData}
                                        />
                                        {validation.touched.description && validation.errors.description ? (
                                            <div id="title-error" className="mt-1 text-sm text-red-500">{validation.errors.description}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>

                            <div className="flex justify-end gap-2 mt-4">
                                <button type="reset" data-modal-close="addDocuments" className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500" onClick={onHide}>Cancel</button>

                                {!isSaving ?
                                    <button type="submit" className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                        {!!isEdit ? "Update CMS" : "Add CMS"}
                                    </button>
                                    : <button type="button" disabled className="flex items-center text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                        <Loader2 className="size-4 ltr:mr-2 rtl:ml-2 animate-spin" />
                                        Saving...
                                    </button>
                                }
                          
                    </div>
                </form>
            </Modal.Body>
        </React.Fragment>
    )
}

export default AddCMS;