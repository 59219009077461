import Modal from "Common/Components/Modal";
import React, { useEffect, useRef, useState } from "react";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import {
  getStateList,
  addCityList,
  getCityList,
  updateCityList,
  getDistrictList,
} from "../../../helpers/backend_helper";
import { Common } from "helpers/common";
import { Loader2, UploadCloud } from "lucide-react";
import Select, { SelectInstance } from "react-select";
import { CityType } from "../../../types/CityType";
import { RegionType } from "../../../types/RegionType";
import { DistrictType } from "../../../types/DistrictType";


type Props = { onHide: () => void; selectedState: RegionType; data: any; onSave: (result: any) => void, allDistricts: DistrictType[], selectedDist: RegionType };

export const AddCity: React.FC<Props> = ({ onHide, data, onSave, selectedState, allDistricts , selectedDist}) => {
  const [stateList, setStateList] = useState<RegionType[]>([]);
  // const [selectedState, setSelectedState] = useState<RegionType>();
  const [districtOptions, setDistrictOptions] = useState<RegionType[]>([]);
  // const [allDistricts, setAllDistricts] = useState<DistrictType[]>([]);
  const [cityData, setCityData] = useState<CityType>(data);
  const [selectedDistrict, setSelectedDistrict] = useState<RegionType | null>(selectedDist);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const commonHelper = new Common();

  const cityInput = useRef<any>({ focus: () => { } } as any);

  const districtRef = useRef<SelectInstance<RegionType> | null>(null)

  useEffect(() => {
    onEdit();
    setStateList(selectedState ? [selectedState] : []);
  }, [selectedState]);

  useEffect(() => {
    setDistrictOptions([]);
    const data = allDistricts.filter((item: DistrictType) => item.statesDistricts?.id === selectedState?.value);

    const options = [{ value: "", label: "Select" }, ...(data as any[]).map(item => ({ value: item.id, label: item.name } as RegionType))];
    console.log(`Options`, options);
    setDistrictOptions(options);
    if (cityData?.id) {
      const selectedDist = options.find((item: RegionType) => item.value === cityData.districtId);
      setSelectedDistrict(selectedDist || null);
    }

    if(selectedDist?.value){
      cityInput.current.focus();

    }

  }, [allDistricts]);



  const onEdit = async () => {
    try {
      const id = cityData?.id ?? "0";
      console.log(id);
      if (id != "0") {
        setIsEdit(true);
      }
    } catch (err) { }
    //}
  };



  const handleDistrictChange = (item: any) => {
    setSelectedDistrict(item);
    validation.setFieldValue("districtId", item.value);
    console.log("Selected District Value", item);
  };

  // validation
  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      stateId: (selectedState && selectedState.value) || "",
      districtId: (cityData && cityData.districtId) || selectedDistrict?.value,
      name: (cityData && cityData.name) || "",
      isActive: (cityData && cityData.isActive) || true,
    },
    validationSchema: Yup.object({
      stateId: Yup.string().required("Please select a state!"),
      districtId: Yup.string().required("Please select a District!"),
      name: Yup.string().required("Please enter a city!"),
      isActive: Yup.string().required("Please choose yes or no!"),
    }),

    onSubmit: async (values: any) => {
      setIsSaving(true);

      console.log(values);
      let dataToSave = {
        ...values,
      };

      if (isEdit && cityData) {
        dataToSave = { ...dataToSave, id: cityData.id };
      }

      try {
        var result = await (isEdit
          ? updateCityList(dataToSave)
          : addCityList(dataToSave));

        commonHelper.showSuccessMessage(
          `City has been successfully ${isEdit ? "updated" : "added"}.`
        );
        const updated = {
          ...result.data.rows[0],
          'district': {
            id: selectedDistrict?.value,
            name: selectedDistrict?.label,
          },
          'state': { id: selectedState?.value, name: selectedState?.label },
        };

        onSave(updated);
        setIsEdit(false);
        // console.log(result.data.rows[0]);
        // console.log(district);
      } catch (error: any) {
        console.log("API-Error", error);
        commonHelper.showErrorMessage(error?.response?.data?.message);
      }
      setIsSaving(false);
    },
  });

  return (
    <React.Fragment>
      <Modal.Header
        className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
        closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
      >
        <Modal.Title className="text-16">
          {!!isEdit ? "Edit City" : "Add City"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
        <form
          action="#!"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-6">
            <div className="lg:col-span-2 xl:col-span-12">
              <label
                htmlFor="stateId"
                className="inline-block mb-2 text-base font-medium"
              >
                State
              </label>
              <Select
                className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                options={stateList}
                isSearchable={false} // To disable search
                value={selectedState}
                isDisabled={true}
              // onChange={(e) => {
              //   handleStateChange(e);
              // }}
              ></Select>
              {validation.touched.stateId && validation.errors.stateId ? (
                <div id="stateId-error" className="mt-1 text-sm text-red-500">
                  {validation.errors.stateId}
                </div>
              ) : null}
            </div>
          </div>

          <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-6">
            <div className="lg:col-span-2 xl:col-span-12">
              <label
                htmlFor="districtId"
                className="inline-block mb-2 text-base font-medium"
              >
                District
              </label>
              <Select
                className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                options={districtOptions}
                ref={districtRef}

                isSearchable={true} // To disable search
                value={selectedDistrict}
                onChange={(e) => {
                  handleDistrictChange(e);
                }}
              ></Select>

              {validation.touched.districtId &&
                validation.errors.districtId ? (
                <div
                  id="districtId-error"
                  className="mt-1 text-sm text-red-500"
                >
                  {validation.errors.districtId}
                </div>
              ) : null}
            </div>
          </div>
          <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-6">
            <div className="lg:col-span-2 xl:col-span-12">
              <label
                htmlFor="name"
                className="inline-block mb-2 text-base font-medium"
              >
                City
              </label>
              <input
              ref={cityInput}

                type="text"
                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                id="name"
                name="name"
                placeholder="City"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.name || ""}
              />

              {validation.touched.name && validation.errors.name ? (
                <div id="name-error" className="mt-1 text-sm text-red-500">
                  {validation.errors.name}
                </div>
              ) : null}
            </div>

            <div className="lg:col-span-2 xl:col-span-12">
              <label
                htmlFor="isActive"
                className="inline-block mb-2 text-base font-medium"
              >
                IsActive
              </label>{" "}
              <br></br>
              <div className="relative inline-block w-10 align-middle transition duration-200 ease-in ltr:mr-2 rtl:ml-2">
                <input
                  type="checkbox"
                  name="isActive"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.isActive}
                  id="isActive"
                  className="absolute block size-5 transition duration-300 ease-linear border-2 rounded-full appearance-none cursor-pointer border-slate-200 dark:border-zink-500 bg-white/80 dark:bg-zink-400 peer/published checked:bg-white dark:checked:bg-white ltr:checked:right-0 rtl:checked:left-0 checked:bg-none checked:border-custom-500 dark:checked:border-custom-500 arrow-none"
                  defaultChecked
                />
                <label
                  htmlFor="customDefaultSwitch"
                  className="block h-5 overflow-hidden duration-300 ease-linear border rounded-full cursor-pointer cursor-pointertransition border-slate-200 dark:border-zink-500 bg-slate-200 dark:bg-zink-600 peer-checked/published:bg-custom-500 peer-checked/published:border-custom-500"
                ></label>

                {validation.touched.isActive && validation.errors.isActive ? (
                  <div
                    id="isActive-error"
                    className="mt-1 text-sm text-red-500"
                  >
                    {validation.errors.isActive}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="flex justify-end gap-2 mt-4">
            <button
              type="reset"
              data-modal-close="addDocuments"
              className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
              onClick={onHide}
            >
              Cancel
            </button>

            {!isSaving ? (
              <button
                type="submit"
                className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {!!isEdit ? "Update City" : "Add City"}
              </button>
            ) : (
              <button
                type="button"
                disabled
                className="flex items-center text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                <Loader2 className="size-4 ltr:mr-2 rtl:ml-2 animate-spin" />
                Saving...
              </button>
            )}
          </div>
        </form>
      </Modal.Body>
    </React.Fragment>
  );
};
