import React from 'react';
import './assets/scss/themes.scss';
import RouteIndex from 'Routes/Index';

// import fakeBackend from "./helpers/AuthType/fakeBackend";

// Activating fake backend
//fakeBackend();


function setActivityTimestamp() {
  localStorage.setItem('lastActivity', Date.now().toString());
}

document.addEventListener('click', setActivityTimestamp);
document.addEventListener('keydown', setActivityTimestamp);
document.addEventListener('mousemove', setActivityTimestamp);

function checkActivity() {
  const lastActivity = localStorage.getItem('lastActivity');
  if (lastActivity) {
      const currentTime = Date.now();
      const elapsed = currentTime - parseInt(lastActivity);
      console.log(lastActivity,currentTime,elapsed);

      const maxInactiveTime = 15 * 60 * 1000; // 15 minutes in milliseconds
      if (elapsed > maxInactiveTime) {
          localStorage.clear();
          console.log('Local storage cleared due to inactivity.');
      }
  }
}

// Check every minute
setInterval(checkActivity, 60 * 1000);

document.addEventListener('DOMContentLoaded', () => {
  setActivityTimestamp(); // Set initial timestamp
  checkActivity(); // Check immediately on load
});



function App() {
  return (
    <RouteIndex />
  );
}

export default App;