import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import CountUp from 'react-countup';
import { format } from 'date-fns';// Icons
import { Loader as LoaderWithMessage } from "../../../Common/Loader";
import moment from "moment";

// icons
import { Boxes, PackagePlus, Loader, Search, Truck, PackageCheck, PackageX, Plus, RefreshCcw, MoreHorizontal, Trash2, Eye, FileEdit, Pencil } from 'lucide-react';
import { OrdersOverviewChart } from "../charts";
import { Link } from "react-router-dom";
import TableContainer from "Common/TableContainer";

import DeleteModal from "Common/DeleteModal";
import Modal from "Common/Components/Modal";


import { ToastContainer } from "react-toastify";
import filterDataBySearch from "Common/filterDataBySearch";
import { getMonthlyOrderCounts, getOrders, getTotalOrderCounts } from "helpers/backend_helper";
import { OrderStatus } from "./OrderStatus";
import { Common } from 'helpers/common';
import { TotalOrderCount } from "types/OrderType";


const Orders = () => {
    const uiHelper = new Common();

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [data, setData] = useState<any>([]);
    const [dataList, setDataList] = useState<any>([]);
    const [eventData, setEventData] = useState<any>();

    const [show, setShow] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [totalOrderCounts, setTotalOrderCounts] = useState<TotalOrderCount>({} as TotalOrderCount);
    const [monthlyOrderCounts, setMonthlyOrderCounts] = useState<number[]>([]);

    // Get Data
    useEffect(() => {
        onGetOrders();
    }, []);


    // Delete Modal

    const onGetOrders = async () => {
        try {
            const response = await getOrders();
            const orderHistory = response.data.sort((a: any, b: any) => (a.updatedAt > b.updatedAt ? -1 : 1));
            setData(orderHistory);
            setDataList(orderHistory);

            // load total order count
            const responseTotalOrders = await getTotalOrderCounts();
            //console.log(responseTotalOrders);
            var orderCounts = {} as TotalOrderCount;

            orderCounts.newOrders = responseTotalOrders.data.find(p => p.status === 'Order Placed')?.ordercount;
            let deliveredOrders = responseTotalOrders.data.find(p => p.status === 'Order Delivered')?.ordercount;
            let completedOrders = responseTotalOrders.data.find(p => p.status === 'Order Completed')?.ordercount;
            orderCounts.deliveredOrders = parseInt(deliveredOrders || 0) + parseInt(completedOrders || 0);
            orderCounts.pendingOrders = responseTotalOrders.data.find(p => p.status === 'Order Processing')?.ordercount;
            orderCounts.shippedOrders = responseTotalOrders.data.find(p => p.status === 'Order Shipped')?.ordercount;

            const sum = responseTotalOrders.data.reduce((accumulator, object) => {
                return parseInt(accumulator) + parseInt(object.ordercount);
            }, 0);

            orderCounts.totalOrders = sum;
            setTotalOrderCounts(orderCounts);

            // monthly order counts
            // load total order count
            const responseMonthlyOrdes = await getMonthlyOrderCounts();

            let monthsCount = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
            console.log(responseMonthlyOrdes)

            responseMonthlyOrdes.data.forEach((element: any) => {
                var momentDate = moment(element.month)

                const month = momentDate.month();
                monthsCount[month - 1] = parseInt(element.orderCount);
            });

            setMonthlyOrderCounts(monthsCount);
            console.log(monthsCount);

            setIsLoading(false);

        } catch (err) { }
    };



    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const deleteToggle = () => setDeleteModal(!deleteModal);

    const onOrderStatusSave = (result: any) => {
        if (result) {
            toggle();
            uiHelper.showSuccessMessage('Order status has been updated successfully.');
            onGetOrders();

        } else {
            uiHelper.showErrorMessage('Something went wrong. Please try again later.');
        }

    }

    // Delete Data
    const onClickDelete = (cell: any) => {
        setDeleteModal(true);
        if (cell.id) {
            setEventData(cell);
        }
    };

    const handleDelete = () => {
        if (eventData) {
            // dispatch(onDeleteOrders(eventData.id));
            alert('method not implemented yet');
            setDeleteModal(false);
        }
    };
    // 

    // Update Data
    const handleUpdateDataClick = (ele: any) => {
        setEventData({ ...ele });
        setIsEdit(true);
        setShow(true);
    };


    // 
    const toggle = useCallback(() => {
        if (show) {
            setShow(false);
            setEventData("");
            setIsEdit(false);
        } else {
            setShow(true);
            setEventData("");

        }
    }, [show]);

    // Search Data
    const filterSearchData = (e: any) => {
        const search = e.target.value;
        const keysToSearch = ['orderId', 'customerName', 'paymentMethod', 'deliveryStatus'];
        filterDataBySearch(dataList, search, keysToSearch, setData);
    };

    const [activeTab, setActiveTab] = useState("1");

    const toggleTab = (tab: any, type: any) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
            let filteredOrders = dataList;

            // filter by status
            if (type !== "all") {

                switch (tab) {
                    case "2":
                        filteredOrders = dataList.filter((order: any) => (order.orderStatus === 'Order Placed' || order.orderStatus === 'Order Accepted' || order.orderStatus === 'Order Processing'));
                        break;
                    case "3":
                        filteredOrders = dataList.filter((order: any) => (order.orderStatus === 'Order Completed' || order.orderStatus === 'Order Delivered'));
                        break;
                    case "4":
                        filteredOrders = dataList.filter((order: any) => (order.orderStatus === 'Order Returned'));
                        break;

                    case "5":
                        filteredOrders = dataList.filter((order: any) => (order.orderStatus === 'Order Canceled'));
                        break;

                    default:
                        break;
                }
                // filteredOrders = dataList.filter((order: any) => order.orderStatus === type);
            }
            setData(filteredOrders);
        }
    };

    // columns
    const Status = ({ item }: any) => {
        switch (item) {
            case "Delivered":
                return (<span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-green-100 border-green-200 text-green-500 dark:bg-green-500/20 dark:border-green-500/20">{item}</span>);
            case "Order Shipped":
                return (<span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-purple-100 border-purple-200 text-purple-500 dark:bg-purple-500/20 dark:border-purple-500/20">{item}</span>);
            case "Order Placed":
                return (<span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-sky-100 border-sky-200 text-sky-500 dark:bg-sky-500/20 dark:border-sky-500/20">{item}</span>);
            case "Pending":
                return (<span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-yellow-100 border-yellow-200 text-yellow-500 dark:bg-yellow-500/20 dark:border-yellow-500/20">{item}</span>);
            case "Cancelled":
                return (<span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-red-100 border-red-200 text-red-500 dark:bg-red-500/20 dark:border-red-500/20">{item}</span>);
            case "Return":
                return (<span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-slate-100 border-slate-200 text-slate-500 dark:bg-slate-500/20 dark:border-slate-500/20 dark:text-zink-200">{item}</span>);
            default:
                return (<span className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border bg-green-100 border-green-200 text-green-500 dark:bg-green-500/20 dark:border-green-500/20">{item}</span>);
        }
    };

    const columns = useMemo(() => [
        {
            header: (
                <div className="flex items-center h-full">
                    <input id="CheckboxAll" className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800" type="checkbox" />
                </div>
            ),
            enableSorting: false,
            id: "checkAll",
            cell: (cell: any) => {
                return (
                    <div className="flex items-center h-full">
                        <input id={"Checkbox" + cell.row.original.id} className="size-4 cursor-pointer bg-white border border-slate-200 checked:bg-none dark:bg-zink-700 dark:border-zink-500 rounded-sm appearance-none arrow-none relative after:absolute after:content-['\eb7b'] after:top-0 after:left-0 after:font-remix after:leading-none after:opacity-0 checked:after:opacity-100 after:text-custom-500 checked:border-custom-500 dark:after:text-custom-500 dark:checked:border-custom-800" type="checkbox" />
                    </div>
                );
            }
        },
        {
            header: "Order ID",
            accessorKey: "orderNo",
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell: any) => (
                <>
                    <Link to={"/order-detail/" + cell.row.original.id} className="transition-all duration-150 ease-linear order_id text-custom-500 hover:text-custom-600">{cell.getValue()}</Link>
                </>
            ),
        },
        {
            header: "Order Date",
            accessorKey: "orderDate",
            enableColumnFilter: false,
            cell: (cell: any) => (
                <p>{format(cell.getValue(), 'dd MMM, yyyy')}</p>
            ),
        },
        {
            header: "Delivery Date",
            accessorKey: "deliveryDate",
            enableColumnFilter: false,
            cell: (cell: any) => (
                <p>  {cell.getValue() == null ? '' : format(cell.getValue(), 'dd MMM, yyyy')}</p>

            ),
        },
        {
            header: "Customer Name",
            accessorKey: "businessesOrderheaders.ownerName",
            enableColumnFilter: false,
        },
        {
            header: "Payment Method",
            accessorKey: "paymentMethod",
            enableColumnFilter: false,
            cell: (cell: any) => (
                <p>  {cell.getValue() ?? 'COD'}</p>
            ),
        },
        {
            header: "Amount",
            accessorKey: "totalAmount",
            enableColumnFilter: false,
            cell: (cell: any) => (
                <p>  {'₹ ' + cell.getValue()}</p>

            ),

        },
        {
            header: "Delivery Status",
            accessorKey: "orderStatus",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell: any) => (
                <Status item={cell.getValue()} />
            ),
        },
        {
            header: "Action",
            enableColumnFilter: false,
            enableSorting: true,
            cell: (cell: any) => (


                <div className="flex justify-start gap-2" >

                    <Link to={"/order-detail/" + cell.row.original.id}
                        className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-green-500 bg-green-100 hover:text-white hover:bg-green-500 dark:text-zink-200 dark:bg-zink-600 dark:hover:text-white dark:hover:bg-zink-400"
                    >
                        <Eye className="inline-block size-4 ltr:mr-1 rtl:ml-1" /> </Link>

                    <Link to="#!" data-modal-target="addOrderModal"
                        className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-blue-500 bg-blue-100 hover:text-white hover:bg-blue-500 dark:text-zink-200 dark:bg-zink-600 dark:hover:text-white dark:hover:bg-zink-400"

                        onClick={() => {
                            const data = cell.row.original;
                            handleUpdateDataClick(data);
                        }}>
                        <FileEdit className="inline-block size-3 ltr:mr-1 rtl:ml-1" />
                    </Link>
                    <Link to="#!"
                        className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-red-500 bg-red-100 hover:text-white hover:bg-red-500 dark:bg-red-500/20 dark:hover:bg-red-500"
                        onClick={() => {
                            const orderData = cell.row.original;
                            onClickDelete(orderData);
                        }}><Trash2 className="inline-block size-4 ltr:mr-1 rtl:ml-1" /> </Link>
                </div>
            ),
        }
    ], []
    );

    return (
        <React.Fragment>
            <BreadCrumb title='Order Lists' pageTitle='Ecommerce' />
            <DeleteModal show={deleteModal} onHide={deleteToggle} onDelete={handleDelete} />
            <ToastContainer closeButton={false} limit={1} />
            <div className="grid grid-cols-1 gap-x-5 md:grid-cols-2 2xl:grid-cols-12">
                <div className="2xl:col-span-2 2xl:row-span-1">
                    <div className="card">
                        <div className="flex items-center gap-3 card-body">
                            <div className="flex items-center justify-center size-12 rounded-md text-15 bg-custom-50 text-custom-500 dark:bg-custom-500/20 shrink-0"><Boxes /></div>
                            <div className="grow">
                                <h5 className="mb-1 text-16">
                                    <CountUp end={totalOrderCounts.totalOrders || 0} separator="," className="counter-value" />
                                </h5>
                                <p className="text-slate-500 dark:text-zink-200">Total Orders</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="2xl:col-span-2 2xl:row-span-1">
                    <div className="card">
                        <div className="flex items-center gap-3 card-body">
                            <div className="flex items-center justify-center size-12 rounded-md text-15 bg-sky-50 text-sky-500 dark:bg-sky-500/20 shrink-0"><PackagePlus /></div>
                            <div className="grow">
                                <h5 className="mb-1 text-16">
                                    <CountUp end={totalOrderCounts.newOrders || 0} separator="," className="counter-value" />
                                </h5>
                                <p className="text-slate-500 dark:text-zink-200">New Orders</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="order-last md:col-span-2 2xl:col-span-8 2xl:row-span-3 card 2xl:order-none">
                    <div className="card-body">
                        <h6 className="mb-4 text-gray-800 text-15 dark:text-zink-50">Orders Overview</h6>
                        <OrdersOverviewChart id="ordersOverview" data={monthlyOrderCounts} />
                    </div>
                </div>
                <div className="2xl:col-span-2 2xl:row-span-1">
                    <div className="card">
                        <div className="flex items-center gap-3 card-body">
                            <div className="flex items-center justify-center size-12 text-yellow-500 rounded-md text-15 bg-yellow-50 dark:bg-yellow-500/20 shrink-0"><Loader /></div>
                            <div className="grow">
                                <h5 className="mb-1 text-16">
                                    <CountUp end={totalOrderCounts.pendingOrders || 0} separator="," className="counter-value" />
                                </h5>
                                <p className="text-slate-500 dark:text-zink-200">Pending Orders</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="2xl:col-span-2 2xl:row-span-1">
                    <div className="card">
                        <div className="flex items-center gap-3 card-body">
                            <div className="flex items-center justify-center size-12 text-purple-500 rounded-md text-15 bg-purple-50 dark:bg-purple-500/20 shrink-0"><Truck /></div>
                            <div className="grow">
                                <h5 className="mb-1 text-16">
                                    <CountUp end={totalOrderCounts.shippedOrders || 0} separator="," className="counter-value" />
                                </h5>
                                <p className="text-slate-500 dark:text-zink-200">Shipping Orders</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="2xl:col-span-2 2xl:row-span-1">
                    <div className="card">
                        <div className="flex items-center gap-3 card-body">
                            <div className="flex items-center justify-center size-12 text-green-500 rounded-md text-15 bg-green-50 dark:bg-green-500/20 shrink-0"><PackageCheck /></div>
                            <div className="grow">
                                <h5 className="mb-1 text-16">
                                    <CountUp end={totalOrderCounts.deliveredOrders || 0} separator="," className="counter-value" />
                                </h5>
                                <p className="text-slate-500 dark:text-zink-200">Delivered Orders</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="2xl:col-span-2 2xl:row-span-1">
                    <div className="card">
                        <div className="flex items-center gap-3 card-body">
                            <div className="flex items-center justify-center size-12 text-red-500 rounded-md text-15 bg-red-50 dark:bg-red-500/20 shrink-0"><PackageX /></div>
                            <div className="grow">
                                <h5 className="mb-1 text-16">
                                    <CountUp end={totalOrderCounts.cancelledOrders || 0} separator="," className="counter-value" />
                                </h5>
                                <p className="text-slate-500 dark:text-zink-200">Cancelled Orders</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="card" id="ordersTable">
                <div className="card-body">
                    <div className="grid grid-cols-1 gap-4 lg:grid-cols-12">
                        <div className="lg:col-span-3">
                            <div className="relative">
                                <input type="text" className="ltr:pl-8 rtl:pr-8 search form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Search for ..." autoComplete="off" onChange={(e) => filterSearchData(e)} />
                                <Search className="inline-block size-4 absolute ltr:left-2.5 rtl:right-2.5 top-2.5 text-slate-500 dark:text-zink-200 fill-slate-100 dark:fill-zink-600" />
                            </div>
                        </div>
                        {/* <div className="lg:col-span-2 lg:col-start-11">
                            <div className="ltr:lg:text-right rtl:lg:text-left">
                                <Link to="#!" data-modal-target="addOrderModal" type="button" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20" onClick={toggle}>
                                    <Plus className="inline-block size-4" /> <span className="align-middle">Add Order</span>
                                </Link>
                            </div>
                        </div> */}
                    </div>

                    <ul className="flex flex-wrap w-full mt-5 text-sm font-medium text-center text-gray-500 nav-tabs">
                        <li className={`group ${activeTab === "1" && "active"}`}>
                            <Link to="#" data-tab-toggle data-target="allOrders" className="inline-block px-4 py-1.5 text-base transition-all duration-300 ease-linear rounded-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:bg-custom-500 group-[.active]:text-white dark:group-[.active]:text-white hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]" onClick={() => { toggleTab("1", "all"); }}>
                                <Boxes className="inline-block size-4 ltr:mr-1 rtl:ml-1" /> <span className="align-middle">All Orders</span>
                            </Link>
                        </li>
                        <li className={`group ${activeTab === "2" && "active"}`}>
                            <Link to="#" data-tab-toggle data-target="pendingOrder" className="inline-block px-4 py-1.5 text-base transition-all duration-300 ease-linear rounded-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:bg-custom-500 group-[.active]:text-white dark:group-[.active]:text-white hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]" onClick={() => { toggleTab("2", "Pending"); }}>
                                <Loader className="inline-block size-4 ltr:mr-1 rtl:ml-1" /> <span className="align-middle">Pending</span>
                            </Link>
                        </li>
                        <li className={`group ${activeTab === "3" && "active"}`}>
                            <Link to="#" data-tab-toggle data-target="deliveredOrder" className="inline-block px-4 py-1.5 text-base transition-all duration-300 ease-linear rounded-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:bg-custom-500 group-[.active]:text-white dark:group-[.active]:text-white hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]" onClick={() => { toggleTab("3", "Delivered"); }}>
                                <PackageCheck className="inline-block size-4 ltr:mr-1 rtl:ml-1" /> <span className="align-middle">Delivered</span>
                            </Link>
                        </li>
                        <li className={`group ${activeTab === "4" && "active"}`}>
                            <Link to="#" data-tab-toggle data-target="returnsOrders" className="inline-block px-4 py-1.5 text-base transition-all duration-300 ease-linear rounded-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:bg-custom-500 group-[.active]:text-white dark:group-[.active]:text-white hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]" onClick={() => { toggleTab("4", "Return"); }}>
                                <RefreshCcw className="inline-block size-4 ltr:mr-1 rtl:ml-1" /> <span className="align-middle">Returns</span>
                            </Link>
                        </li>
                        <li className={`group ${activeTab === "5" && "active"}`}>
                            <Link to="#" data-tab-toggle data-target="cancelledOrders" className="inline-block px-4 py-1.5 text-base transition-all duration-300 ease-linear rounded-md text-slate-500 dark:text-zink-200 border border-transparent group-[.active]:bg-custom-500 group-[.active]:text-white dark:group-[.active]:text-white hover:text-custom-500 dark:hover:text-custom-500 active:text-custom-500 dark:active:text-custom-500 -mb-[1px]" onClick={() => { toggleTab("5", "Cancelled"); }}>
                                <PackageX className="inline-block size-4 ltr:mr-1 rtl:ml-1 " /> <span className="align-middle">Cancelled</span>
                            </Link>
                        </li>
                    </ul>

                    {isLoading ? (
                        <LoaderWithMessage />
                    ) : (
                        <div className="!pt-1 card-body">
                            {data.length > 0 ? (
                                <TableContainer
                                    isPagination={true}
                                    columns={(columns || [])}
                                    data={(data || [])}
                                    customPageSize={10}
                                    divclassName="mt-5 overflow-x-auto"
                                    tableclassName="w-full whitespace-nowrap"
                                    theadclassName="ltr:text-left rtl:text-right bg-slate-100 dark:bg-zink-600"
                                    thclassName="px-3.5 py-2.5 font-semibold text-slate-500 border-b border-slate-200 dark:border-zink-500 dark:text-zink-200"
                                    tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
                                    PaginationClassName="flex flex-col items-center mt-5 md:flex-row"
                                />)
                                :
                                (<div className="noresult">
                                    <div className="py-6 text-center">
                                        <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                                        <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                                        <p className="mb-0 text-slate-500 dark:text-zink-200">Ops... We did not find any orders for you search.</p>
                                    </div>
                                </div>)}

                        </div>
                    )}

                </div>
            </div>


            {/* Order Modal */}

            <Modal show={show} onHide={toggle} modal-center="true"
                className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
                dialogClassName="w-screen md:w-[50rem] bg-white shadow rounded-md dark:bg-zink-600">
                <OrderStatus orderDetail={eventData} onSave={(result: any) => { onOrderStatusSave(result); }} onHide={toggle} />
            </Modal>

        </React.Fragment>
    );
};

export default Orders;