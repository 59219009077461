import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BreadCrumb from 'Common/BreadCrumb';
import { Link, useNavigate } from 'react-router-dom';
import TableContainer from 'Common/TableContainer';
import Select from 'react-select';
import { Loader } from '../../../Common/Loader'
// Icons
import { Pencil, Search, Trash2, Plus, } from 'lucide-react';
import DeleteModal from 'Common/DeleteModal';

import {
    getProductList as onGetProductList,
    deleteProduct as onDeleteProductList,
} from '../../../helpers/backend_helper';
import { ToastContainer } from 'react-toastify';
import { Common } from 'helpers/common';

const ProductList = () => {
    const [productList, setProductList] = useState([]);
    const [eventData, setEventData] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const uiHelper = new Common();
    useEffect(() => {
        loadProducts(false);
    }, [])

    async function loadProducts(relead: boolean) {
        try {
        if (productList.length === 0 || relead) {

            let response = await onGetProductList();
            if (response.success) {
                console.log('cat', response.data);
                setProductList(response.data as never[]);
            }
            setIsLoading(false)
        }
    } catch (error) {
            
    }finally{
        setIsLoading(false)
    }
    }

    // Delete Modal
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const deleteToggle = () => setDeleteModal(!deleteModal);

    // Delete Data
    const onClickDelete = (cell: any) => {
        setDeleteModal(true);
        if (cell.id) {
            setEventData(cell);
        }
    };

    const handleDelete = async () => {
        try {

        if (eventData) {
            console.log(eventData)

            await onDeleteProductList(eventData.id);
            setDeleteModal(false);
            uiHelper.showSuccessMessage('Product has been deleted successfully.')
            // remove the item and then update list
            setProductList(categoryList => categoryList.filter((cat: any, idx) => cat.id !== eventData.id));
        }
    } catch (error:any) {
            console.log(error?.response?.data?.success)
            uiHelper.showErrorMessage('Product deletion failed. Please try again.')

    }
    };
    // 

    // Update Data
    const handleEditDataClick = (ele: any) => {
        console.log(ele);
        navigate('/product-create/' + ele.id);
    };

    const handleAddProduct = () => {
        navigate('/product-create');
    }


    // columns
    const Status = ({ item }: any) => {
        switch (item) {
            case true:
                return (<span className="status px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">Active</span>);
            case false:
                return (<span className="status px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-orange-100 border-transparent text-orange-500 dark:bg-orange-500/20 dark:border-transparent">InActive</span>);
        }
    };
    const columns = useMemo(() => [
        {
            header: "Product Name",
            accessorKey: "name",
            enableColumnFilter: true,
            enableSorting: false,
        },
        {
            header: "Brand",
            accessorKey: "brandsProducts.name",
            enableColumnFilter: false,
        },
        {
            header: "Category",
            accessorKey: "categoriesProducts.name",
            enableColumnFilter: false,

        },
        {
            header: "Gender",
            accessorKey: "gender",
            enableColumnFilter: false,

        },
        {
            header: "Unit Price",
            enableColumnFilter: false,
            cell: (cell: any) => (
                <div >
                    {cell.row.original.productsProductvariants.length>0?cell.row.original.productsProductvariants[0].unitPrice : 0}
                </div>
            ),

        },

        {
            header: "Pack Of",
            enableColumnFilter: false,
            cell: (cell: any) => (
                <div >
                    {cell.row.original.productsProductvariants.length>0?cell.row.original.productsProductvariants[0].packOf :0}
                </div>
            ),
        },

        {
            header: "Discount",
            // accessorKey: "productsProductvariants[0].discount",
            enableColumnFilter: false,
            cell: (cell: any) => (
                <div >
                    {cell.row.original.productsProductvariants.length>0?cell.row.original.productsProductvariants[0].discountValue : 0}
                </div>
            ),
        },

        // {
        //     header: "Logo",
        //     accessorKey: "logo",
        //     enableColumnFilter: false,
        //     //enableSorting: true,
        //     cell: (cell: any) => (
        //         <div >
        //             <div className="flex items-center justify-center size-10 font-medium rounded-full shrink-0 bg-slate-200 text-slate-800 dark:text-zink-50 dark:bg-zink-600">
        //                 {cell.row.original.logo ? <img src={cell.row.original.logo} alt="" className="h-10 rounded-full" /> : (cell.getValue().split(' ').map((word: any) => word.charAt(0)).join(''))}
        //             </div>

        //         </div>
        //     ),
        // },

        {
            header: "Status",
            accessorKey: "isActive",
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell: any) => (
                <Status item={cell.getValue()} />
            ),
        },
        {
            header: "Action",
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell: any) => (

                <div className="flex justify-start gap-2" >
                    <button
                        className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-slate-500 bg-slate-100 hover:text-white hover:bg-slate-500 dark:text-zink-200 dark:bg-zink-600 dark:hover:text-white dark:hover:bg-zink-400"
                        onClick={() => {
                            const data = cell.row.original;
                            handleEditDataClick(data);
                        }}>
                        <Pencil className="inline-block size-4 ltr:mr-1 rtl:ml-1" /> </button>


                    <Link to="#!"
                        className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-red-500 bg-red-100 hover:text-white hover:bg-red-500 dark:bg-red-500/20 dark:hover:bg-red-500"
                        onClick={() => {
                            const orderData = cell.row.original;
                            onClickDelete(orderData);
                        }}><Trash2 className="inline-block size-4 ltr:mr-1 rtl:ml-1" /> </Link>
                </div>
            ),
        }
    ], []
    );

    const options = [
        { value: 'Select Status', label: 'Select Status' },
        { value: true, label: 'Active' },
        { value: false, label: 'inActive' },
    ];

    const handleChange = (selectedOption: any) => {
        if (selectedOption.value === 'Select Status' || selectedOption.value === 'Hidden') {
            setProductList(productList);
        } else {
            const filteredUsers = productList.filter((data: any) => data.isActive === selectedOption.value);
            setProductList(filteredUsers);
        }
    };


    return (
        <React.Fragment>
            <BreadCrumb title='Manage' pageTitle='Products' />
            <DeleteModal show={deleteModal} onHide={deleteToggle} onDelete={handleDelete} />
            <ToastContainer closeButton={false} limit={1} />
            <div className="card" id="brandListTable">
                <div className="card-body">
                    <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
                        <div className="xl:col-span-12">
                            <div className="card" id="categoriesTable">
                                <div className="card-body">
                                    <div className="flex items-center">
                                        <h6 className="text-15 grow">Product List</h6>
                                        <div className="shrink-0">
                                            <button type="button" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20" onClick={handleAddProduct}><Plus className="inline-block size-4" /> <span className="align-middle">Add Product</span></button>
                                        </div>
                                    </div>
                                </div>
                                <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
                                    <form action="#!">
                                        <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                                            <div className="xl:col-span-2">
                                                <Select
                                                    className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                    options={options}
                                                    isSearchable={false}
                                                    defaultValue={options[0]}
                                                    onChange={(event: any) => handleChange(event)}
                                                    id="choices-single-default"
                                                />
                                            </div>

                                        </div>
                                    </form>
                                </div>
                                {isLoading ? (<Loader />) :
                                    (<div className="!pt-1 card-body">
                                        {productList && productList?.length > 0 ?
                                            <TableContainer
                                                isPagination={true}
                                                columns={(columns || [])}
                                                data={(productList || [])}
                                                customPageSize={10}
                                                divclassName="overflow-x-auto"
                                                tableclassName="w-full whitespace-nowrap"
                                                theadclassName="ltr:text-left rtl:text-right bg-slate-100 dark:bg-zink-600"
                                                thclassName="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                                                tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
                                                PaginationClassName="flex flex-col items-center gap-4 px-4 mt-3 md:flex-row"
                                            />
                                            :
                                            (<div className="noresult">
                                                <div className="py-6 text-center">
                                                    <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                                                    <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                                                </div>
                                            </div>)}
                                    </div>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ProductList;
