import React, { useEffect, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";

// Images
import avatar4 from "assets/images/users/avatar-4.png";

import delivery1 from "assets/images/brand/delivery-1.png";

// icons
import { Truck, CreditCard, CircleDollarSign, Download } from 'lucide-react';
import { Link, useParams } from "react-router-dom";
import { getCartById } from "helpers/backend_helper";
import { format } from 'date-fns';// Icons
import { CartDetailResponse, CartItem } from "types/CartType";


const CartDetail = () => {
    const { userId, businessId } = useParams();
    const [cartDetail, setCartDetail] = useState<CartDetailResponse>({} as CartDetailResponse);

    // Get Data
    useEffect(() => {
        onGetCartDetail();
    }, []);

    const onGetCartDetail = async () => {
        try {
            const response = await getCartById(userId || 0, businessId || 0);
            console.log("Cart Data", response.data[0]);
            setCartDetail(response.data[0] as CartDetailResponse);
        } catch (err) { }
    };


    const netPrice = (item: CartItem) => {
        return (item.unitPrice - item.discountValue);
    }

    const setPrice = (item: CartItem) => {
        const total = (item.packOf * (item.unitPrice - item.discountValue) * item.quantity)
        return parseFloat( (total*100/100).toString()).toFixed(2);
    }

    return (
        <React.Fragment>
            <BreadCrumb title='Cart Overview' pageTitle='Ecommerce' />
            <div className="grid grid-cols-1 2xl:grid-cols-12 gap-x-5">
                <div className="2xl:col-span-5">
                    <div className="card">
                        <div className="card-body">
                            <div className="flex items-center justify-center size-12 bg-orange-100 rounded-md dark:bg-orange-500/20 ltr:float-right rtl:float-left">
                                <CreditCard className="text-orange-500 fill-orange-200 dark:fill-orange-500/30" />
                            </div>
                            <h6 className="mb-4 text-15">Billing Details</h6>

                            <h6 className="mb-1">{cartDetail?.businessDetails?.businessName}</h6>
                            <p className="mb-1 text-slate-500 dark:text-zink-200">{cartDetail?.businessDetails?.addressLine1}, {cartDetail?.businessDetails?.addressLine2}</p>
                            <p className="text-slate-500 dark:text-zink-200">{cartDetail?.businessDetails?.cityName}, {cartDetail?.businessDetails?.districtName}, {cartDetail?.businessDetails?.stateName}, {cartDetail?.businessDetails?.pinCode}</p>
                        </div>
                    </div>
                </div>
                <div className="2xl:col-span-5">
                    <div className="card">
                        <div className="card-body">
                            <div className="size-12 bg-yellow-100 rounded-md ltr:float-right rtl:float-left dark:bg-yellow-500/20">
                                <img src={avatar4} alt="" className="h-12 rounded-md" />
                            </div>
                            <h6 className="mb-4 text-15">Customer Info</h6>

                            <h6 className="mb-1">{cartDetail?.cartuser?.userName}</h6>
                            <p className="mb-1 text-slate-500 dark:text-zink-200">{cartDetail?.cartuser?.email}</p>
                            <p className="text-slate-500 dark:text-zink-200">+91 {cartDetail?.cartuser?.phone}</p>
                        </div>
                    </div>
                </div>
                </div>
                <div className="grid grid-cols-1 2xl:grid-cols-12 gap-x-5">
                <div className="2xl:col-span-2">
                    <div className="card">
                        <div className="text-center card-body">
                            <h6 className="mb-1">{format(cartDetail?.cartitems == null ? new Date() : new Date(cartDetail?.cartitems[0]?.createdAt ?? new Date()), 'dd MMM, yyyy')}</h6>
                            <p className="uppercase text-slate-500 dark:text-zink-200">Cart Date</p>
                        </div>
                    </div>
                </div>

                <div className="2xl:col-span-2">
                    <div className="card">
                        <div className="text-center card-body">
                            <h6 className="mb-1">₹ {cartDetail?.cartsummary?.total}</h6>
                            <p className="uppercase text-slate-500 dark:text-zink-200">Cart Amount</p>
                        </div>
                    </div>
                </div>
                <div className="2xl:col-span-2">
                    <div className="card">
                        <div className="text-center card-body">
                            <h6 className="mb-1"> {cartDetail?.cartsummary?.totalproducts}</h6>
                            <p className="uppercase text-slate-500 dark:text-zink-200">Total Items in Cart</p>
                        </div>
                    </div>
                </div>
                <div className="2xl:col-span-2">
                    <div className="card">
                        <div className="text-center card-body">
                            <h6 className="mb-1"> {cartDetail?.cartsummary?.totalquantity}</h6>
                            <p className="uppercase text-slate-500 dark:text-zink-200">Total Quantities</p>
                        </div>
                    </div>
                </div>
                <div className="2xl:col-span-2">
                    <div className="card">
                        <div className="text-center card-body">
                            <h6 className="mb-1"> {'Not Available'}</h6>
                            <p className="uppercase text-slate-500 dark:text-zink-200">Total Savings</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-1 2xl:grid-cols-12 gap-x-5">
                <div className="2xl:col-span-10">
                    <div className="card">
                        <div className="card-body">
                            <div className="flex items-center gap-3 mb-4">
                                <h6 className="text-15 grow">Cart Summary</h6>
                            </div>
                            <div className="overflow-x-auto">
                                <table className="w-full">
                                    <tbody>

                                        {cartDetail?.cartitems?.map((item: CartItem, index: number) => (
                                            <tr key={`cartitems-${index}`} >
                                                <td className="px-3.5 py-4 bcart-b border-dashed first:pl-0 last:pr-0 border-slate-200 dark:border-zink-500">
                                                    <div className="flex items-center gap-3">
                                                        <div className="flex items-center justify-center size-18 rounded-md bg-slate-200 shrink-0">
                                                            <img src={item.imageUrl} alt="" className="h-24" />
                                                        </div>
                                                        <div className="grow">
                                                            <h6 className="mb-1 text-15"><Link to="/product-overview" className="transition-all duration-300 ease-linear hover:text-custom-500">
                                                                {item?.brandName},  {item?.productName}</Link></h6>
                                                          
                                                            <div className="flex">
                                                                <div
                                                                    key={`$color-${101}`}
                                                                    style={{
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        marginRight: 10,
                                                                    }}
                                                                    className="mr-2 mb-2"
                                                                >
                                                                    Size : {item.size}, 
                                                                </div>

                                                                <div
                                                                    key={`$color-${100}`}
                                                                    style={{
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        marginRight: 10,
                                                                    }}
                                                                    className="mr-2 mb-2"
                                                                >
                                                                    Colors:
                                                                </div>
                                                                {item?.color.map((itemColor: any, idx: number, arr: any[]) => {
                                                                    const lastElement = idx === arr.length - 1;
                                                                    const marginRight = lastElement ? 0 : 4;
                                                                    return (
                                                                        <div
                                                                            key={`$color-${idx}`}
                                                                            style={{
                                                                                width: 16,
                                                                                height: 16,
                                                                                backgroundColor: itemColor,
                                                                                borderRadius: 10,
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center',
                                                                                marginRight: marginRight,
                                                                            }}
                                                                            className="mr-2 mb-2"
                                                                        >
                                                                        </div>
                                                                    );
                                                                })}
                                                            </div>
                                                            <p className="text-slate-500 dark:text-zink-200">Set of : {item.packOf} Pcs</p>
                                                            <p className="text-slate-500 dark:text-zink-200">Quantity : {item.quantity}</p>

                                                            <p className="text-slate-500 dark:text-zink-200">Unit Price : ₹{item.unitPrice} / Pc, Discount : ₹{item.discountValue} , Net Price : ₹{netPrice(item)} / Pc</p>
                                                            {/* <p className="text-slate-500 dark:text-zink-200">Discount : ₹{item.discountValue} / Pc</p>
                                                            <p className="text-slate-500 dark:text-zink-200">Net Price : ₹{netPrice(item)} / Pc</p> */}

                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-3.5 py-4 border-b border-dashed first:pl-0 last:pr-0 border-slate-200 dark:border-zink-500 ltr:text-right rtl:text-left">
                                                    ₹{setPrice(item)}
                                                </td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td className="px-3.5 pt-4 pb-3 first:pl-0 last:pr-0 text-slate-500 dark:text-zink-200">
                                                Sub Total
                                            </td>
                                            <td className="px-3.5 pt-4 pb-3 first:pl-0 last:pr-0 ltr:text-right rtl:text-left">₹{cartDetail?.cartsummary?.subtotal}</td>
                                        </tr>
                                        <tr>
                                            <td className="px-3.5 py-3 first:pl-0 last:pr-0 text-slate-500 dark:text-zink-200">
                                                Tax (5%)
                                            </td>
                                            <td className="px-3.5 py-3 first:pl-0 last:pr-0 ltr:text-right rtl:text-left">{cartDetail?.cartsummary?.gst}</td>
                                        </tr>
                                        <tr>
                                            <td className="px-3.5 py-3 first:pl-0 last:pr-0 text-slate-500 dark:text-zink-200">
                                                Total Products
                                            </td>
                                            <td className="px-3.5 py-3 first:pl-0 last:pr-0 ltr:text-right rtl:text-left">{cartDetail?.cartsummary?.totalproducts}</td>
                                        </tr>
                                        <tr>
                                            <td className="px-3.5 py-3 first:pl-0 last:pr-0 text-slate-500 dark:text-zink-200">
                                                Total Quantities
                                            </td>
                                            <td className="px-3.5 py-3 first:pl-0 last:pr-0 ltr:text-right rtl:text-left">{cartDetail?.cartsummary?.totalquantity}</td>
                                        </tr>
                                        <tr>
                                            <td className="px-3.5 py-3 first:pl-0 last:pr-0 text-slate-500 dark:text-zink-200">
                                                Shipping Charges
                                            </td>
                                            <td className="px-3.5 py-3 first:pl-0 last:pr-0 ltr:text-right rtl:text-left">₹{cartDetail?.cartsummary?.shipping}</td>
                                        </tr>
                                        <tr className="font-semibold">
                                            <td className="px-3.5 pt-3 first:pl-0 last:pr-0 text-slate-500 dark:text-zink-200">
                                                Total Amount
                                            </td>
                                            <td className="px-3.5 pt-3 first:pl-0 last:pr-0 ltr:text-right rtl:text-left">₹{cartDetail?.cartsummary?.total}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </React.Fragment>
    );
};

export default CartDetail;