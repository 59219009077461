import Modal from "Common/Components/Modal";
import React, { useEffect, useState } from "react";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import Select from "react-select";
import {
  addPermissions,
  getRoles,
  getResources,
  updatePermissions,
} from "../../../helpers/backend_helper";
import { Common } from "helpers/common";
import { Loader2, UploadCloud } from "lucide-react";

type Props = { onHide: () => void; data: any; onSave: (result: any, closeModal:boolean) => void };

export const AddPermissions: React.FC<Props> = ({ onHide, data, onSave }) => {
  const [permissionData, setPermissionsData] = useState<any>(data);
  const [role, setRole] = useState<any[]>([]);
  const [selectedRole, setSelectedRole] = useState<any>();
  const [resource, setResource] = useState<any[]>([]);
  const [selectedResource, setSelectedResource] = useState<any>();
  const [selectedAction, setSelectedAction] = useState<any>();
  const [defaultValue, setDefaultValue] = useState("* ");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const commonHelper = new Common();

  useEffect(() => {
    loadDataFromAPI();
  }, []);

  useEffect(() => {
    if (permissionData?.id) {
      setIsEdit(true);
      setSelectedRole({ value: permissionData.id, label: permissionData.role });
      setSelectedResource({
        value: permissionData.id,
        label: permissionData.resource,
      });
      setSelectedAction({
        value: permissionData.action,
        label: permissionData.action,
      });
    }
  }, [permissionData]);

  const loadDataFromAPI = async () => {
    try {
      await loadRoles();
      await loadResources();
    } catch (error) {
      console.error("Error loading data from API", error);
    }
  };

  async function loadRoles() {
    try {
      const response = await getRoles();
      const data = response.data.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      const options = [{ value: "", label: "Select" }, ...data];
      setRole(options);
    } catch (error) {
      console.error("Error loading roles", error);
    }
  }

  async function loadResources() {
    try {
      const response = await getResources();
      const data = response.data.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      const options = [{ value: "", label: "Select" }, ...data];
      setResource(options);
    } catch (error) {
      console.error("Error loading resources", error);
    }
  }

  const handleRoleChange = (item: any) => {
    setSelectedRole(item);
    validation.setFieldValue("role", item.value);
  };

  const handleResourceChange = (item: any) => {
    setSelectedResource(item);
    validation.setFieldValue("resource", item.value);
  };

  const handleActionChange = (item: any) => {
    setSelectedAction(item);
    validation.setFieldValue("action", item.value);
  };

  const actionOptions = [
    { value: "", label: "Select Action" },
    { value: "create:any", label: "create:any" },
    { value: "create:own", label: "create:own" },
    { value: "update:any", label: "update:any" },
    { value: "update:own", label: "update:own" },
    { value: "read:any", label: "read:any" },
    { value: "read:own", label: "read:own" },
    { value: "delete:any", label: "delete:any" },
    { value: "delete:own", label: "delete:own" },
  ];

  const validation: any = useFormik({
    enableReinitialize: true,
    initialValues: {
      role: selectedRole?.label || "",
      resource: selectedResource?.label || "",
      action: selectedAction?.label || "",
      attributes: defaultValue || "",
      isActive: permissionData?.isActive ?? true,
    },
    validationSchema: Yup.object({
      role: Yup.string().required("Please Enter A Role!"),

      resource: Yup.string().required("Please Enter A Resource!"),
      action: Yup.string().required("Please Enter An Action!"),
      attributes: Yup.string().required("Please Enter An Attribute!"),
      isActive: Yup.boolean().required("Please choose yes or no!"),
    }),

    onSubmit: async (values: any) => {
      setIsSaving(true);
      let dataToSave = { ...values };
      if (isEdit && permissionData) {
        dataToSave = { ...dataToSave, id: permissionData.id };
      }
      try {
        const result = await (isEdit
          ? updatePermissions(dataToSave)
          : addPermissions(dataToSave));
        commonHelper.showSuccessMessage(
          `Permission has been successfully ${isEdit ? "updated" : "added"}.`
        );
        console.log(result.data.rows[0]);

        onSave(result.data.rows[0],isEdit);
        
        setIsEdit(false);
        setIsSaving(false);
      } catch (error: any) {
        console.error("API-Error", error.response.data);
        commonHelper.showErrorMessage(error.response.data.message);
        setIsSaving(false);
      }
    },
  });

  return (
    <React.Fragment  >
      <Modal.Header
        className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
        closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500"
      >
        <Modal.Title className="text-16">
          {!!isEdit ? "Edit Permission" : "Add Permission"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="max-h-[calc(theme('height.screen')_-_280px)] p-4 overflow-y-auto"   >
        <form
          action="#!"
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          style={{height:500}}
        >
          <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-6" > 
            <div className="lg:col-span-2 xl:col-span-12">
              <label
                htmlFor="role"
                className="inline-block mb-2 text-base font-medium"
              >
                Roles
              </label>
              <Select
                className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                options={role}
                isSearchable={true}
                name="role"
                id="role"
                value={selectedRole}
                onChange={handleRoleChange}
              />
              {validation.touched.role && validation.errors.role ? (
                <div id="role-error" className="mt-1 text-sm text-red-500">
                  {validation.errors.role}
                </div>
              ) : null}
            </div>
          </div>
          <div className="lg:col-span-2 xl:col-span-12">
            <label
              htmlFor="resource"
              className="inline-block mb-2 text-base font-medium"
            >
              Resource
            </label>
            <Select
              className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              options={resource}
              isSearchable={true}
              name="resource"
              id="resource"
              value={selectedResource}
              onChange={handleResourceChange}
            />
            {validation.touched.resource && validation.errors.resource ? (
              <div id="resource-error" className="mt-1 text-sm text-red-500">
                {validation.errors.resource}
              </div>
            ) : null}
          </div>
          <div className="lg:col-span-2 xl:col-span-12">
            <label
              htmlFor="action"
              className="inline-block mb-2 text-base font-medium"
            >
              Action
            </label>
            <Select
              className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              options={actionOptions}
              isSearchable={true}
              name="action"
              id="action"
              value={selectedAction}
              onChange={handleActionChange}
            />
            {validation.touched.action && validation.errors.action ? (
              <div id="action-error" className="mt-1 text-sm text-red-500">
                {validation.errors.action}
              </div>
            ) : null}
          </div>
          <div className="lg:col-span-2 xl:col-span-12">
            <label
              htmlFor="attributes"
              className="inline-block mb-2 text-base font-medium"
            >
              Attribute
            </label>
            <input
              type="text"
              className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
              id="attributes"
              name="attributes"
              readOnly
              placeholder="Enter Attribute"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              value={validation.values.attributes}
            />
            {validation.touched.attribute && validation.errors.attributes ? (
              <div id="attributes-error" className="mt-1 text-sm text-red-500">
                {validation.errors.attributes}
              </div>
            ) : null}
          </div>

          <div className="lg:col-span-2 xl:col-span-12">
            <label
              htmlFor="isActive"
              className="inline-block mb-2 text-base font-medium"
            >
              IsActive
            </label>
            <br />
            <div className="relative inline-block w-10 align-middle transition duration-200 ease-in ltr:mr-2 rtl:ml-2">
              <input
                type="checkbox"
                name="isActive"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.isActive}
                id="isActive"
                className="absolute block size-5 transition duration-300 ease-linear border-2 rounded-full appearance-none cursor-pointer border-slate-200 dark:border-zink-500 bg-white/80 dark:bg-zink-400 peer/published checked:bg-white dark:checked:bg-white ltr:checked:right-0 rtl:checked:left-0 checked:bg-none checked:border-custom-500 dark:checked:border-custom-500 arrow-none"
                defaultChecked
              />
              <label
                htmlFor="isActive"
                className="block h-5 overflow-hidden duration-300 ease-linear border rounded-full cursor-pointer cursor-pointertransition border-slate-200 dark:border-zink-500 bg-slate-200 dark:bg-zink-600 peer-checked/published:bg-custom-500 peer-checked/published:border-custom-500"
              ></label>

              {validation.touched.isActive && validation.errors.isActive ? (
                <div id="isActive-error" className="mt-1 text-sm text-red-500">
                  {validation.errors.isActive}
                </div>
              ) : null}
            </div>
          </div>
          <div className="flex justify-end gap-2 mt-4">
            <button
              type="reset"
              data-modal-close="addDocuments"
              className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500"
              onClick={onHide}
            >
              Cancel
            </button>

            {!isSaving ? (
              <button
                type="submit"
                className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                {!!isEdit ? "Update Permission" : "Add Permission"}
              </button>
            ) : (
              <button
                type="button"
                disabled
                className="flex items-center text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
              >
                <Loader2 className="size-4 ltr:mr-2 rtl:ml-2 animate-spin" />
                Saving...
              </button>
            )}
          </div>
        </form>
      </Modal.Body>
    </React.Fragment>
  );
};
