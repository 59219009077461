import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { Link } from "react-router-dom";
import TableContainer from "Common/TableContainer";
import Select from "react-select";
import { Loader } from "../../../Common/Loader";
// Icons
import { Pencil, Search, Trash2, Plus } from "lucide-react";
import Modal from "Common/Components/Modal";
import DeleteModal from "Common/DeleteModal";

import {
  deletePermissions,
  getPermissions,
  getRoles,
  getResources,
} from "helpers/backend_helper";
import { ToastContainer } from "react-toastify";
import { Common } from "helpers/common";
import { AddPermissions } from "./AddPermissions";
const PermissionList = () => {
  const [Permissions, setPermissions] = useState([]);
  const [allPermissions, setAllPermissions] = useState([]);
  const [eventData, setEventData] = useState<any>();
  const [PermissionsData, setPermissionsData] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const uiHelper = new Common();

  const [roles, setRoles] = useState<any[]>([]);
  const [selectedRole, setSelectedRole] = useState<any>();
  const [resources, setResources] = useState<any[]>([]);
  const [selectedResource, setSelectedResource] = useState<any>();


  useEffect(() => {
    loadDataFromAPI();
    loadPermissions();
  }, []);

  const loadDataFromAPI = async () => {
    try {
      await loadRoles();
      await loadResources();
    } catch (error) {
      console.error("Error loading data from API", error);
    }
  };


  async function loadRoles() {
    try {
      const response = await getRoles();
      const data = response.data.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      const options = [{ value: "", label: "Select" }, ...data];
      setRoles(options);
    } catch (error) {
      console.error("Error loading roles", error);
    }
  }

  async function loadResources() {
    try {
      const response = await getResources();
      const data = response.data.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      const options = [{ value: "", label: "Select" }, ...data];
      setResources(options);
    } catch (error) {
      console.error("Error loading resources", error);
    }
  }

  async function loadPermissions() {
    try {
      if (Permissions.length === 0) {
        let response = await getPermissions();
        if (response.success) {
          console.log("Permissions", response.data);
          setPermissions(response.data as never[]);
          setAllPermissions(response.data as never[]);
        }
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  }
  //Delete Modal
  const deleteToggle = () => setDeleteModal(!deleteModal);
  //Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
    if (cell.id) {
      setEventData(cell);
    }
  };
  const handleDelete = async () => {
    if (eventData) {
      await deletePermissions(eventData.id);
      setDeleteModal(false);
      uiHelper.showSuccessMessage("Permissions has been deleted successfully.");
      // remove the item and then update list
      setPermissions((permission) =>
        permission.filter((data: any, idx) => data.id != eventData.id)
      );
    }
  };
  const handleEditDataClick = (ele: any) => {
    setPermissionsData({ ...ele });
    setIsEdit(true);
    setShow(true);
  };
  const handleAddPermissions = () => {
    setPermissionsData({});
    toggle();
  };
  const onSave = (result: any, closeModal: boolean) => {
    if (isEdit) {
      setPermissions((prevData) =>
        prevData.map((data: any, idx) =>
          data.id === PermissionsData.id
            ? ({ ...result } as never)
            : ({ ...data } as never)
        )
      );
      setIsEdit(false);
    } else {
      setPermissions((prevData) => [{ ...result } as never, ...prevData]);
    }

    if (closeModal) toggle();

  };

  const toggle = useCallback(() => {
    setShow(!show);
    if (show) {
      setPermissionsData(null);
    }
  }, [show]);


  // filter role and resources
  const handleRoleChange = (role: any) => {
    setSelectedRole(role);
    console.log("Selected Role", role);

    // Filter districts by selected state

    let filteredPermission = allPermissions.filter((dist: any) => dist?.role === role.label);
    console.log(role);

    if (selectedResource?.value !== " ") {
     filteredPermission =  filteredPermission.filter((dist: any) => dist?.resource === selectedResource.label);
    }

    setPermissions(filteredPermission);
    // Filter cities by filtered districts
    // const filteredCities = allCities?.filter((city) => filteredDistricts.some((dist) => dist.id === city.districtId));
    // setCityList(filteredCities??[]);
  };

  const handleResourceChange = (resource: any) => {
    console.log(resource);

    setSelectedResource(resource);
 
    
    let filteredPermission = allPermissions.filter((dist: any) => dist?.resource === resource.label);

    if (selectedRole?.value !== " ") {
     filteredPermission =  filteredPermission.filter((dist: any) => dist?.role === selectedRole.label);
    }

    setPermissions(filteredPermission);

  };



  const Status = ({ item }: any) => {
    switch (item) {
      case true:
        return (
          <span className="status px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">
            Active
          </span>
        );
      case false:
        return (
          <span className="status px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-orange-100 border-transparent text-orange-500 dark:bg-orange-500/20 dark:border-transparent">
            InActive
          </span>
        );
    }
  };
  const columns = useMemo(
    () => [
      {
        header: "Role",
        accessorKey: "role",
        enableColumnFilter: false,
        enableSorting: false,
      },

      {
        header: "Resource",
        accessorKey: "resource",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Action",
        accessorKey: "action",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Attribute",
        accessorKey: "attributes",
        enableColumnFilter: false,
        enableSorting: false,
      },

      {
        header: "Status",
        accessorKey: "isActive",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => <Status item={cell.getValue()} />,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => (
          <div className="flex justify-start gap-2">
            <Link
              data-modal-target="addPermissionsModal"
              to="#!"
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-slate-500 bg-slate-100 hover:text-white hover:bg-slate-500 dark:text-zink-200 dark:bg-zink-600 dark:hover:text-white dark:hover:bg-zink-400"
              onClick={() => {
                const data = cell.row.original;
                handleEditDataClick(data);
              }}
            >
              <Pencil className="inline-block size-4 ltr:mr-1 rtl:ml-1" />{" "}
            </Link>

            <Link
              to="#!"
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-red-500 bg-red-100 hover:text-white hover:bg-red-500 dark:bg-red-500/20 dark:hover:bg-red-500"
              onClick={() => {
                const orderData = cell.row.original;
                onClickDelete(orderData);
              }}
            >
              <Trash2 className="inline-block size-4 ltr:mr-1 rtl:ml-1" />{" "}
            </Link>
          </div>
        ),
      },
    ],
    []
  );


  return (
    <React.Fragment>
      <BreadCrumb title="Manage" pageTitle="Permissions" />
      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDelete}
      />
      <ToastContainer closeButton={false} limit={1} />
      <div className="card" id="PermissionsTable">
        <div className="card-body">
          <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
            <div className="xl:col-span-12">
              <div className="card" id="PermissionsTable">
                <div className="card-body">
                  <div className="flex items-center">
                    <h6 className="text-15 grow">Permissions</h6>
                    <div className="shrink-0">
                      <button
                        type="button"
                        className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                        onClick={handleAddPermissions}
                      >
                        <Plus className="inline-block size-4" />{" "}
                        <span className="align-middle">Add Permission</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
                  <form action="#!">
                    <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                      <div className="xl:col-span-2"> Role
                        <Select
                          className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                          options={roles}
                          isSearchable={true}
                          value={selectedRole}
                          defaultValue={selectedRole}
                          onChange={(event: any) => handleRoleChange(event)}
                          id="choices-single-default"
                        />
                      </div>
                      <div className="xl:col-span-2"> Resource
                        <Select
                          className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                          options={resources}
                          isSearchable={true}
                          value={selectedResource}
                          defaultValue={selectedResource}
                           onChange={(event: any) => handleResourceChange(event)}
                          id="choices-single-default"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                {isLoading ? (
                  <Loader />
                ) : (
                  <div className="!pt-1 card-body">
                    {Permissions && Permissions?.length > 0 ? (
                      <TableContainer
                        isPagination={true}
                        columns={columns || []}
                        data={Permissions || []}
                        customPageSize={10}
                        divclassName="overflow-x-auto"
                        tableclassName="w-full whitespace-nowrap"
                        theadclassName="ltr:text-left rtl:text-right bg-slate-100 dark:bg-zink-600"
                        thclassName="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                        tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
                        PaginationClassName="flex flex-col items-center gap-4 px-4 mt-3 md:flex-row"
                      />
                    ) : (
                      <div className="noresult">
                        <div className="py-6 text-center">
                          <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                          <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* User Modal  */}
      <Modal
        show={show}
        onHide={toggle}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600"
      >
        {PermissionsData ? (
          <AddPermissions
            onSave={onSave}
            onHide={toggle}
            data={PermissionsData}
          />
        ) : (
          <></>
        )}
      </Modal>
    </React.Fragment>
  );
};
export default PermissionList;
