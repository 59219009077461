import React, { useEffect, useState } from 'react'
import Modal from 'Common/Components/Modal';
// Formik
import { Common } from 'helpers/common';
import { Loader2 } from 'lucide-react';
import { ProductVariantType } from 'types/ProductVariantType';
import Select, { SelectInstance } from 'react-select';
import Cleave from "cleave.js/react";

type Props = { onHide: () => void; data: any, onSave: (result: any) => void, ProductSizes: any[], brandDiscount: number }

const AddProductVariant: React.FC<Props> = ({ onHide, data, onSave, ProductSizes, brandDiscount }) => {

    const [isEdit, setIsEdit] = useState<boolean>(false);

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const commonHelper = new Common();
    const [errors, setErrors] = useState<any>({});

    const [productPrice, setProductPrice] = useState<ProductVariantType>(data);
    const [selectedProductSize, setSelectedProductSize] = useState<any>();

    useEffect(() => {
        loadProductColors();

        if (data?.id) {
            setIsEdit(true);
            if (data?.productsizesProductvariants) {
                setSelectedProductSize(ProductSizes.find(item => item.value === data.productsizesProductvariants.id));
                calculateSetPrice('', ''); // calculate NetPrice and SetPrice
            }
        } else {

            if (!productPrice?.discountPercent || productPrice?.discountPercent === 0) {
                setProductPrice({ ...productPrice, discountPercent: brandDiscount });
            }
        }

        console.log('productPrice', productPrice);
    }, [data])

    const loadProductColors = () => {
        const defaultColors = [
            { color: '#0ea5e9', selected: false },
            { color: '#f97316', selected: false },
            { color: '#249782', selected: false },
            { color: '#a855f7', selected: false },
            { color: '#eab308', selected: false },
            { color: '#ef4444', selected: false },
            { color: '#64748b', selected: false },
            { color: '#000000', selected: false },
        ]
        // combine defaultColors and productData?.productColor to get unique colors
        // const uniqueColors = [...defaultColors, ...(productData?.productColor?.map((color: string) => ({ color, selected: true })) || [])].filter((obj, index, self) =>
        //     index === self.findIndex((t) => t.color === obj.color));

        //const array3 = defaultColors.filter((obj) => productData?.productColor.indexOf(obj.color) === -1);
        console.log('uniqueColors', defaultColors)
        // setProductColors(defaultColors);
    }

    const handleChange = (e: any, field: string) => {

        switch (field) {
            case 'unitPrice':
                setProductPrice({ ...productPrice, unitPrice: e.target.value });
                break;
            case 'packOf':
                setProductPrice({ ...productPrice, packOf: e.target.value });
                break;
            case 'discountPercent':
                setProductPrice({ ...productPrice, discountPercent: e.target.value });
                break;
            case 'mrp':
                setProductPrice({ ...productPrice, mrp: e.target.value });
                break;
            case 'inventory':
                setProductPrice({ ...productPrice, inventory: e.target.value });
                break;
            case 'margin':
                setProductPrice({ ...productPrice, margin: e.target.value });
                break;
            default:
                break;
        }

        setTimeout(() => {
            //calculateSetPrice('','')
        }, 1000);
    }
    const handleBlur = (e: any) => {

    }

    const addRow = () => {
        // add code to validate all fields
        try {

            // add validation for all the fields in productPrice. check if empty
            const errors: any = {};
            setErrors(errors);

            if (!productPrice.unitPrice || productPrice.unitPrice == 0) {
                errors.unitPrice = 'Required';
            }
            if (!productPrice.productsizesProductvariants.id) {
                errors.productsize = 'Required';
            }
            if (!productPrice.packOf || productPrice.packOf == 0) {
                errors.packOf = 'Required';
            }
            // if (productPrice.colors?.length === 0) {
            //     errors.colors = 'Required';
            // }
            //console.log(productPrice.colors);
            console.log(errors);
            if (Object.keys(errors).length > 0) {
                setErrors(errors);
                return;
            }
            productPrice.sizeId = productPrice.productsizesProductvariants.id;

            onSave({ ...productPrice });


            //resetFields();
            // console.log(productVariantList, productPrice);
        } catch (err) {
            console.log(err);
        }
    }

    const resetFields = () => {
        setProductPrice({ ...productPrice, unitPrice: 0, setPrice: 0, netPrice: 0, discountPercent: 0, mrp: 0, packOf: 0, inventory: 0, margin: 0, productsizesProductvariants: { name: '', id: '' }, colors: [] });
        //loadProductColors();
    }

    // calculate set price


    const calculateSetPrice = (item: any, field: string) => {
        try {
            const unitPrice = productPrice.unitPrice || 0;
            const packOf = productPrice.packOf || 0;
            const discountPercent = productPrice.discountPercent || 0;
            const mrp = productPrice.mrp || 0;
            console.log(unitPrice, packOf, discountPercent);

            const discountValue = Math.round(((unitPrice / 100) * discountPercent) * 100) / 100.00;


            const netPrice = Math.round((unitPrice - discountValue) * 100) / 100;
            const setPrice = Math.round(netPrice * packOf * 100) / 100;

            setProductPrice({ ...productPrice, setPrice: setPrice, netPrice: netPrice, discountValue: discountValue });


            // productPrice.setPrice = setPrice;
            // productPrice.netPrice = netPrice;

            // discount can not be more than set price
            errors.discountPercent = '';
            errors.packOf = '';
            errors.unitPrice = '';
            errors.mrp = '';
            setErrors(errors);

            if (unitPrice <= 0 && field === 'unitPrice') {
                errors.unitPrice = 'Enter valid Unit Price';
            }

            if (packOf <= 0 && field === 'packOf') {
                errors.packOf = 'Enter valid Pack Of';
            }

            if (discountValue > unitPrice && field === 'discountPercent') {
                errors.discountPercent = 'Discount can not be more than Unit Price';
            }

            if (mrp > 0 && mrp < unitPrice && field === 'mrp') {
                errors.mrp = 'MRP can not be less than Unit Price';
            }

            console.log(errors);
            if (Object.keys(errors).length > 0) {
                setErrors(errors);
            }



        } catch (err) {
        }
    }
    return (
        <React.Fragment>
            <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
                <Modal.Title className="text-16">{!!isEdit ? "Edit Product Variant" : "Add Product Variant"}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">


                <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-12">
                    <div className="xl:col-span-9">
                        <label htmlFor="productsize" className="inline-block mb-2 text-base font-medium">Product Size</label>

                        <Select
                            className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                            options={ProductSizes || []}
                            defaultValue={selectedProductSize}
                            value={selectedProductSize}
                            isSearchable={false} // To disable search
                            isDisabled={isEdit}
                            name="productsize"
                            id="productsize"
                            onChange={e => { setProductPrice({ ...productPrice, productsizesProductvariants: { name: e?.label || '', id: e?.value || '' } }); console.log(e); }}
                        />
                        {errors.productsize ? (
                            <div id="productsize-error" className="mt-1 text-sm text-red-500">{errors.productsize}</div>
                        ) : null}

                    </div>
                    <div className="xl:col-span-3">
                        <label htmlFor="brandDiscount" className="inline-block mb-2 text-base font-medium">Brand Discount</label>
                        <input type="decimal"
                            min={0}
                            maxLength={7}
                            id="unitPrice"
                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                            name="unitPrice"
                            disabled
                            placeholder="10.00"

                            value={`${brandDiscount}%` || ""}
                        ></input>
                    </div>

                    <div className="xl:col-span-3">
                        <label htmlFor="unitPrice" className="inline-block mb-2 text-base font-medium">Unit Price (Per Piece)</label>
                        {/* <input type="decimal"
                            min={0}
                            maxLength={7}
                            id="unitPrice"
                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                            name="unitPrice"
                            placeholder="10.00"
                            onKeyUp={(e: any) => { calculateSetPrice(e, 'unitPrice') }}
                            onChange={(e: any) => { handleChange(e, 'unitPrice') }} // handleChange}
                            onBlur={handleBlur}
                            value={productPrice.unitPrice || ""}
                        ></input> */}


                        <Cleave
                            placeholder="10.00"
                            options={{
                                numeral: true,
                                numeralThousandsGroupStyle: 'thousand'
                            }}
                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                            name="unitPrice"
                            id="unitPrice"
                            onKeyUp={(e: any) => { calculateSetPrice(e, 'unitPrice') }}
                            onChange={(e: any) => { handleChange(e, 'unitPrice') }} // handleChange}
                            onBlur={handleBlur}
                            value={productPrice.unitPrice || ""}
                        />
                        {errors.unitPrice ? (
                            <div id="unitPrice-error" className="mt-1 text-sm text-red-500">{errors.unitPrice}</div>
                        ) : null}
                    </div>
                    <div className="xl:col-span-3">
                        <label htmlFor="discountPercent" className="inline-block mb-2 text-base font-medium">Discount(%)</label>
                        <Cleave id="discountPercent" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                            placeholder="10"
                            onKeyUp={(e: any) => { calculateSetPrice(e, 'discountPercent') }}
                            onChange={(e: any) => { handleChange(e, 'discountPercent') }}
                            onBlur={handleBlur}
                            options={{
                                numeral: true,
                                // numeralDecimalScale: 2,
                                // numeralPositiveOnly: true,
                                // prefix: '%',
                                // noImmediatePrefix: true,
                                // rawValueTrimPrefix: true
                            }}
                            value={productPrice.discountPercent || ""}
                            min={0}
                            maxLength={2}


                        />
                        {errors.discountPercent ? (
                            <div id="discountPercent-error" className="mt-1 text-sm text-red-500">{errors.discountPercent}</div>
                        ) : null}

                    </div>

                    <div className="xl:col-span-3">
                        <label htmlFor="netPrice" className="inline-block mb-2 text-base font-medium">Net Price</label>
                        <input type="number" id="netPrice" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="₹0.00"
                            disabled
                            value={productPrice.netPrice || ""}
                        ></input>
                    </div>

                    <div className="xl:col-span-3">
                        <label htmlFor="packOf" className="inline-block mb-2 text-base font-medium">Pack Of (Quantity Per Set)</label>
                        <Cleave id="packOf"
                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                            name="packOf"
                            min={0}
                            options={{
                                numeral: true,
                                numeralThousandsGroupStyle: 'thousand'
                            }}
                            maxLength={2}
                            placeholder="10"
                            onKeyUp={(e: any) => { calculateSetPrice(e, 'packOf') }}
                            onChange={(e: any) => { handleChange(e, 'packOf') }}
                            onBlur={handleBlur}
                            value={productPrice.packOf || ""}
                        />
                        {errors.packOf ? (
                            <div id="packOf-error" className="mt-1 text-sm text-red-500">{errors.packOf}</div>
                        ) : null}
                    </div>
                    <div className="xl:col-span-3">
                        <label htmlFor="setPrice" className="inline-block mb-2 text-base font-medium">Set/Pack Price </label>
                        <input type="number" id="setPrice" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="₹0.00"
                            disabled
                            value={productPrice.setPrice || ""}
                        ></input>
                    </div>




                    <div className="xl:col-span-3">
                        <label htmlFor="mrp" className="inline-block mb-2 text-base font-medium">MRP</label>
                        <Cleave id="mrp"
                            onKeyUp={(e: any) => { calculateSetPrice(e, 'mrp') }}
                            onChange={(e: any) => { handleChange(e, 'mrp') }}
                            onBlur={handleBlur}
                            value={productPrice.mrp || 0}
                            options={{
                                numeral: true,
                                numeralThousandsGroupStyle: 'thousand'
                            }}
                            min={0}
                            maxLength={7}
                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="₹0.00"
                        />
                        {errors.mrp ? (
                            <div id="mrp-error" className="mt-1 text-sm text-red-500">{errors.mrp}</div>
                        ) : null}

                    </div>
                    <div className="xl:col-span-3">
                        <label htmlFor="margin" className="inline-block mb-2 text-base font-medium">Margin</label>
                        <Cleave id="margin" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="100"
                            onChange={(e: any) => { handleChange(e, 'margin') }}
                            onBlur={handleBlur}
                            value={productPrice.margin || ""}
                            min={0}
                            maxLength={5}
                            options={{
                                numeral: true,
                                numeralThousandsGroupStyle: 'thousand'
                            }}
                        />
                        {errors.margin ? (
                            <div id="margin-error" className="mt-1 text-sm text-red-500">{errors.margin}</div>
                        ) : null}

                    </div>
                    <div className="xl:col-span-3">
                        <label htmlFor="inventory" className="inline-block mb-2 text-base font-medium">Inventory</label>
                        <Cleave id="inventory" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="100"
                            onChange={(e: any) => { handleChange(e, 'inventory') }}
                            onBlur={handleBlur}
                            value={productPrice.inventory || ""}
                            min={0}
                            maxLength={5}
                            options={{
                                numeral: true,
                                numeralThousandsGroupStyle: 'thousand'
                            }}
                        />
                        {errors.inventory ? (
                            <div id="inventory-error" className="mt-1 text-sm text-red-500">{errors.inventory}</div>
                        ) : null}

                    </div>

                </div>

                <div className="flex justify-end gap-2 mt-4">
                    <button type="reset" data-modal-close="addDocuments" className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500" onClick={onHide}>Cancel</button>

                    {!isSaving ?
                        <button type="button"
                            onClick={() => addRow()}
                            className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                            {!!isEdit ? "Update Product Variant" : "Add Product Variant"}

                        </button>
                        : <button type="button" disabled className="flex items-center text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                            <Loader2 className="size-4 ltr:mr-2 rtl:ml-2 animate-spin" />
                            Saving...
                        </button>
                    }

                </div>

            </Modal.Body>
        </React.Fragment >
    )
}

export default AddProductVariant;