import axiosPrivate from './axiosPrivate'
import imageAxios from './imageAxios'

export const setAuthorization = (token: any) => {
  //axiosPrivate.headers.common["Authorization"] =  token;
  //axiosPrivate.defaults.headers.common["Authorization"] = "Bearer " + token;
};

export class APIClient {
  get = async (url: any, params: any) => {
    let response;

    let paramKeys: any = [];

    if (params) {
      Object.keys(params).map(key => {
        paramKeys.push(key + '=' + params[key]);
        return paramKeys;
      });

      const queryString = paramKeys && paramKeys.length ? paramKeys.join('&') : "";
      response = axiosPrivate.get(`${url}?${queryString}`, params);
    } else {
      response = axiosPrivate.get(`${url}`, params);
    }

    return this.sanitizeResponse(await response);
  };

  sanitizeResponse = (response: any) => {
    let res = {
      data:  [] as any[],
      message: '',
      success: false
    }

    try {

      const responseData= response.data?.rows || response.data || response;

      if(Array.isArray(responseData)){
        res.data = responseData;
      }else{
        res.data = [responseData];
      }
      //res.data =  response.data?.rows || response.data || response;
      res.message = response.message;
      res.success = response.success;
    } catch (error) {
      res.message = error as string;
      res.success = false;
    }

    return res;
  }



  setDefaultContentType = () => {
    axiosPrivate.defaults.headers.post["Content-Type"] = "application/json";
  };

  setContentType = (type: string) => {
    if (type === 'form-data') {
      axiosPrivate.defaults.headers.post["Content-Type"] = "multipart/form-data";
    } else {
      axiosPrivate.defaults.headers.post["Content-Type"] = "application/json";
    }
  }

  /**
  * post given data to url with images
  */
  createWithFormData = async (url: any, data: any) => {
    console.log(url, data);
    let response = await imageAxios.post(url, data);
    return response;
  };

  /**
 * Updates data with images
 */
  updateWithFormData = (url: any, data: any) => {
    this.setContentType('form-data');
    return axiosPrivate.put(url, data);
  };


  /**
   * post given data to url
   */
  create = (url: any, data: any) => {
    this.setContentType('json');
    return axiosPrivate.post(url, data);
  };
  /**
   * Updates data
   */
  update = (url: any, data: any) => {
    this.setContentType('json');
    return axiosPrivate.patch(url, data);
  };

  put = (url: any, data: any) => {
    this.setContentType('json');
    return axiosPrivate.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url: any, config: any) => {
    this.setContentType('json');
    return axiosPrivate.delete(url, { ...config });
  };
}
export const getLoggedUser = () => {

  const user = localStorage.getItem("token");
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};
