import axios from "axios";

const BASE_URL = 'https://imgdb.doeducation.in/api';

const imageAxios = axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'multipart/form-data',
        'x-api-key': `BMn6nPTxTBHomlODqrIt8BPUXMc2s88C69YcTzGogvbldquV4+u5uHWY2RkA1kixyHlay6X0P+LdgfvG+GXiU6di0LV+GUhbm3Z99WbdkYbSdE5iM6Txcc+KBOhcMlW9r224qy9ilbO/VCqbE3QveO1h6lk60BCWmOe6WIuOqSg=`
    },
});


imageAxios.interceptors.request.use(
    config => {
        // this is for image service
        // if (!config.headers['x-api-key']) {
        //     config.headers['x-api-key'] = `BMn6nPTxTBHomlODqrIt8BPUXMc2s88C69YcTzGogvbldquV4+u5uHWY2RkA1kixyHlay6X0P+LdgfvG+GXiU6di0LV+GUhbm3Z99WbdkYbSdE5iM6Txcc+KBOhcMlW9r224qy9ilbO/VCqbE3QveO1h6lk60BCWmOe6WIuOqSg=`;
        // }

        // if (!config.headers['Authorization']) {
        //     config.headers['Authorization'] = `Bearer ${token}`;
        // }
        return config;
    }, (error) => Promise.reject(error)
);

imageAxios.interceptors.response.use(
    (response) => response?.data || response,
    async (error) => {
        const originalRequest = error.config;

        // If the error status is 401 and there is no originalRequest._retry flag,
        // it means the token has expired and we need to refresh it
        if ((error.response.status === 401) && !originalRequest._retry) {
            originalRequest._retry = true;

            try {
                const refreshToken = localStorage.getItem('refreshToken');
                let data = { token: refreshToken }

                const response = await axios.post(BASE_URL + '/refreshtokens', data);

                console.log('useResponse', response.data.data);

                let authToken = response.data.data.authToken;

                authToken = authToken.replace('Bearer ', '');

                localStorage.setItem('token', authToken);

                // Retry the original request with the new token
                originalRequest.headers.Authorization = `Bearer ${authToken}`;
                return axios(originalRequest);
            } catch (error) {
                // Handle refresh token error or redirect to login
            }
        }
        if (error.response.status === 500) {
            return Promise.reject('Some error occured while processing the request. Please try again.');
        }

        return Promise.reject(error);
    }
);


export default imageAxios;