import React, { useEffect, useState } from 'react'
import Modal from 'Common/Components/Modal';
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import Flatpickr from "react-flatpickr";
import { Common } from 'helpers/common';
import {
    addBannerList as onAddBannerList,
    updateBannerList as onUpdateBannerList,
    uploadBannerImage,
} from '../../../helpers/backend_helper';

import { Loader2, UploadCloud } from 'lucide-react';
import Dropzone from 'react-dropzone';

type Props = { onHide: () => void; data: any, onSave: (result: any) => void }

const AddBanner: React.FC<Props> = ({ onHide, data, onSave }) => {

    const [bannerData, setBannerData] = useState<any>(data);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [editorData, setEditorData] = useState('<br/><br/>');
    const commonHelper = new Common();
    const [selectedFiles, setSelectedFiles] = React.useState<any>([])
    const [selectedImage, setSelectedImage] = useState<any>();

    const handleAcceptedFiles = (files: any) => {
        files.map((file: any) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: commonHelper.formatBytes(file.size),
            })
        )
        setSelectedFiles(files)
        setSelectedImage(files[0]);
        validation.setFieldValue('logo', 'htttp');
    }

    useEffect(() => {
        if (bannerData?.id) {
            setIsEdit(bannerData?.id);
            setEditorData(bannerData.description);
            console.log(bannerData);
            let obj = {
                preview: bannerData.logo,
                formattedSize: '',
                name: ''
            }
            setSelectedFiles((prev: any) => [{ ...obj }])
        }
    }, [])

    // validation
    const validation: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: (bannerData && bannerData.title) || '',
            description: (bannerData && bannerData.description) || '',
            logo: (bannerData && bannerData.logo) || '',
            url: (bannerData && bannerData.url) || '',
            startDate: (bannerData && bannerData.startDate) || '',
            endDate: (bannerData && bannerData.endDate) || '',
            isActive: (bannerData && bannerData.isActive) || true,
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Please Enter Title !"),
            description: Yup.string().required("Please Enter Description !"),
            logo: Yup.string().required("Please Select Logo !"),
            url: Yup.string().required("Please Enter URL !"),
            startDate: Yup.date().required("Please Enter Start Date !"),
            endDate: Yup.date().required("Please Enter End Date !"),
            isActive: Yup.bool().required("Please choose yes or no!")
        }),

        onSubmit: async (values: any) => {
            setIsSaving(true);
            var logo=null;
            // upload if any new images is selected..
            if(selectedImage?.name)
            {
                var imgResult = await uploadBannerImage({ fileCollection: selectedImage });
                console.log(imgResult);
                logo=imgResult?.data.rows[0].url;
            }
                        
            if (isEdit && bannerData) {
                try {
                    // update user
                    const updateData = {
                        ...values,
                        id: bannerData.id,
                        logo: logo?? values.logo
                    };

                    var result = await onUpdateBannerList(updateData);
                    commonHelper.showSuccessMessage('Banner has been successfully updated.');
                    onSave(result.data.rows[0]);

                } catch (error) {
                    console.log('API-Error', error);
                    commonHelper.showErrorMessage(error as string);
                    setIsSaving(false);
                }

                setIsEdit(false);
            } else {
                // save new user

                try {
                    const newData = { ...values,logo: logo };
                    var result = await onAddBannerList(newData);
                    commonHelper.showSuccessMessage('Banner has been successfully saved.');
                    onSave(result.data.rows[0]);
                } catch (error) {
                    console.log('API-Error', error);
                    commonHelper.showErrorMessage(error as string);
                    setIsSaving(false);
                }
            }
            setIsSaving(false);
        },

    });

    const handleStartDateChange = (selectedDates: any, dateStr: any, instance: any) => {
        console.log(dateStr)
        validation.setFieldValue('startDate', dateStr)
    }

    const handleEndDateChange = (selectedDates: any, dateStr: any, instance: any) => {
        validation.setFieldValue('endDate', dateStr)
    }

 
    return (
        <React.Fragment>
            <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
                <Modal.Title className="text-16">{!!isEdit ? "Edit Banner" : "Add Banner"}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
                <form action="#!" onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                    <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-6">
                        <div className="lg:col-span-2 xl:col-span-12">
                            <label htmlFor="title" className="inline-block mb-2 text-base font-medium">Title</label>
                            <input type="text" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                id="title"
                                name="title"
                                placeholder="Title"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.title || ""} />
                            {validation.touched.title && validation.errors.title ? (
                                <div id="title-error" className="mt-1 text-sm text-red-500">{validation.errors.title}</div>
                            ) : null}
                        </div>

                        <div className="lg:col-span-2 xl:col-span-12">
                            <label htmlFor="startDate" className="inline-block mb-2 text-base font-medium">Start Date</label>
                            <Flatpickr
                                id="startDate"
                                options={{
                                    altInput: true,
                                    altFormat: "d/m/Y",
                                    dateFormat: "m/d/Y",
                                 }}
                                onChange={handleStartDateChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.startDate || ""}
                                placeholder="Select Date"
                                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                            />
                            {validation.touched.startDate && validation.errors.startDate ? (
                                <div id="startDate-error" className="mt-1 text-sm text-red-500">{validation.errors.startDate}</div>
                            ) : null}
                        </div>

                        <div className="lg:col-span-2 xl:col-span-12">
                            <label htmlFor="endDate" className="inline-block mb-2 text-base font-medium">End Date</label>
                            <Flatpickr
                                id="endDate"
                                options={{
                                    altInput: true,
                                    altFormat: "d/m/Y",
                                    dateFormat: "m/d/Y",
                                }}
                                onChange={handleEndDateChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.endDate || ""}
                                placeholder="Select Date"
                                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                            />
                            {validation.touched.endDate && validation.errors.endDate ? (
                                <div id="endDate-error" className="mt-1 text-sm text-red-500">{validation.errors.endDate}</div>
                            ) : null}
                        </div>
                        <div className="lg:col-span-2 xl:col-span-12">
                            <label htmlFor="title" className="inline-block mb-2 text-base font-medium">Url</label>
                            <input type="text" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                id="url"
                                name="url"
                                placeholder="URL"

                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.url || ""} />
                            {validation.touched.url && validation.errors.url ? (
                                <div id="url-error" className="mt-1 text-sm text-red-500">{validation.errors.url}</div>
                            ) : null}
                        </div>


                        <div className="lg:col-span-2 xl:col-span-12">
                            <h6 className="mb-4 text-15">Description</h6>
                            <div className="ckeditor-classic text-slate-800">
                            <textarea className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    id="description"
                                    name="description"
                                    placeholder="Enter Description"
                                    rows={2}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.description || ""}
                                ></textarea>
                                {validation.touched.description && validation.errors.description ? (
                                    <div id="title-error" className="mt-1 text-sm text-red-500">{validation.errors.description}</div>
                                ) : null}
                            </div>
                        </div>


                        
                        <div className="lg:col-span-2 xl:col-span-12">
                            <label htmlFor="isActive" className="inline-block mb-2 text-base font-medium">Category Image</label> <br></br>

                            <div className="flex items-center justify-center bg-white border border-dashed rounded-md cursor-pointer  border-slate-300 dark:bg-zink-700 dark:border-zink-500">
                                <Dropzone
                                    onDrop={(acceptedFiles: any) => {
                                        handleAcceptedFiles(acceptedFiles)
                                    }}
                                >
                                    {({ getRootProps, getInputProps }: any) => (
                                        <div
                                            className="w-full py-5 text-lg text-center dz-message needsclick"
                                            {...getRootProps()}
                                        >
                                            <input {...getInputProps()} />
                                            <div className="mb-3">
                                                <UploadCloud className="block size-12 mx-auto text-slate-500 fill-slate-200 dark:text-zink-200 dark:fill-zink-500"></UploadCloud>
                                            </div>

                                            <h5 className="mb-0 font-normal text-slate-500 text-15">Drag and drop category image or <a href="#!">browse</a></h5>
                                        </div>
                                    )}
                                </Dropzone>  <ul className="flex flex-wrap mb-0 gap-x-5" id="dropzone-preview2">
                                {
                                    (selectedFiles || [])?.map((f: any, i: any) => {
                                        return (
                                            <li className="mt-2" id="dropzone-preview-list" key={i + "-file"}>
                                                <div className="border rounded border-slate-200 dark:border-zink-500">
                                                    <div className="p-2 text-center">
                                                        <div>
                                                            <div className="p-2 mx-auto rounded-md size-14 bg-slate-100 dark:bg-zink-600">
                                                                <img data-dz-thumbnail className="block w-full h-full rounded-md" src={f.preview} alt={f.name} />
                                                            </div>
                                                        </div>
                                                        {/* <div className="pt-3">
                                                            <h5 className="mb-1 text-15" data-dz-name>{f.name}</h5>
                                                            <p className="mb-0 text-slate-500 dark:text-zink-200" data-dz-size>{f.formattedSize}</p>
                                                        </div> */}
                                                        <div className="mt-2">
                                                            <button data-dz-remove
                                                                className="px-2 py-1.5 text-xs text-white bg-red-500 border-red-500 btn hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:border-red-600 active:ring active:ring-red-100 dark:ring-custom-400/20"
                                                                onClick={() => {
                                                                    const newImages = [...selectedFiles];
                                                                    newImages.splice(i, 1);
                                                                    setSelectedFiles(newImages);
                                                                }}
                                                            >Delete</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                            </div>

                          
                            <input type="hidden" id="logo" name="logo" />
                            {validation.touched.logo && validation.errors.logo ? (
                                <div id="logo-error" className="mt-1 text-sm text-red-500">{validation.errors.logo}</div>
                            ) : null}
                        </div>
                        
                    </div>

                    <div className="flex justify-end gap-2 mt-4">
                        <button type="reset" data-modal-close="addDocuments" className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500" onClick={onHide}>Cancel</button>

                        {!isSaving ?
                            <button type="submit" className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                {!!isEdit ? "Update Banner" : "Add Banner"}
                            </button>
                            : <button type="button" disabled className="flex items-center text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                <Loader2 className="size-4 ltr:mr-2 rtl:ml-2 animate-spin" />
                                Saving...
                            </button>
                        }

                    </div>
                </form>
            </Modal.Body>
        </React.Fragment>
    )
}

export default AddBanner;