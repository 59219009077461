import React, { useCallback, useEffect, useMemo, useState } from "react";
import Flatpickr from "react-flatpickr";
import Modal from "Common/Components/Modal";
import TableContainer from 'Common/TableContainer';

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import moment from "moment";
import { getOrderStatusById, updateOrderStatus } from "helpers/backend_helper";
import { Link } from 'react-router-dom';
import { Trash2 } from "lucide-react";


type Props = { onHide: () => void; orderDetail: any, onSave: (result: any) => void }

export const OrderStatus: React.FC<Props> = ({ onHide, orderDetail, onSave }) => {

    const [statusList, setStatusList] = useState<any[]>([]);
    const [orderStatusList, setOrderStatusList] = useState<any[]>([]);
    
    useEffect(() => {
        loadStatusList();
    }, [])

    useEffect(() => {
        loadOrderStatus();
    }, [orderDetail])

    const loadOrderStatus=async () => {   
        const response = await getOrderStatusById(orderDetail && orderDetail.id || 0);
        console.log("Order Status", response.data);
        setOrderStatusList(response.data);
    }

    const loadStatusList = () => {
        setStatusList([
            "Order Placed",
            "Order Accepted",
            "Order Processing",
            "Order Shipped",
            "Order Delivered",
            "Order Completed",
            "Order Canceled",
            "Order Rejected"]);
    }


    // validation
    const validation: any = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            orderNo: (orderDetail && orderDetail.orderNo) || '',
            orderId: (orderDetail && orderDetail.id) || '',
            orderDate: (orderDetail && moment(orderDetail.orderDate).format("DD MMMM YYYY")) || '',
            statusDate: (orderDetail && orderDetail.statusDate) || '',
            orderStatus: (orderDetail && orderDetail.orderStatus) || '',
            comment: ''
        },
        validationSchema: Yup.object({
            statusDate: Yup.string().required("Please Enter Date"),
            orderStatus: Yup.string().required("Please Enter Name"),
        }),

        onSubmit: async (values) => {
            console.log(values);
            values.statusDate = moment(values.statusDate).format("YYYY-MM-DD");
           // values.comment = values.comment.trim().length > 0 ? values.comment : '   ';
            try {
                const result = await updateOrderStatus(values);
                console.log(result);
                onSave(result);
            } catch (err) {
                onSave(null);
            }
        },
    });


    const columns = useMemo(() => [
        {
            header: "Status Name",
            accessorKey: "statusName",
            enableColumnFilter: false,
            enableSorting: false,
        },
        {
            header: "Date",
            accessorKey: "statusDate",
            enableColumnFilter: false,
            cell: (cell: any) => (
                <p>{!cell.getValue() ? '' : moment(cell.getValue()).format('DD MMM, yyyy hh:mm a')}</p>
            ),
        },
        {
            header: "Comment",
            accessorKey: "comment",
            enableColumnFilter: false,
        },
        {
            header: "Action",
            enableColumnFilter: false,
            enableSorting: false,
            cell: (cell: any) => (

                <div className="flex justify-start gap-2" >

                    <Link to="#!"
                        className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-red-500 bg-red-100 hover:text-white hover:bg-red-500 dark:bg-red-500/20 dark:hover:bg-red-500"
                        onClick={() => {
                            // const orderData = cell.row.original;
                            // onClickDelete(orderData);
                        }}><Trash2 className="inline-block size-4 ltr:mr-1 rtl:ml-1" /> </Link>
                </div>
            ),
        }
    ], []
    );



    return (
        <React.Fragment>
            <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
                <Modal.Title className="text-16">{"Update Order Status"}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
                <form action="#!" onSubmit={(e) => {
                    e.preventDefault();
                    validation.submitForm();
                    return false;
                }}>
                    <div className="grid grid-cols-1 gap-4 xl:grid-cols-12">
                        <div className="xl:col-span-6">
                            <label htmlFor="orderNo" className="inline-block mb-2 text-base font-medium">Order #</label>
                            <input type="text" id="orderNo" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                             disabled value={validation.values.orderNo || "N/A"} required />
                        </div>
                        <div className="xl:col-span-6">
                            <label htmlFor="orderDate" className="inline-block mb-2 text-base font-medium">Order Date</label>
                            <input type="text" id="orderDate" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Customer name"
                                name="orderDate"
                                value={validation.values.orderDate || ""}
                                disabled={true}
                            />
                        </div>

                        <div className="xl:col-span-6">
                            <label htmlFor="orderStatus" className="inline-block mb-2 text-base font-medium">Order Status</label>
                            <select className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" data-choices data-choices-search-false id="deliveryStatusSelect"
                                name="orderStatus"
                                onChange={validation.handleChange}
                                value={validation.values.orderStatus || ""}
                            >
                                {statusList.map((item, index) => (
                                    <option key={index} value={item}>{item}</option>
                                ))}

                            </select>
                            {validation.touched.orderStatus && validation.errors.orderStatus ? (
                                <p className="text-red-400">{validation.errors.orderStatus}</p>
                            ) : null}
                        </div>

                        <div className="xl:col-span-6">
                            <label htmlFor="statusDate" className="inline-block mb-2 text-base font-medium">Status Date</label>
                            {/* <Flatpickr
                                id="statusDate"
                                className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                options={{
                                    dateFormat: "d M, Y"
                                }}
                                placeholder='Select date' name='statusDate'
                                onChange={(date: any) => validation.setFieldValue("statusDate", moment(date[0]).format("DD MMMM ,YYYY"))}
                                value={validation.values.statusDate || ''}
                            /> */}

                            <input type="date" id="statusDate" 
                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Customer name"
                                name="statusDate"
                                value={validation.values.statusDate || ""}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                            />

                            {validation.touched.statusDate && validation.errors.statusDate ? (
                                <p className="text-red-400">{validation.errors.statusDate}</p>
                            ) : null}
                        </div>
                        <div className="xl:col-span-12">
                            <label htmlFor="comment" className="inline-block mb-2 text-base font-medium">Comment</label>
                            <input type="text" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                id="comment" placeholder="Enter Comment"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.comment || ""}
                            ></input>
                            {validation.touched.description && validation.errors.comment ? (
                                <div id="comment-error" className="mt-1 text-sm text-red-500">{validation.errors.comment}</div>
                            ) : null}

                        </div>



                    </div>
                    <div className="flex justify-end gap-2 mt-4">
                        <button type="reset" className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-600 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10" onClick={onHide}>Cancel</button>
                        <button type="submit" className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                            {"Update Status"}
                        </button>
                    </div>


                    <div className="!pt-1 card-body">
                                        {orderStatusList && orderStatusList?.length > 0 ?
                                            <TableContainer
                                                isPagination={true}
                                                columns={(columns || [])}
                                                data={(orderStatusList || [])}
                                                customPageSize={10}
                                                divclassName="overflow-x-auto"
                                                tableclassName="w-full whitespace-nowrap"
                                                theadclassName="ltr:text-left rtl:text-right bg-slate-100 dark:bg-zink-600"
                                                thclassName="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                                                tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
                                                PaginationClassName="flex flex-col items-center gap-4 px-4 mt-3 md:flex-row"
                                            />
                                            :
                                            (<div className="noresult">
                                                <div className="py-6 text-center">
                                                    <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                                                </div>
                                            </div>)}
                                    </div>
                </form>
            </Modal.Body>
        </React.Fragment>
    )
};