import React, { useCallback, useEffect, useMemo, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";
import { Link } from "react-router-dom";
import TableContainer from "Common/TableContainer";
import Select from "react-select";
import { Loader } from "../../../Common/Loader";
// Icons
import { Pencil, Search, Trash2, Plus } from "lucide-react";
import Modal from "Common/Components/Modal";
import DeleteModal from "Common/DeleteModal";

import { getCityList, deleteCityList, getDistrictList, getStateList } from "../../../helpers/backend_helper";
import { ToastContainer } from "react-toastify";
import { Common } from "helpers/common";
import { AddCity } from "./AddCity";
import { CityType } from "../../../types/CityType";
import { RegionType } from "../../../types/RegionType";
import { DistrictType } from "../../../types/DistrictType";


function CityList() {
  const [allCities, setAllCities] = useState<CityType[] | null>(null)
  const [stateList, setStateList] = useState<RegionType[]>([]);
  const [selectedState, setSelectedState] = useState<RegionType>();
  const [districtOptions, setDistrictOptions] = useState<RegionType[]>([]);
  const [selectedDistrict, setSelectedDistrict] = useState<RegionType>();
  const [allDistricts, setAllDistricts] = useState<DistrictType[]>([]);
  const [cityList, setCityList] = useState<CityType[]>([]);
  const [eventData, setEventData] = useState<any>();
  const [cityData, setCityData] = useState<any>();
  const [show, setShow] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const uiHelper = new Common();
  // Delete Modal
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const deleteToggle = () => setDeleteModal(!deleteModal);

  useEffect(() => {
    loadDataFromAPI();
  }, []);

  const loadDataFromAPI = async () => {
    try {
      await loadCityList(false);
      await loadDistricts();
      await loadStates();
    
    } catch (error) {
    }finally{
        setIsLoading(false)
    }
  }

  // LOADS CITIES
  async function loadCityList(reload: boolean) {
    try{
    
    if (cityList.length === 0 || reload) {
      let response = await getCityList();
      if (response.success) {
        setAllCities(response.data as CityType[]);
      }
    }
    
  } catch (error) {
  }finally{
      setIsLoading(false)
  }
  }

  // LOADS DISTRICTS
  const loadDistricts = async () => {
    try {
      const response = await getDistrictList();
      const data = response.data.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));

      const districtOptions = [{ value: " ", label: "Select" }, ...data];

      console.log("Districts", districtOptions);
      setDistrictOptions(districtOptions)
      setAllDistricts(response.data)
   
    } catch (error) {
    }finally{
        setIsLoading(false)
    }
  }


useEffect(() => {
  if(stateList.length > 0){
  setSelectedState(stateList[0] as RegionType);
  handleStateChange(stateList[0] as RegionType);
  setIsLoading(false);
  }
}, [stateList]);


  const loadStates = async () => {
    try {
      const response = await getStateList();

      const data = response.data.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));

      // const stateList = [{value:" ", label: "Select"},...data];

      console.log("States", stateList);
      setStateList(data);
     
    
    } catch (error) {
    }finally{
        setIsLoading(false)
    }
  }

  // Delete Data
  const onClickDelete = (cell: any) => {
    setDeleteModal(true);
    if (cell.id) {
      setEventData(cell);
      console.log(cell);
    }
  };

  const handleDelete = async () => {
    if (eventData) {
      await deleteCityList(eventData.id);
      setDeleteModal(false);
      uiHelper.showSuccessMessage("City has been deleted successfully.");
      // remove the item and then update list
      setCityList((cityList) =>
        cityList.filter((city: CityType) => city.id !== eventData.id)
      );
    }
  };


  // Update Data
  const handleEdit = (city: CityType) => {
    setCityData(city);
    setIsEdit(true);
    setShow(true);
  };

  const handleAddCity = () => {
    setCityData({});
    toggle();
  };

  const onSave = (result: CityType) => {
    // loadCityList(true);
    if (isEdit) {
      setCityList((prevCityList) =>
        prevCityList.map((city: CityType) =>
          city.id === cityData?.id ? result : city
        )
      );
      setIsEdit(false);
    } else {
      setCityList((prevCityList) => [result, ...prevCityList]);
    }
    toggle();
  };

  const toggle = useCallback(() => {
    setShow(!show);
    if (show) {
      setCityData(undefined);
    }
  }, [show]);

  const handleStateChange = (state: RegionType) => {
    setSelectedState(state);
    console.log("Selected State", state);

    // Filter districts by selected state
    const filteredDistricts = allDistricts.filter(
      (dist) => dist.statesDistricts?.id === state.value
    );

    // Convert filtered districts to options format
    const districtOptions = filteredDistricts.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    setDistrictOptions([{ value: " ", label: "Select" }, ...districtOptions]);

    // Filter cities by filtered districts
    const filteredCities = allCities?.filter((city) => filteredDistricts.some((dist) => dist.id === city.districtId));
    setCityList(filteredCities??[]);
  };

  const handleDistrictChange = (district: RegionType) => {

    setSelectedDistrict(district);
    console.log("Selected District", district);
    console.log("allCities", allCities);
     // Filter cities by filtered districts
     const filteredCities = district.value === " "? allCities?.filter((city) =>city.districtId === district.value) : allCities?.filter((city) =>city.districtId === district.value);
  
    setCityList(filteredCities??[]);

  };

  // Search Data
  // const filterSearchData = (e: any) => {
  //   const search = e.target.value;
  //   const keysToSearch = ["name","isActive"];
  //   filterDataBySearch(cityList, search, keysToSearch, setCityList);
  // };

  // columns
  const Status = ({ item }: any) => {
    switch (item) {
      case true:
        return (
          <span className="status px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-green-100 border-transparent text-green-500 dark:bg-green-500/20 dark:border-transparent">
            Active
          </span>
        );
      case false:
        return (
          <span className="status px-2.5 py-0.5 inline-block text-xs font-medium rounded border bg-orange-100 border-transparent text-orange-500 dark:bg-orange-500/20 dark:border-transparent">
            Inctive
          </span>
        );
    }
  };

  const columns = useMemo(
    () => [
      {
        header: "Districts",
        accessorKey: "districtsCities.name",
        enableColumnFilter: false,
        enableSorting: false,
      },

      {
        header: "City",
        accessorKey: "name",
        enableColumnFilter: false,
        enableSorting: false,
      },

      {
        header: "Status",
        accessorKey: "isActive",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => <Status item={cell.getValue()} />,
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (cell: any) => (
          <div className="flex justify-start gap-2">
            <Link
              data-modal-target="addUserModal"
              to="#!"
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-slate-500 bg-slate-100 hover:text-white hover:bg-slate-500 dark:text-zink-200 dark:bg-zink-600 dark:hover:text-white dark:hover:bg-zink-400"
              onClick={() => {
                const data = cell.row.original;
                handleEdit(data);
              }}
            >
              <Pencil className="inline-block size-4 ltr:mr-1 rtl:ml-1" />{" "}
            </Link>

            <Link
              to="#!"
              className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-red-500 bg-red-100 hover:text-white hover:bg-red-500 dark:bg-red-500/20 dark:hover:bg-red-500"
              onClick={() => {
                const orderData = cell.row.original;
                onClickDelete(orderData);
              }}
            >
              <Trash2 className="inline-block size-4 ltr:mr-1 rtl:ml-1" />{" "}
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  // const options = [
  //   { value: "Select Status", label: "Select Status" },
  //   { value: true, label: "Active" },
  //   { value: false, label: "inActive" },
  // ];

  return (
    <React.Fragment>
      <BreadCrumb title="Manage" pageTitle="City List" />
      <DeleteModal
        show={deleteModal}
        onHide={deleteToggle}
        onDelete={handleDelete}
      />
      <ToastContainer closeButton={false} limit={1} />
      <div className="card" id="cityListTable">
        <div className="card-body">
          <div className="grid grid-cols-1 gap-x-5 xl:grid-cols-12">
            <div className="xl:col-span-12">
              <div className="card" id="cityTable">
                <div className="card-body">
                  <div className="flex items-center">
                    <h6 className="text-15 grow">City List</h6>
                    <div className="shrink-0">
                      <button
                        type="button"
                        className="text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                        onClick={handleAddCity}
                      >
                        <Plus className="inline-block size-4" />{" "}
                        <span className="align-middle">Add City</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="!py-3.5 card-body border-y border-dashed border-slate-200 dark:border-zink-500">
                  <form action="#!">
                    <div className="grid grid-cols-1 gap-5 xl:grid-cols-12">
                      <div className="xl:col-span-2"> State 
                        <Select
                          className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                          options={stateList}
                          isSearchable={true}
                          value={selectedState}
                          defaultValue={selectedState}

                          onChange={(event: any) => handleStateChange(event)}
                          id="choices-single-default"
                        />
                      </div>
                      <div className="xl:col-span-2"> District 
                        <Select
                          className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                          options={districtOptions}
                          isSearchable={true}
                          value={selectedDistrict}
                          defaultValue={selectedDistrict}
                          onChange={(event: any) => handleDistrictChange(event)}
                          id="choices-single-default"
                        />
                      </div>
                    </div>
                  </form>
                </div>
                {isLoading ? (
                  <Loader />
                ) : (
                  <div className="!pt-1 card-body">
                    {cityList?.length > 0 ? (
                      <TableContainer
                        isPagination={true}
                        columns={columns || []}
                        data={cityList || []}
                        customPageSize={10}
                        divclassName="overflow-x-auto"
                        tableclassName="w-full whitespace-nowrap"
                        theadclassName="ltr:text-left rtl:text-right bg-slate-100 dark:bg-zink-600"
                        thclassName="px-3.5 py-2.5 font-semibold border-b border-slate-200 dark:border-zink-500"
                        tdclassName="px-3.5 py-2.5 border-y border-slate-200 dark:border-zink-500"
                        PaginationClassName="flex flex-col items-center gap-4 px-4 mt-3 md:flex-row"
                      />
                    ) : (
                      <div className="noresult">
                        <div className="py-6 text-center">
                          <Search className="size-6 mx-auto text-sky-500 fill-sky-100 dark:sky-500/20" />
                          <h5 className="mt-2 mb-1">Sorry! No Result Found</h5>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* User Modal  */}
      <Modal
        show={show}
        onHide={toggle}
        id="defaultModal"
        modal-center="true"
        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
        dialogClassName="w-screen md:w-[30rem] bg-white shadow rounded-md dark:bg-zink-600">
        {cityData ? (<AddCity onSave={onSave} onHide={toggle} data={cityData} allDistricts={allDistricts} selectedState={selectedState as RegionType}  selectedDist={selectedDistrict as RegionType}  />) : (<></>)}
      </Modal>
    </React.Fragment>
  );
}

export default CityList;
