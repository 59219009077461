import { Award, CalendarDays, CircuitBoard, Codesandbox, FileText, LifeBuoy, LocateFixed, Mail, Map, MessageSquare, MonitorDot, PackagePlus, PictureInPicture2, PieChart, RadioTower, ScrollText, Settings, Share2, ShoppingBag, Table, Trophy, UserRound } from "lucide-react";

const menuData: any = [
    // {
    //     label: 'menu',
    //     isTitle: true,
    // },
    {
        id: "dashboard",
        label: 'Dashboards',
        link: "/dashboard",
        icon: <MonitorDot />,
    },
    {
        id: "ecommerce",
        label: 'Catalog',
        link: "/#",
        icon: <ShoppingBag />,
        subItems: [
            {
                id: 'category',
                label: 'Categories',
                parentId: 'ecommerce',
                link: '/category-list',
            },
            {
                id: 'brands',
                label: 'Brands',
                link: '/brands-list',
                parentId: 'ecommerce',
            },
            {
                id: 'product',
                label: 'Products',
                parentId: 'ecommerce',
                subItems: [
                    {
                        id: 'listview',
                        label: 'Manage Product',
                        link: '/product-list',
                        parentId: 'product'
                    },
                    {
                        id: 'addnew',
                        label: 'Add Product',
                        link: '/product-create',
                        parentId: 'product'
                    },
                ]
            },
            {
                id: 'attribute',
                label: 'Product Attributes',
                link: '/attribute-list',
                parentId: 'ecommerce'
            },
            {
                id: 'attributevalue',
                label: 'Product Atttribute Values',
                link: '/attribute-value-list',
                parentId: 'ecommerce'
            },
            {
                id: 'productsize',
                label: 'Product Sizes',
                link: '/product-size-list',
                parentId: 'ecommerce'
            },           
            {
                id: 'productcolor',
                label: 'Product Color',
                link: '/product-color-list',
                parentId: 'ecommerce'
            },
            
            
        ]
    },
   
    {
        id: 'contentmanagement',
        label: 'Content Management',
        icon: <FileText />,
        parentId: 2,
        subItems: [
            {
                id: 'announcement',
                label: 'Announcements',
                link: '/announcement-list',
                parentId: 'contentmanagement'
            },
            {
                id: 'FAQ',
                label: 'FAQ',
                link: '/faq-list',
                parentId: 'contentmanagement'
            },
            {
                id: 'CMS',
                label: 'CMS',
                link: '/cms-list',
                parentId: 'contentmanagement'
            }
            ,
            {
                id: 'Banner',
                label: 'Banners',
                link: '/banner-list',
                parentId: 'contentmanagement'
            }
            ,
            {
                id: 'Coupon',
                label: 'Coupons',
                link: '/coupon-list',
                parentId: 'contentmanagement'
            },
            
        ]
    },
    {
        id: 'configuration',
        label: 'Configuration',
        icon: <Settings />,
        parentId: 2,
        subItems: [
            {
                id: 'common-settings',
                label: 'Common Settings',
                link: '/common-settings',
                
                parentId: 'configuration'
            },{
                id: 'email-settings',
                label: 'Email Settings',
                link: '/email-settings',
                parentId: 'configuration'
            },
                // {
                //     id: 'season',
                //     label: 'Manage Season',
                //     link: '/season-list',
                //     parentId: 'configuration'
                // }
                // ,
            {
                id: 'district',
                label: 'Districts',
                link: '/district-list',
                parentId: 'configuration'
            },
            {
                id: 'city',
                label: 'Cities',
                link: '/city-list', 
                parentId: 'configuration'
            }, 
            {
                id: 'userlist',
                label: 'Users',
                link: '/userlist',
                parentId: 'configuration'
            },
            
            // {
            //     id: 'user-roles',
            //     label: 'User Roles',
            //     link: '/user-roles',
            //     parentId: 'configuration'
            // },
            {
                id: 'roles',
                label: 'Roles',
                link: '/roles',
                parentId: 'configuration'
            },
            {
                id: 'resources',
                label: 'Resources',
                link: '/resources',
                parentId: 'configuration'
            }, 

            {
                id: 'permissions',
                label: 'Permissions',
                link: '/permissions',
                parentId: 'configuration'
            }
        ]
    },
    {
        id: 'order-management',
        label: 'Order Management',
        icon: <FileText />,
        parentId: 2,
        subItems: [
            {
                id: 'orderlisty',
                label: 'Orders',
                link: '/orders',
                parentId: 'order-management'
            }
            ,
            {
                id: 'carts',
                label: 'Carts',
                link: '/carts',
                parentId: 'order-management'
            },
            {
                id: 'wishlist',
                label: 'Wishlist',
                link: '/wishlist',
                parentId: 'order-management'
            },
            {
                id: 'returnrequest',
                label: 'Return Requests',
                link: '/return-request',
                parentId: 'order-management'
            }
        ]
    },
    {
        id: 'customer-management',
        label: 'Customer Management',
        icon: <UserRound />,
        parentId: 2,
        subItems: [
            {
                id: 'customers',
                label: 'Customers',
                link: '/customer-list',
                parentId: 'customer-management'
            },
            {
                id: 'track-activity',
                label: 'Track Activity',
                link: '/track-activity',
                parentId: 'customer-management'
            }
        ]
    },

    {
        id: 'report-management',
        label: 'Reports',
        icon: <UserRound />,
        parentId: 2,
        subItems: [
            {
                id: 'sales-summary',
                label: 'Sales Summary',
                link: '/sales-summary',
                parentId: 'report-management'
            },
            {
                id: 'low-inventory',
                label: 'Low Inventory',
                link: '/low-inventory',
                parentId: 'report-management'
            },
            {
                id: 'best-seller',
                label: 'Best Seller',
                link: '/best-seller',
                parentId: 'report-management'
            }
            ,
            {
                id: 'product-never-sold',
                label: 'Product Never Sold',
                link: '/product-never-sold',
                parentId: 'report-management'
            },
            {
                id: 'customer-order',
                label: 'Customer Order',
                link: '/customer-order',
                parentId: 'report-management'
            }
        ]
    },
    /*
    {
        label: 'Pages',
        isTitle: true,
    },
    {
        id: 'authentication',
        label: 'Authentication',
        icon: <Award />,
        parentId: 2,
        subItems: [
            {
                id: 'login',
                label: 'Login',
                parentId: 'social',
                subItems: [
                    {
                        id: 'basic',
                        label: 'Basic',
                        link: '/auth-login-basic',
                        parentId: 'login'
                    },
                    {
                        id: 'cover',
                        label: 'Cover',
                        link: '/auth-login-cover',
                        parentId: 'login'
                    },
                    {
                        id: 'boxed',
                        label: 'Boxed',
                        link: '/auth-login-boxed',
                        parentId: 'login'
                    },
                    {
                        id: 'modern',
                        label: 'Modern',
                        link: '/auth-login-modern',
                        parentId: 'login'
                    },
                ]
            },
            {
                id: 'register',
                label: 'Register',
                parentId: 'social',
                subItems: [
                    {
                        id: 'registerbasic',
                        label: 'Basic',
                        link: '/auth-register-basic',
                        parentId: 'register'
                    },
                    {
                        id: 'registercover',
                        label: 'Cover',
                        link: '/auth-register-cover',
                        parentId: 'register'
                    },
                    {
                        id: 'registerboxed',
                        label: 'Boxed',
                        link: '/auth-register-boxed',
                        parentId: 'register'
                    },
                    {
                        id: 'registermodern',
                        label: 'Modern',
                        link: '/auth-register-modern',
                        parentId: 'register'
                    },
                ]
            },
            {
                id: 'verifyemail',
                label: 'Verify Email',
                parentId: 'social',
                subItems: [
                    {
                        id: 'verifyemailbasic',
                        label: 'Basic',
                        link: '/auth-verify-email-basic',
                        parentId: 'verifyemail'
                    },
                    {
                        id: 'verifyemailcover',
                        label: 'Cover',
                        link: '/auth-verify-email-cover',
                        parentId: 'verifyemail'
                    },
                    {
                        id: 'verifyemailmodern',
                        label: 'Modern',
                        link: '/auth-verify-email-modern',
                        parentId: 'verifyemail'
                    },
                ]
            },
            {
                id: 'twostep',
                label: 'Two Steps',
                parentId: 'social',
                subItems: [
                    {
                        id: 'twostepbasic',
                        label: 'Basic',
                        link: '/auth-two-steps-basic',
                        parentId: 'twostep'
                    },
                    {
                        id: 'twostepcover',
                        label: 'Cover',
                        link: '/auth-two-steps-cover',
                        parentId: 'twostep'
                    },
                    {
                        id: 'twostepboxed',
                        label: 'Boxed',
                        link: '/auth-two-steps-boxed',
                        parentId: 'twostep'
                    },
                    {
                        id: 'twostepmodern',
                        label: 'Modern',
                        link: '/auth-two-steps-modern',
                        parentId: 'twostep'
                    },
                ]
            },
            {
                id: 'logout',
                label: 'Logout',
                parentId: 'social',
                subItems: [
                    {
                        id: 'logoutbasic',
                        label: 'Basic',
                        link: '/auth-logout-basic',
                        parentId: 'logout'
                    },
                    {
                        id: 'logoutcover',
                        label: 'Cover',
                        link: '/auth-logout-cover',
                        parentId: 'logout'
                    },
                    {
                        id: 'logoutboxed',
                        label: 'Boxed',
                        link: '/auth-logout-boxed',
                        parentId: 'logout'
                    },
                    {
                        id: 'logoutmodern',
                        label: 'Modern',
                        link: '/auth-logout-modern',
                        parentId: 'logout'
                    },
                ]
            },
            {
                id: 'resetpw',
                label: 'Reset Password',
                parentId: 'social',
                subItems: [
                    {
                        id: 'resetpwbasic',
                        label: 'Basic',
                        link: '/auth-reset-password-basic',
                        parentId: 'resetpw'
                    },
                    {
                        id: 'resetpwcover',
                        label: 'Cover',
                        link: '/auth-reset-password-cover',
                        parentId: 'resetpw'
                    },
                    {
                        id: 'resetpwboxed',
                        label: 'Boxed',
                        link: '/auth-reset-password-boxed',
                        parentId: 'resetpw'
                    },
                    {
                        id: 'resetpwmodern',
                        label: 'Modern',
                        link: '/auth-reset-password-modern',
                        parentId: 'resetpw'
                    },
                ]
            },
            {
                id: 'createpw',
                label: 'Create Password',
                parentId: 'social',
                subItems: [
                    {
                        id: 'createpwbasic',
                        label: 'Basic',
                        link: '/auth-create-password-basic',
                        parentId: 'createpw'
                    },
                    {
                        id: 'createpwcover',
                        label: 'Cover',
                        link: '/auth-create-password-cover',
                        parentId: 'createpw'
                    },
                    {
                        id: 'createpwboxed',
                        label: 'Boxed',
                        link: '/auth-create-password-boxed',
                        parentId: 'createpw'
                    },
                    {
                        id: 'createpwmodern',
                        label: 'Modern',
                        link: '/auth-create-password-modern',
                        parentId: 'createpw'
                    },
                ]
            }
        ]
    },
    {
        id: 'pages',
        label: 'Pages',
        icon: <Codesandbox />,
        parentId: 2,
        subItems: [
            {
                id: 'account',
                label: 'Account',
                link: '/pages-account',
                parentId: 'pages'
            },
            {
                id: 'setting',
                label: 'Settings',
                link: '/pages-account-settings',
                parentId: 'pages'
            },
            // {
            //     id: 'pricing',
            //     label: 'Pricing',
            //     link: '/pages-pricing',
            //     parentId: 'pages'
            // },
            {
                id: 'faq',
                label: 'FAQs',
                link: '/pages-faqs',
                parentId: 'pages'
            },
            {
                id: 'contactus',
                label: 'Contact US',
                link: '/pages-contact-us',
                parentId: 'pages'
            },
            {
                id: 'comingsoon',
                label: 'Coming Soon',
                link: '/pages-coming-soon',
                parentId: 'pages'
            },
            {
                id: 'errorpage',
                label: 'Error Pages',
                parentId: 'pages',
                subItems: [
                    {
                        id: '404',
                        label: '404',
                        link: '/pages-404',
                        parentId: 'errorpage'
                    },
                    // {
                    //     id: 'offline',
                    //     label: 'Offline',
                    //     link: '/pages-offline',
                    //     parentId: 'errorpage'
                    // }
                ]
            },
            {
                id: 'maintenance',
                label: 'Maintenance',
                link: '/pages-maintenance',
                parentId: 'pages'
            },
        ]
    },
    {
        label: 'Components',
        isTitle: true,
    },
    // {
    //     id: "uielement",
    //     label: 'UI Elements',
    //     link: "/#",
    //     icon: <LifeBuoy />,
    //     subItems: [
    //         {
    //             id: '1',
    //             label: 'Alerts',
    //             link: '/ui-alerts',
    //             parentId: "uielement"
    //         },
    //         {
    //             id: '2',
    //             label: 'Avatar',
    //             link: '/ui-avatar',
    //             parentId: "uielement"
    //         },
    //         {
    //             id: '3',
    //             label: 'Buttons',
    //             link: '/ui-buttons',
    //             parentId: "uielement"
    //         },
    //         {
    //             id: '4',
    //             label: 'Label',
    //             link: '/ui-label',
    //             parentId: "uielement"
    //         },
    //         {
    //             id: '5',
    //             label: 'Cards',
    //             link: '/ui-cards',
    //             parentId: "uielement"
    //         },
    //         {
    //             id: '6',
    //             label: 'Collapse',
    //             link: '/ui-collapse',
    //             parentId: "uielement"
    //         },
    //         {
    //             id: '7',
    //             label: 'Countdown',
    //             link: '/ui-countdown',
    //             parentId: "uielement"
    //         },
    //         {
    //             id: '8',
    //             label: 'Drawer',
    //             link: '/ui-drawer',
    //             parentId: "uielement"
    //         },
    //         {
    //             id: '9',
    //             label: 'Dropdown',
    //             link: '/ui-dropdown',
    //             parentId: "uielement"
    //         },
    //         {
    //             id: '10',
    //             label: 'Gallery',
    //             link: '/ui-gallery',
    //             parentId: "uielement"
    //         },
    //         {
    //             id: '11',
    //             label: 'Lists',
    //             link: '/ui-lists',
    //             parentId: "uielement"
    //         },
    //         {
    //             id: '12',
    //             label: 'Notification',
    //             link: '/ui-notification',
    //             parentId: "uielement"
    //         },
    //         {
    //             id: '13',
    //             label: 'Modal',
    //             link: '/ui-modal',
    //             parentId: "uielement"
    //         },
    //         {
    //             id: '14',
    //             label: 'Spinners',
    //             link: '/ui-spinners',
    //             parentId: "uielement"
    //         },
    //         {
    //             id: '15',
    //             label: 'Timeline',
    //             link: '/ui-timeline',
    //             parentId: "uielement"
    //         },
    //         {
    //             id: '16',
    //             label: 'Progress Bar',
    //             link: '/ui-progress-bar',
    //             parentId: "uielement"
    //         },
    //         {
    //             id: '17',
    //             label: 'Tooltip',
    //             link: '/ui-tooltip',
    //             parentId: "uielement"
    //         },
    //         {
    //             id: '18',
    //             label: 'Video',
    //             link: '/ui-video',
    //             parentId: "uielement"
    //         }
    //     ]
    // },
    // {
    //     id: 'plugin',
    //     label: 'Plugins',
    //     icon: <PackagePlus />,
    //     subItems: [
    //         {
    //             id: 'simplebar',
    //             label: 'Simplebar',
    //             link: '/plugins-simplebar',
    //             parentId: 'plugin'
    //         },
    //         {
    //             id: 'lightbox',
    //             label: 'Lightbox',
    //             link: '/plugins-lightbox',
    //             parentId: 'plugin'
    //         },
    //         {
    //             id: 'swiper',
    //             label: 'Swiper Slider',
    //             link: '/plugins-swiper-slider',
    //             parentId: 'plugin'
    //         },
    //         {
    //             id: 'scrollhint',
    //             label: 'Scroll Hint',
    //             link: '/plugins-scroll-hint',
    //             parentId: 'plugin'
    //         },
    //         {
    //             id: 'videoplayer',
    //             label: 'Video Player',
    //             link: '/plugins-video-player',
    //             parentId: 'plugin'
    //         },
    //     ]
    // },
   
    {
        id: 'navigation',
        label: 'Navigation',
        icon: <LocateFixed />,
        subItems: [
            {
                id: 'navbar',
                label: 'Navbar',
                link: '/navigation-navbars',
                parentId: 'navigation'
            },
            {
                id: 'tabs',
                label: 'Tabs',
                link: '/navigation-tabs',
                parentId: 'navigation'
            },
            {
                id: 'breadcrumb',
                label: 'Breadcrumb',
                link: '/navigation-breadcrumb',
                parentId: 'navigation'
            },
            {
                id: 'pagination',
                label: 'Pagination',
                link: '/navigation-pagination',
                parentId: 'navigation'
            },
        ]
    },
    {
        id: "form",
        label: 'Forms',
        link: "/#",
        icon: <LifeBuoy />,
        subItems: [
            {
                id: 'basicform',
                label: 'Basic',
                link: '/forms-basic',
                parentId: "form"
            },
            {
                id: 'validation',
                label: 'Validation',
                link: '/forms-validation',
                parentId: "form"
            },
            {
                id: 'inputmask',
                label: 'Input Mask',
                link: '/forms-input-mask',
                parentId: "form"
            },
            {
                id: 'select',
                label: 'Select',
                link: '/forms-select',
                parentId: "form"
            },
            {
                id: 'checkbox-radio',
                label: 'Checkbox & Radio',
                link: '/forms-checkbox-radio',
                parentId: "form"
            },
            {
                id: 'switches',
                label: 'Switches',
                link: '/forms-switches',
                parentId: "form"
            },
            {
                id: 'wizard',
                label: 'Wizard',
                link: '/forms-wizard',
                parentId: "form"
            },
            {
                id: 'file-upload',
                label: 'File Upload',
                link: '/forms-file-upload',
                parentId: "form"
            },
            {
                id: 'datepicker',
                label: 'Date Picker',
                link: '/forms-datepicker',
                parentId: "form"
            },
            {
                id: 'timepicker',
                label: 'Time Picker',
                link: '/forms-timepicker',
                parentId: "form"
            },
            {
                id: 'colorpicker',
                label: 'Color Picker',
                link: '/forms-colorpicker',
                parentId: "form"
            },
            {
                id: 'multi-select',
                label: 'Multi Select',
                link: '/forms-multi-select',
                parentId: "form"
            },
            {
                id: 'input-spin',
                label: 'Input Spin',
                link: '/forms-input-spin',
                parentId: "form"
            },
            {
                id: 'clipboard',
                label: 'Clipboard',
                link: '/forms-clipboard',
                parentId: "form"
            },

        ]
    },
    {
        id: 'tables',
        label: 'Tables',
        icon: <Table />,
        subItems: [
            {
                id: 'basictable',
                label: 'Basic Table',
                link: '/tables-basic',
                parentId: 'tables'
            },
            {
                id: 'datatable',
                label: 'Datatable',
                link: '/tables-datatable',
                parentId: 'tables'
            }
        ]
    },
    {
        id: "icons",
        label: 'Icons',
        link: "/#",
        icon: <Trophy />,
        subItems: [
            {
                id: 'remix',
                label: 'Remix',
                link: '/icons-remix',
                parentId: "icons"
            },
            {
                id: 'lucide',
                label: 'Lucide',
                link: '/icons-lucide',
                parentId: "icons"
            }
        ]
    },
*/
];

export { menuData };