import Modal from 'Common/Components/Modal';
import React, { useEffect, useState } from 'react'
import { SketchPicker } from 'react-color';
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

import {
    addCategoryList as onAddcategoryList,
    updateCategoryList as onUpdatecategoryList,
    uploadCategoryImage
} from '../../../helpers/backend_helper';
import { Common } from 'helpers/common';
import Dropzone from 'react-dropzone';
import { Loader2, UploadCloud } from 'lucide-react';

type Props = { onHide: () => void; data: any, onSave: (result: any) => void }

export const AddCategory: React.FC<Props> = ({ onHide, data, onSave }) => {
    const [categoryData, setCategoryData] = useState<any>(data);
    const [isEdit, setIsEdit] = useState<boolean>(false);

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [selectedFiles, setSelectedFiles] = React.useState<any>([])

    const [displayColorPicker, setDisplayColorPicker] = useState(true);
    const [selectedColor, setSelectedColor] = useState('');


    // Image
    const [selectedImage, setSelectedImage] = useState<any>();
    const commonHelper = new Common();

    const handlePickerClick = () => {
        setDisplayColorPicker(prev => { return !prev })
    };

    const handlePickerClose = () => {
        setDisplayColorPicker(false);
    };

    const handlePickerChange = (color: any) => {
        setSelectedColor(color.hex);
        console.log({ color });//: color.rgb })

        validation.setFieldValue('backgroundColor', color.hex);
    };

    const handleAcceptedFiles = (files: any) => {
        files.map((file: any) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: commonHelper.formatBytes(file.size),
            })
        )
        setSelectedFiles(files)
        setSelectedImage(files[0]);
        // validation.setFieldValue('logo', files[0]);
        validation.setFieldValue('logo', 'htttp');
    }

    useEffect(() => {
        if (categoryData?.id) {
            setIsEdit(categoryData?.id);
            let obj = {
                preview: categoryData.logo,
                formattedSize: '',
                name: ''
            }
            setSelectedFiles((prev: any) => [{ ...obj }])
            setSelectedColor(categoryData?.backgroundColor);
        }
    }, [])


    // validation
    const validation: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: (categoryData && categoryData.name) || '',
            description: (categoryData && categoryData.description) || '',
            logo: (categoryData && categoryData.logo) || '',
            isActive: (categoryData && categoryData.isActive) || true,
            sortOrder: (categoryData && categoryData.sortOrder) || '',
            backgroundColor: (categoryData && categoryData.backgroundColor) || '',

        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please Enter Category Name !"),
            logo: Yup.string().required("Please Select Logo !"),
            description: Yup.string().required("Please Enter Description !"),
            sortOrder: Yup.number().required("Please Enter Sort Order !"),
            backgroundColor: Yup.string().required("Please Enter Background Color !"),
            isActive: Yup.string().required("Please choose yes or no!")
        }),

        onSubmit: async (values: any) => {
            setIsSaving(true);
            let logo = null;
            console.log(selectedImage)

            // upload if any new images is selected..
            if (selectedImage?.name) {
                var imgResult = await uploadCategoryImage({ fileCollection: selectedImage });
                console.log(imgResult);
                logo = imgResult?.data.rows[0].url;
            }

            if (isEdit && categoryData) {

                try {
                    // update user
                    const updateData = {
                        ...values,
                        id: categoryData.id,
                        logo: logo ?? values.logo
                    };

                    var result = await onUpdatecategoryList(updateData);
                    commonHelper.showSuccessMessage('Category has been successfully updated.');
                    onSave(result.data.rows[0]);

                } catch (error) {
                    console.log('API-Error', error);
                    commonHelper.showErrorMessage(error as string);
                    setIsSaving(false);
                }

                setIsEdit(false);
            } else {
                // save new user

                try {
                    const newData = { ...values, logo: logo };
                    var result = await onAddcategoryList(newData);
                    commonHelper.showSuccessMessage('Category has been successfully saved.');
                    onSave(result.data.rows[0]);
                } catch (error) {
                    console.log('API-Error', error);
                    commonHelper.showErrorMessage(error as string);
                    setIsSaving(false);
                }
            }
            setIsSaving(false);
        },

    });


    const color: React.CSSProperties = {
        width: '36px',
        height: '14px',
        borderRadius: '2px',
        background: `${selectedColor}`,
    };

    const swatch: React.CSSProperties = {
        padding: '5px',
        background: '#fff',
        borderRadius: '1px',
        boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
        display: 'inline-block',
        cursor: 'pointer',
    };
    const popover: React.CSSProperties = {
        position: 'absolute',
        zIndex: '2',
    };
    const cover: React.CSSProperties = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    };


    return (
        <React.Fragment>
            <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
                <Modal.Title className="text-16">{!!isEdit ? "Edit Category" : "Add Category"}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
                <form action="#!" onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                }}>
                    <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-6">
                        <div className="lg:col-span-2 xl:col-span-12">
                            <label htmlFor="name" className="inline-block mb-2 text-base font-medium">Category Name</label>
                            <input type="text" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                id="name"
                                name="name"
                                placeholder="Category Name"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.name || ""} />
                            {validation.touched.name && validation.errors.name ? (
                                <div id="name-error" className="mt-1 text-sm text-red-500">{validation.errors.name}</div>
                            ) : null}

                        </div>

                        <div className="lg:col-span-2 xl:col-span-12">
                            <div>
                                <label htmlFor="description" className="inline-block mb-2 text-base font-medium">Category Description</label>
                                <textarea className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    id="description"
                                    name="description"
                                    placeholder="Enter Description"
                                    rows={2}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.description || ""}
                                ></textarea>

                                {validation.touched.description && validation.errors.description ? (
                                    <div id="description-error" className="mt-1 text-sm text-red-500">{validation.errors.description}</div>
                                ) : null}
                            </div>
                        </div>

                        <div className="lg:col-span-2 xl:col-span-12">
                            <div>
                                <label htmlFor="sortOrder" className="inline-block mb-2 text-base font-medium">Sort Order</label>
                                <input type="text" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                    id="sortOrder"
                                    name="sortOrder"
                                    placeholder="Sort Order"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.sortOrder || ""} />

                                {validation.touched.sortOrder && validation.errors.sortOrder ? (
                                    <div id="sortOrder-error" className="mt-1 text-sm text-red-500">{validation.errors.sortOrder}</div>
                                ) : null}
                            </div>
                        </div>

                        <div className="lg:col-span-2 xl:col-span-9">
                            <label htmlFor="backgroundColor" className="inline-block mb-2 text-base font-medium">Background Color</label>
                            <input type="text" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                id="backgroundColor"
                                name="backgroundColor"
                                placeholder="Background Color"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.backgroundColor || ""}
                                disabled={true}
                                onClick={() => setDisplayColorPicker(true)}
                                style={{ backgroundColor: selectedColor }}
                            />

                            {validation.touched.backgroundColor && validation.errors.backgroundColor ? (
                                <div id="backgroundColor-error" className="mt-1 text-sm text-red-500">{validation.errors.backgroundColor}</div>
                            ) : null}


                            {displayColorPicker ? <div style={{ ...popover }}>
                                <div style={{ ...cover }} onClick={handlePickerClose} />
                                <SketchPicker color={selectedColor} onChange={handlePickerChange} />
                            </div> : null}


                        </div>
                        <div className="lg:col-span-2 xl:col-span-3">
                            <button
                                className="px-2 py-1.5 text-xs text-white bg-custom-500 border-custom-500 btn hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                                onClick={() => setDisplayColorPicker(true)}
                                style={{ marginTop: "32px" }}  >Pick Color</button>
                        </div>

                        <div className="lg:col-span-2 xl:col-span-12">
                            <label htmlFor="isActive" className="inline-block mb-2 text-base font-medium">IsActive</label> <br></br>

                            <div className="relative inline-block w-10 align-middle transition duration-200 ease-in ltr:mr-2 rtl:ml-2">
                                <input type="checkbox" name="isActive"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    value={validation.values.isActive}
                                    id="isActive" className="absolute block size-5 transition duration-300 ease-linear border-2 rounded-full appearance-none cursor-pointer border-slate-200 dark:border-zink-500 bg-white/80 dark:bg-zink-400 peer/published checked:bg-white dark:checked:bg-white ltr:checked:right-0 rtl:checked:left-0 checked:bg-none checked:border-custom-500 dark:checked:border-custom-500 arrow-none" defaultChecked />
                                <label htmlFor="customDefaultSwitch" className="block h-5 overflow-hidden duration-300 ease-linear border rounded-full cursor-pointer cursor-pointertransition border-slate-200 dark:border-zink-500 bg-slate-200 dark:bg-zink-600 peer-checked/published:bg-custom-500 peer-checked/published:border-custom-500"></label>

                                {validation.touched.isActive && validation.errors.isActive ? (
                                    <div id="isActive-error" className="mt-1 text-sm text-red-500">{validation.errors.isActive}</div>
                                ) : null}
                            </div>
                        </div>


                        <div className="lg:col-span-2 xl:col-span-12">
                            <label htmlFor="isActive" className="inline-block mb-2 text-base font-medium">Category Image</label> <br></br>

                            <div className="flex items-center justify-center bg-white border border-dashed rounded-md cursor-pointer  border-slate-300 dark:bg-zink-700 dark:border-zink-500">
                                <Dropzone
                                    onDrop={(acceptedFiles: any) => {
                                        handleAcceptedFiles(acceptedFiles)
                                    }}
                                >
                                    {({ getRootProps, getInputProps }: any) => (
                                        <div
                                            className="w-full py-5 text-lg text-center dz-message needsclick"
                                            {...getRootProps()}
                                        >
                                            <input {...getInputProps()} />
                                            <div className="mb-3">
                                                <UploadCloud className="block size-12 mx-auto text-slate-500 fill-slate-200 dark:text-zink-200 dark:fill-zink-500"></UploadCloud>
                                            </div>

                                            <h5 className="mb-0 font-normal text-slate-500 text-15">Drag and drop category image or <a href="#!">browse</a></h5>
                                        </div>
                                    )}
                                </Dropzone>
                                <ul className="flex flex-wrap mb-0 gap-x-5" id="dropzone-preview2">
                                    {
                                        (selectedFiles || [])?.map((f: any, i: any) => {
                                            return (
                                                <li className="mt-2" id="dropzone-preview-list" key={i + "-file"}>
                                                    <div className="border rounded border-slate-200 dark:border-zink-500">
                                                        <div className="p-2 text-center">
                                                            <div>
                                                                <div style={{ height: "100px", width: "100px"}}>
                                                                    <img data-dz-thumbnail className="block w-full h-full rounded-md" src={f.preview} alt={f.name} 
                                                                    style={{ backgroundColor:selectedColor }}
                                                                    
                                                                    />
                                                                </div>
                                                            </div>
                                                            {/* <div className="pt-3">
                                                            <h5 className="mb-1 text-15" data-dz-name>{f.name}</h5>
                                                            <p className="mb-0 text-slate-500 dark:text-zink-200" data-dz-size>{f.formattedSize}</p>
                                                        </div> */}
                                                            <div className="mt-2">
                                                                <button data-dz-remove
                                                                    className="px-2 py-1.5 text-xs text-white bg-red-500 border-red-500 btn hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:border-red-600 active:ring active:ring-red-100 dark:ring-custom-400/20"
                                                                    onClick={() => {
                                                                        const newImages = [...selectedFiles];
                                                                        newImages.splice(i, 1);
                                                                        setSelectedFiles(newImages);
                                                                    }}
                                                                >Delete</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>

                            </div>


                            <input type="hidden" id="logo" name="logo" />
                            {validation.touched.logo && validation.errors.logo ? (
                                <div id="logo-error" className="mt-1 text-sm text-red-500">{validation.errors.logo}</div>
                            ) : null}
                        </div>

                    </div>
                    <div className="flex justify-end gap-2 mt-4">
                        <button type="reset" data-modal-close="addDocuments" className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500" onClick={onHide}>Cancel</button>

                        {!isSaving ?
                            <button type="submit" className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                {!!isEdit ? "Update Category" : "Add Category"}
                            </button>
                            : <button type="button" disabled className="flex items-center text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                <Loader2 className="size-4 ltr:mr-2 rtl:ml-2 animate-spin" />
                                Saving...
                            </button>
                        }
                    </div>
                </form>
            </Modal.Body>
        </React.Fragment>
    )
};


