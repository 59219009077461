import { Loader2 } from "lucide-react"

export const Loader = () => {

    return (
        <div className="!pt-1 card-body">
            <div className="flex items-center justify-center">
                <Loader2 className="size-8 animate-spin" /> <h5 className="mt-2 mb-1">Loading... please wait</h5>
            </div>
        </div>
    )
};