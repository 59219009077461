import { postLogin } from "helpers/backend_helper";
import { loginError, loginSuccess, logoutSuccess } from "./reducer";
import { ThunkAction } from "redux-thunk";
import { Action, Dispatch } from "redux";
import { RootState } from "slices";

interface User {
    email: string;
    password: string;
}

export const loginUser = (
    user: User,
    history: any
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch: Dispatch) => {
    try {
        let response: any;
            response = await postLogin({
                email: user.email,
                password: user.password,
            });
            console.log(response);
            response=response.data;
            localStorage.setItem("token", response.data.authToken);
            localStorage.setItem("refreshToken", response.data.refreshToken);

            if (response) {
            dispatch(loginSuccess(response));
            history("/dashboard");
        }
    } catch (error) {
        console.log(error);

        dispatch(loginError(error));
    }
};

export const logoutUser = () => async (dispatch: Dispatch) => {
    try {
        localStorage.removeItem("token");
            dispatch(logoutSuccess(true));
    } catch (error) {
        dispatch(loginError(error));
    }
}
