import { toast } from "react-toastify";

export class Common{
    showSuccessMessage = (message: string) => toast.success(message, { autoClose: 3000, theme: "colored", icon: false });
    showErrorMessage = (message: string) => toast.error(message, { autoClose: 3000, theme: "colored", icon: false });

    
    /**
     * Formats the size
     */
    formatBytes = (bytes: any, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
        
    }
}