import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Flatpickr from 'react-flatpickr';
import BreadCrumb from "Common/BreadCrumb";
import Select, { SelectInstance } from 'react-select';
// Icon
import { Loader2, Trash2, UploadCloud } from 'lucide-react';

// Formik
import * as Yup from "yup";
import { useFormik } from "formik";

// Image
import Dropzone from "react-dropzone";
import {
    addProduct,
    addProductImages, 
    getBrands, 
    getCategoryList,
    getPackagings, 
    getProductAttributeValues, getProductAttributes, getProductSizes, getSeasons, uploadProductImages,
    getProductById,
    updateProduct,
    addProductVariant,
    updateProductVariant,
    deleteProductVariant
} from "helpers/backend_helper";
import { Common } from "helpers/common";
import { ToastContainer } from "react-toastify";
import { ProductColor } from "Common/Components/ProductColor";
import { ProductType } from "types/ProductType";
import { ProductVariant } from "pages/Components/Landing/Product/ProductVariant";
import AddProductVariant from "./AddProductVariant";
import Modal from "Common/Components/Modal";
import { ProductVariantType } from "types/ProductVariantType";

export type AttributesOption = {
    readonly value: string
    readonly label: string
}
const AddProduct = () => {
    const [productData, setProductData] = useState<any>();
    const [productId, setProductId] = useState<any>();
    const [selectfiles, setSelectfiles] = useState([]);
    const [brands, setBrands] = useState<any[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<any>();
    const [selectedBrand, setSelectedBrand] = useState<any>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [productVariant, setProductVariant] = useState<any>();
    const [selectedSeasons, setSelectedSeasons] = useState<any>({});
    const [selectedPackagings, setSelectedPackagings] = useState<any>();
    const [categories, setCategories] = useState<any[]>([]);
    const [productSizes, setProductSizes] = useState<any[]>([]);
    const [seasons, setSeasons] = useState<any[]>([]);
    const [packagings, setPackagings] = useState<any[]>([]);
    const [productAttributes, setProductAttributes] = useState<any[]>([]);
    const [productAttributeValues, setProductAttributeValues] = useState<any[]>([]);
    const [displayColorPickers, setDisplayColorPickers] = useState(false);
    const productAttributeValueRef = useRef<SelectInstance<AttributesOption> | null>(null);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [selectedGender, setSelectedGender] = useState<any>();
    const [selectedProductAttribute, setSelectedProductAttribute] = useState<AttributesOption>();
    const [selectedProductAttributeValue, setSelectedProductAttributeValue] = useState<AttributesOption>();
    const [attributes, setAttributes] = useState<any[]>([]);
    const [productColors, setProductColors] = useState<any[]>([]);
    const [productImages, setProductImages] = useState<any[]>([]);
    const [productTags, setProductTags] = useState<any[]>([]);
    const [selectedTags, setSelectedTags] = useState<any[]>([]);
    const [productVariants, setProductVariants] = useState<any[]>([]);
    const [brandDiscount, setBrandDiscount] = useState<any>(0);

    const navigate = useNavigate();
    const commonHelper = new Common();

    const { prodId } = useParams();
    console.log('ProductId', prodId)

    useEffect(() => {
        setProductId(prodId);
        loadProductDetail().then(() => {
            console.log('productData in useEffect', productData)
            loadCategories();
            loadBrands();
            loadProductSizes();
            loadSeasons();
            loadProductAttributes();
            loadProductColors();
            loadPackagings();
            loadProductImages();
            calculateSetPrice(null, 'all');
        });
    }, [productData])

    const loadProductColors = () => {
        let defaultColors = [
            { color: '#0ea5e9', selected: false },
            { color: '#f97316', selected: false },
            { color: '#249782', selected: false },
            { color: '#a855f7', selected: false },
            { color: '#eab308', selected: false },
            { color: '#ef4444', selected: false },
            { color: '#64748b', selected: false },
            { color: '#000000', selected: false },
            { color: '#FFB6C1', selected: false },
            { color: '#FFD580', selected: false },
            { color: '#fbe0c5', selected: false },
            { color: '#000080', selected: false },
            { color: '#FAF9F6', selected: false },
        ]
        // combine defaultColors and productData?.productColor to get unique colors

        // get color array from productData.productvariants[0]
       // const colorArray = productData?.productvariants[0]?.color;

        
        if(productData){
            productData.productColor = productData?.productsProductvariants[0]?.color;
        }

        defaultColors.forEach((color: any) => {
            if (productData?.productColor?.includes(color.color)) {
                color.selected = true;
            }
        })

        // const uniqueColors = [...defaultColors, ...(productData?.productColor?.map((color: string) => ({ color, selected: true })) || [])].filter((obj, index, self) =>
        //     index === self.findIndex((t) => t.color === obj.color));

        //const array3 = defaultColors.filter((obj) => productData?.productColor.indexOf(obj.color) === -1);
        console.log('uniqueColors', defaultColors, productData?.productColor)
        setProductColors(defaultColors);
    }

    const loadBrands = async () => {
        if (brands.length === 0) {
            const response = await getBrands();
            if (response.success) {
                const brandItems = [{ value: '', label: 'Select', discount: 0 }, ...response.data.map((item: any) => ({ value: item.id, label: item.name, discount: item.discount }))];
                setBrands(brandItems);

                console.log('brands', brandItems)
                if (productData?.brandId) {
                    setSelectedBrand(brandItems.find(item => item.value === productData.brandId));
                }
            }
        }
    };


    const loadProductDetail = async () => {

        console.log('prodId', prodId, ' productData', productData)
        if (prodId && productData === undefined) {
            try {
                setIsEdit(true);
                const prods = await getProductById(prodId);
                const prodDetail = await prods.data[0] as any;
                setProductData(prodDetail);
                console.log('in prod load 0', prodDetail);

                setAttributes(prodDetail.attributes);

                setProductTags(prodDetail.productTags);

                let tags = prodDetail.productTags.map((item: any) => ({ value: item, label: item }));
                validation.setFieldValue('productTags', tags);

                setSelectedTags(prodDetail.productTags.map((item: any) => ({ value: item, label: item })));

                setSelectedGender({ value: prodDetail.gender, label: prodDetail.gender });

                setProductVariants(prodDetail.productsProductvariants);
                //console.log('productData?.productvariants', productData?.productvariants);

            } catch (err) {
                console.error('Error loading product details', err);
            }
        }
    };


    const loadProductImages = async () => {
        if (productImages.length === 0) {
            try {

                if (prodId) {
                    // let response = await getProductImages(prodId);
                    // console.log('product images', response)
                    if (productData?.productsProductimages) {
                        setProductImages(productData?.productsProductimages);
                        const prodImages = productData?.productsProductimages.map((item: any) => {

                            return {
                                preview: item.url,
                                formattedSize: '',
                                name: ''
                            }
                        });

                        console.log('prodImages', prodImages);
                        setSelectfiles(prodImages as never[]);

                    }
                }
            } catch (err) {

            }
        }
    }

    const deleteProductImages = async (imageId: string) => {
        if (productImages.length === 0) {
            try {
                let response = await deleteProductImages(imageId);
                console.log('product images', response)
            } catch (err) {

            }
        }
    }

    const calculateSetPrice = (item: any, field: string) => {

        if (field == 'unitPrice') {
            validation.setFieldValue('unitPrice', parseFloat(item.target.value));
        } else if (field == 'packOf') {
            validation.setFieldValue('packOf', parseInt(item.target.value));
        } else if (field === 'discount') {
            validation.setFieldValue('discount', parseInt(item.target.value));
        }

        const unitPrice = validation.values.unitPrice || 0;
        const packOf = validation.values.packOf || 0;
        const discount = validation.values.discount || 0;
        const setPrice = Math.round(unitPrice * packOf * 100) / 100;
        const netPrice = setPrice - discount;
        validation.setFieldValue('setPrice', setPrice);
        validation.setFieldValue('netPrice', netPrice);

    }


    const loadCategories = async () => {
        if (categories.length === 0) {
            try {
                const response = await getCategoryList();
                const categoryItems = response.data.map((item: any) => ({ value: item.id, label: item.name, }));

                setCategories([{ value: '', label: 'Select' }, ...categoryItems,]);

                console.log('cat product', productData)
                if (productData?.categoryId) {
                    setSelectedCategory(categoryItems.find(item => item.value === productData.categoryId));
                }
            } catch {
                // ignore error
            }
        }
    }

    const loadProductSizes = async () => {
        if (productSizes.length === 0) {
            try {
                const response = await getProductSizes();
                const prodSizes = response.data.map(({ id, name }) => ({ value: id, label: name }));
                setProductSizes(prodSizes);

                // const productSizesMap = new Map(prodSizes.map((size: any) => [size.value, size]));
                // const productVariantsWithSizes = productData?.productvariants?.map((item: any) => ({
                //     ...item,
                //     productSize: productSizesMap.get(item.productSizeId),
                // })) || [];


            } catch { }
        }
    };


    const loadSeasons = async () => {
        if (seasons.length === 0) {
            try {
                const response = await getSeasons();
                const items = [{ value: '', label: 'Select' }, ...response.data.map(({ id, name }) => ({ value: id, label: name }))];
                setSeasons(items);
                if (productData?.seasonId) {
                    setSelectedSeasons(items.find(item => item.value === productData.seasonId));
                }

            } catch { }
        }
    };


    const loadPackagings = async () => {
        if (packagings.length === 0) {
            try {
                const response = await getPackagings();
                const items = response.data.map((item: any) => ({
                    value: item.id,
                    label: item.name,
                }));
                setPackagings([{ value: '', label: 'Select' }, ...items]);

                if (productData?.packagingId) {
                    let item = items.find((item: any) => item.value === productData?.packagingId);
                    setSelectedPackagings(item);
                    console.log('selected packaging', item)

                }

            } catch { }
        }
    };

    const loadProductAttributes = async () => {
        if (productAttributes.length === 0) {
            try {
                const response = await getProductAttributes();
                const data = response.data.map((item: any) => ({ value: item.id, label: item.name }));
                const options = [{ value: '', label: 'Select' }, ...data];

                setProductAttributes(options);
            } catch { }
        }
    };

    const loadProductAttributeValues = async (attributeId: string) => {
        try {
            const { data: attributeValues } = await getProductAttributeValues(attributeId);
            setProductAttributeValues([
                { value: '', label: 'Select' },
                ...attributeValues.map(({ id, attributeValue }) => ({ value: id, label: attributeValue })),
            ]);
        } catch { }


    }

    const handleProductAttributeChange = (item: any) => {
        productAttributeValueRef?.current?.clearValue();
        setSelectedProductAttribute(item)

        console.log('selected product attribute', item)
        loadProductAttributeValues(item.value)
    }

    const handleProductAttributeValueChange = (item: any) => {
        console.log('selected product attribute', item)
        if (item) {
            setSelectedProductAttributeValue(item)
        }
    }

    const addProductAttributes = () => {
        if(!attributes ){
        setAttributes([]);    
        }
        
        if (attributes.filter(i => i.attribute.value === selectedProductAttribute?.value).length > 0) {
            commonHelper.showErrorMessage('Product attribute [' + selectedProductAttribute?.label + '] is already added.')
            return;
        }

        const attrs = [...attributes, { attribute: selectedProductAttribute, attributeValue: selectedProductAttributeValue }]
        setAttributes(attrs);
        console.log('attrs', attributes)
        validation.setFieldValue('attributes', attributes)

    }

    const onAttributeDelete = (attributeId: string) => {
        setAttributes(list => list.filter((attr: any, idx) => attr.attributeValue.value !== attributeId));
        console.log('attrs', attributes)
        validation.setFieldValue('attributes', attributes)

    }

    //displayColorPickers
    const handleColorPicker = () => {
        setDisplayColorPickers(true);
    }

    const addProductColor = (color: any) => {
        if (productColors.filter(i => i.color === color.hex).length > 0) {
            commonHelper.showErrorMessage('This color is already added');
            return;
        }

        const prodColors = [...productColors, { color: color.hex, selected: true }];
        setProductColors(prodColors);
        setDisplayColorPickers(false);

        console.log(prodColors);
        let colors =
            prodColors?.filter(item => item.selected == true)
                .map((item: any) => {
                    return item.color;
                });

        validation.setFieldValue('productColor', colors);
        setTimeout(() => {
            console.log(colors, validation.values.productColor);
        }, 2000);



    }

    const handleColorSelection = (color: string) => {
        const prodColors = productColors.map((item: any) => {
            if (item.color === color) {
                return { ...item, selected: !item.selected };
            } else {
                return item;
            }
        });
        setProductColors(prodColors);

        console.log(prodColors);
        let colors =
            prodColors?.filter(item => item.selected == true)
                .map((item: any) => {
                    return item.color;
                });

        validation.setFieldValue('productColor', colors);
        setTimeout(() => {
            console.log('productColor', colors, validation.values.productColor);
        }, 2000);

    }

    const handleAcceptfiles = (files: any) => {
        files?.map((file: any) => {
            return Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: commonHelper.formatBytes(file.size),
            });
        });

        console.log(files);

        const selectedFiles = [...selectfiles, ...files]
        //selectfiles.push(files as never);
        setSelectfiles(selectedFiles as never[]);

        console.log(selectedFiles);
        validation.setFieldValue('image', files.length);
    };


    const genderSelect = [
        { value: '', label: 'Select Gender' },
        { value: 'Boys', label: 'Boys' },
        { value: 'Girls', label: 'Girls' },
        { value: 'Unisex', label: 'Unisex' },
    ];


    const handleAvailableFromDateChange = (selectedDates: any, dateStr: any, instance: any) => {
        console.log(dateStr)
        validation.setFieldValue('availableFromDate', dateStr)
    }

    const handleAvailableToDateChange = (selectedDates: any, dateStr: any, instance: any) => {
        validation.setFieldValue('availableToDate', dateStr)
    }

    const handleBrandSelection = (item: any) => {
        if (item) {
            validation.setFieldValue('brandId', item.value);
            console.log(item)
            const brandDiscount = Math.round(item.discount) ;
            setBrandDiscount(brandDiscount);
        }
    }

    const toggle = useCallback(() => {
        setShowModal(!showModal);
        if (showModal) {
            setProductVariant(null);
        }
    }, [showModal]);


    const handleAddProductVariant = () => {
        const newProductVariant = {
            productsizesProductvariants: { name: '', id: '' },
            colors: [],
            unitPrice: 0,
            setPrice: 0,
            netPrice: 0,
            discountPercent: 0,
            discountValue: 0,
            mrp: 0,
            packOf: 0,
            inventory: 0,
            sizeId: '',
            id: '',
            isEdit: false,
            color: 'green',
            margin: 0
        } as ProductVariantType

        setProductVariant(newProductVariant);
        toggle();
    }


    const onProductVariantEdit = (prodVariant: any) => {
        setProductVariant(prodVariant);
        toggle();
    }

    const handleSeasonSelection = (item: any) => {
        console.log(item);
        validation.setFieldValue('seasonId', item.value);
        setSelectedSeasons(item);
    }

    // TODO: delete this code
    const onProductVariantSave = (item: any) => {
        // check duplicate size before adding new
        console.log('on product variant save', item);
        if (productVariant.sizeId == '') {
            if (productVariants.some(x => x.sizeId == item.sizeId)) {
                commonHelper.showErrorMessage('The product size already exists. Please select a different size.');
                return;
            }
            setProductVariants([item, ...productVariants]);
        }
        else {
            const updatedProductVariants = productVariants.map((productVariant: any) => {
                if (productVariant.sizeId == item.sizeId) {
                    return item;
                }
                else {
                    return productVariant;
                }
            });
            setProductVariants(updatedProductVariants);
        }
        toggle();
        console.log('on product variant save', item);
    }

    const handleProductTagsSelection = (items: any) => {
        try {
            console.log(items);

            const selectedTags = items.map((value: string): any => ({ label: value, value }));
            const selectedTagValues = items.map((item: any): any => { return item.value });
            setProductTags(selectedTagValues);
            setSelectedTags(selectedTags);
            validation.setFieldValue('productTags', selectedTags);
        } catch (err) {
            console.log(err);
        }
    }



    // validation
    const validation: any = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: (productData && productData.name) || '',
            description: (productData && productData.description) || '',
            sku: (productData && productData.sku) || '',
            brandId: (productData && productData.brandId) || '',
            categoryId: (productData && productData.categoryId) || '',
            seasonId: (productData && productData.seasonId) || '',
            gender: (productData && productData.gender) || '',
            availableFromDate: (productData && productData.availableFromDate) || '',
            availableToDate: (productData && productData.availableToDate) || '01/01/2050',
            publish: (productData && productData.publish) || true,
            packagingId: (productData && productData.packagingId) || '1',
            productTags: (productData && productData.productTags) || [],
            attributes: (productData && productData.attributes) || [],
            isActive: (productData && productData.isActive) || true,
            productVariants: (productData && productData.productsProductvariants) || [],
            //ageGroup: (productData && productData.ageGroup) || [],

            //productSize: (productData && productData.productSize) || [],
            productColor: (productData && productData.productColor) || [],
            // packOf: (productData && productData.packOf) || '',
            // unitPrice: (productData && productData.unitPrice) || '',
            // mrp: (productData && productData.mrp) || '',

            // inventory: (productData && productData.inventory) || '',
            // discount: (productData && productData.discount) || '',
            // // image: (productData && productData.image) || '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please enter product name !"),
            description: Yup.string().required("Please enter description !"),
            sku: Yup.string().required("Please enter SKU (Product Code) !"),
            brandId: Yup.string().required("Please select Brand !"),
            gender: Yup.string().required("Please select gender !"),
            categoryId: Yup.string().required("Please select category !"),
            seasonId: Yup.string().required("Please select season!"),
            availableFromDate: Yup.string().required("Please select available From Date!"),
            availableToDate: Yup.string().required("Please select available To Date!"),
            // packagingId: Yup.string().required("Please select packaging!"),
            attributes: Yup.array().required("Please add product attributes!"),
            productVariants: Yup.array().required("Please add product variant(s)!"),

            productColor: Yup.array().required("Please select product colors!"),

            isActive: Yup.boolean().required("Please choose yes or no!")
        }),

        onSubmit: async (values: any) => {

            if (!isValidData()) {
                return;
            }
            setIsSaving(true);
            console.log('onsubmit', values)



            let dataToSave = {
                ...values,
                //productTags: values.productTags.map((item: any) => item.value),
                productTags: [
                    "VastriFi",
                    "Soft Cloths"
                ],
                attributes: attributes
            };

            try {
                if (isEdit && productData) {
                    dataToSave = { ...dataToSave, id: productData.id }
                }

                var result = await (isEdit ? updateProduct(dataToSave) : addProduct(dataToSave));
                console.log(result.data)
                await uploadImages(productData?.id ?? result.data.rows[0].id);
                saveProductVariants(productData?.id ?? result.data.rows[0].id);
                commonHelper.showSuccessMessage(`Product has been successfully ${isEdit ? "updated" : "added"}.`);

                setTimeout(() => {
                    navigate('/product-list');
                }, 2000);

            } catch (error) {
                console.log('API-Error', error);
                commonHelper.showErrorMessage(error as string);
                setIsSaving(false);
            }

            //setIsEdit(false);
            //setIsSaving(false);
        },

    });

    const isValidData = () => {

        if (productColors.filter((item: any) => item.selected == true).length == 0) {
            commonHelper.showErrorMessage("Please select product color(s)!");
            return false;
        }
        else if (productVariants.length == 0) {
            commonHelper.showErrorMessage("Please add product variant(s)!");
            return false;
        }
        else if (attributes.length == 0) {
            commonHelper.showErrorMessage("Please add product attribute(s)!");
            return false;
        } else if (selectfiles.length == 0) {
            commonHelper.showErrorMessage("Please add product image(s)!");
            return false;
        }
        else {
            return true;
        }
    }

    const uploadImages = async (productId: string) => {

        console.log('images to be posted', selectfiles);
        selectfiles.map(async (file: any, index: number) => {
            if (file?.formattedSize?.length > 2) {

                var imgResult = await uploadProductImages({ fileCollection: file });
                console.log(imgResult);

                if (imgResult?.data?.rows?.length > 0) {
                    let obj = {
                        name: imgResult.data.rows[0].id,
                        productId: productId,
                        url: imgResult.data.rows[0].url,
                        isActive: true
                    }
                    var imgResult2 = await addProductImages(obj);
                    console.log(imgResult2);
                }
            }
        });
    }


    const saveProductVariants = async (productId: string) => {

        try {
            productVariants.forEach(async (productVariant: any) => {
                productVariant.productId = productId;
                productVariant.color = productColors.filter((item: any) => item.selected == true).map((item: any) => item.color);

                // delete id from object if it is empty(new record)
                if (productVariant.id === '') {
                    delete productVariant.id;
                }
                try {
                    var result = await (productVariant.id ? updateProductVariant(productVariant) : addProductVariant(productVariant))
                    console.log(result);
                } catch (error) {
                    console.log('API-Error1', error);

                }
            })

        } catch (error) {
            console.log('API-Error', error);
            commonHelper.showErrorMessage(error as string);
            setIsSaving(false);
        }
    }



    const handleDeleteProductVariant = async (productVariantId: string) => {

        try {
            const productVariant = productVariants.find((item: any) => item.id === productVariantId);
            var result = deleteProductVariant(productVariant);
            console.log(result);
        } catch (error) {
            console.log('API-Error', error);
            commonHelper.showErrorMessage(error as string);
            setIsSaving(false);
        }
    }

    const onProductVariantDelete = (status: boolean) => {
        if (status) {
            commonHelper.showSuccessMessage('Product variant has been successfully deleted.');

        } else {
            commonHelper.showErrorMessage('Something went wrong. Please try again later.');
        }
    }

    return (
        <React.Fragment>
            <BreadCrumb title='Add New' pageTitle='Products' />
            <ToastContainer closeButton={false} limit={1} />

            <form action="#!" onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
            }}>
                <div className="grid grid-cols-1 xl:grid-cols-12 gap-x-5">
                    <div className="xl:col-span-8">
                        <div className="card">
                            <div className="card-body">
                                <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-12">
                                    <div className="xl:col-span-8">
                                        <label htmlFor="name" className="inline-block mb-2 text-base font-medium">Product Title</label>
                                        <input type="text" id="name"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.name || ""}
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="Product title" />
                                        {validation.touched.name && validation.errors.name ? (
                                            <div id="name-error" className="mt-1 text-sm text-red-500">{validation.errors.name}</div>
                                        ) : null}
                                    </div>
                                    <div className="xl:col-span-4">
                                        <label htmlFor="sky" className="inline-block mb-2 text-base font-medium">SKU (Product Code)</label>
                                        <input type="text" id="sku"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200" placeholder="TWT-LP-ALU-08"
                                            onChange={validation.handleChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.sku || ""}
                                        />
                                        {validation.touched.sku && validation.errors.sku ? (
                                            <div id="sku-error" className="mt-1 text-sm text-red-500">{validation.errors.sku}</div>
                                        ) : null}
                                    </div>
                                    <div className="xl:col-span-8">
                                        <div>
                                            <label htmlFor="description" className="inline-block mb-2 text-base font-medium">Description</label>
                                            <input type="text" className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                id="description" placeholder="Enter Description"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.description || ""}
                                            ></input>
                                            {validation.touched.description && validation.errors.description ? (
                                                <div id="description-error" className="mt-1 text-sm text-red-500">{validation.errors.description}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="xl:col-span-4">
                                        <label htmlFor="brandId" className="inline-block mb-2 text-base font-medium">Brand</label>
                                        <Select
                                            className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            options={brands}
                                            value={selectedBrand}
                                            defaultValue={selectedBrand}
                                            isSearchable={true} // To disable search
                                            name="brandId"
                                            id="brandId"
                                            onChange={e => { handleBrandSelection(e); }}
                                        />
                                        {validation.touched.brandId && validation.errors.brandId ? (
                                            <div id="brandId-error" className="mt-1 text-sm text-red-500">{validation.errors.brandId}</div>
                                        ) : null}
                                    </div>

                                    <div className="xl:col-span-4">
                                        <label htmlFor="categoryId" className="inline-block mb-2 text-base font-medium">Category</label>
                                        <Select
                                            className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            options={categories}
                                            value={selectedCategory}
                                            defaultValue={selectedCategory}
                                            isSearchable={true} // To disable search
                                            name="categoryId"
                                            id="categoryId"
                                            onChange={e => { validation.setFieldValue('categoryId', e?.value); console.log(e?.value) }}

                                        />
                                        {validation.touched.categoryId && validation.errors.categoryId ? (
                                            <div id="categoryId-error" className="mt-1 text-sm text-red-500">{validation.errors.categoryId}</div>
                                        ) : null}
                                    </div>

                                    <div className="xl:col-span-4">
                                        <label htmlFor="gender" className="inline-block mb-2 text-base font-medium">Gender</label>
                                        <Select
                                            className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            options={genderSelect}
                                            defaultValue={selectedGender}
                                            value={selectedGender}

                                            isSearchable={false} // To disable search
                                            name="gender"
                                            id="gender"
                                            onChange={e => { validation.setFieldValue('gender', e?.value); console.log(e?.value) }}
                                        />
                                        {validation.touched.gender && validation.errors.gender ? (
                                            <div id="gender-error" className="mt-1 text-sm text-red-500">{validation.errors.gender}</div>
                                        ) : null}
                                    </div>
                                    {/* 
                                    <div className="xl:col-span-8">
                                        <label htmlFor="ageGroup" className="inline-block mb-2 text-base font-medium">Age Group</label>
                                        <Select
                                            className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            options={ageGroup}
                                            defaultValue={selectedAgeGroup}
                                            value={selectedAgeGroup}
                                            isSearchable={false} // To disable search
                                            name="ageGroup"
                                            id="ageGroup"
                                            isMulti
                                            onChange={e => { handleAgeGroupSelection(e) }}

                                        />
                                        {validation.touched.ageGroup && validation.errors.ageGroup ? (
                                            <div id="ageGroup-error" className="mt-1 text-sm text-red-500">{validation.errors.ageGroup}</div>
                                        ) : null}
                                    </div> */}
                                    <div className="xl:col-span-4">
                                        <label htmlFor="seasonId" className="inline-block mb-2 text-base font-medium">Seasons</label>
                                        <Select
                                            className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            options={seasons}
                                            defaultValue={selectedSeasons}
                                            value={selectedSeasons}
                                            isSearchable={true} // To disable search
                                            name="seasonId"
                                            id="seasonId"
                                            onChange={e => { handleSeasonSelection(e) }}
                                        />
                                        {validation.touched.seasonId && validation.errors.seasonId ? (
                                            <div id="seasonId-error" className="mt-1 text-sm text-red-500">{validation.errors.seasonId}</div>
                                        ) : null}
                                    </div>

                                    <div className="lg:col-span-2 xl:col-span-4">
                                        <label htmlFor="availableFromDate" className="inline-block mb-2 text-base font-medium">Available From</label>
                                        <Flatpickr
                                            id="availableFromDate"
                                            options={{
                                                altInput: true,
                                                altFormat: "d/m/Y",
                                                dateFormat: "m/d/Y",
                                            }}
                                            onChange={handleAvailableFromDateChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.availableFromDate || ""}

                                            placeholder="available from date"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                        />
                                        {validation.touched.availableFromDate && validation.errors.availableFromDate ? (
                                            <div id="availableFromDate-error" className="mt-1 text-sm text-red-500">{validation.errors.availableFromDate}</div>
                                        ) : null}
                                    </div>

                                    <div className="lg:col-span-2 xl:col-span-4">
                                        <label htmlFor="availableToDate" className="inline-block mb-2 text-base font-medium">Available Till</label>
                                        <Flatpickr
                                            id="availableToDate"
                                            options={{
                                                altInput: true,
                                                altFormat: "d/m/Y",
                                                dateFormat: "m/d/Y",
                                            }}
                                            onChange={handleAvailableToDateChange}
                                            onBlur={validation.handleBlur}
                                            value={validation.values.availableToDate || ""}
                                            placeholder="available till date"
                                            className="form-input border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                        />
                                        {validation.touched.availableToDate && validation.errors.availableToDate ? (
                                            <div id="availableToDate-error" className="mt-1 text-sm text-red-500">{validation.errors.availableToDate}</div>
                                        ) : null}
                                    </div>

                                    <div className="xl:col-span-4">
                                        <label htmlFor="packagingId" className="inline-block mb-2 text-base font-medium">Packaging</label>
                                        <Select
                                            className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            options={packagings}
                                            defaultValue={selectedPackagings}
                                            value={selectedPackagings}
                                            isSearchable={true} // To disable search
                                            name="packagingId"
                                            id="packagingId"
                                            onChange={e => { validation.setFieldValue('packagingId', e?.value); console.log(e?.value) }}
                                        />
                                        {validation.touched.packagingId && validation.errors.packagingId ? (
                                            <div id="packagingId-error" className="mt-1 text-sm text-red-500">{validation.errors.packagingId}</div>
                                        ) : null}
                                    </div>
                                    {/* <div className="lg:col-span-2 xl:col-span-12">
                                        <label htmlFor="productTags" className="inline-block mb-2 text-base font-medium">Product Tags</label>
                                        <CreatableSelect
                                            className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                            id="productTags"
                                            defaultValue={selectedTags}
                                            value={selectedTags}
                                            data-choices data-choices-text-unique-true
                                            isMulti
                                            onChange={(e: any) => { handleProductTagsSelection(e) }}
                                        />

                                        {validation.touched.productTags && validation.errors.productTags ? (
                                            <div id="productTags-error" className="mt-1 text-sm text-red-500">{validation.errors.productTags}</div>
                                        ) : null}
                                    </div> */}
                                    {<div className="xl:col-span-8">
                                        <label htmlFor="productColor" className="inline-block mb-2 text-base font-medium">Colors Variant</label>
                                        <div className="flex flex-wrap items-center gap-2">

                                            <ProductColor
                                                id="productColor"
                                                key="productColorMain"
                                                onStatusChange={handleColorSelection}
                                                productColors={productColors}
                                                onColorSaved={() => console.log('color saved')}
                                                showColorPallet={true}
                                            ></ProductColor>
                                            {validation.touched.productColor && validation.errors.productColor ? (
                                                <div id="productColor-error" className="mt-1 text-sm text-red-500">{validation.errors.productColor}</div>
                                            ) : null}

                                        </div>
                                    </div>}

                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="xl:col-span-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-12">
                                    <div className="xl:col-span-4">
                                        <label htmlFor="genderSelect" className="inline-block mb-2 text-base font-medium">Browse Images</label>
                                        <Dropzone
                                            onDrop={(acceptfiles: any) => {
                                                handleAcceptfiles(acceptfiles);
                                            }}>
                                            {({ getRootProps }: any) => (
                                                <div className="flex items-center justify-center bg-white border border-dashed rounded-md cursor-pointer border-slate-300 dark:bg-zink-700 dark:border-zink-500 dropzone2"
                                                    style={{ height: 135 }}
                                                >
                                                    <div className="py-5 text-lg text-center dz-message needsclick" {...getRootProps()} >
                                                        <UploadCloud className="block size-12 mx-auto text-slate-500 fill-slate-200 dark:text-zink-200 dark:fill-zink-500" />
                                                        <h5 className="mb-0 font-normal text-slate-500 dark:text-zink-200 text-15">Browse / Drop images</h5>
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                    </div>
                                    <div className="xl:col-span-8">
                                        <label htmlFor="genderSelect" className="inline-block mb-2 text-base font-medium">Selected Images</label>
                                        <div className="flex flex-item">
                                            {

                                                (selectfiles || [])?.map((file: any, index: number) => {
                                                    return (

                                                        <div style={{ marginRight: 10 }} className="border rounded border-slate-200 dark:border-zink-500" key={"dropzone-preview-list2" + index}>
                                                            <div className="p-2 text-center">
                                                                <div>
                                                                    <div className="p-2 mx-auto rounded-md size-20 bg-slate-100 dark:bg-zink-600">
                                                                        <img className="block w-full h-full rounded-md" src={file.preview} alt={file.name} />
                                                                    </div>
                                                                </div>
                                                                {/* <div className="pt-3">
                                                                        <h5 className="mb-1 text-15" data-dz-name>{file.path}</h5>
                                                                        <p className="mb-0 text-slate-500 dark:text-zink-200" data-dz-size>{file.formattedSize}</p>
                                                                        <strong className="error text-danger" data-dz-errormessage></strong>
                                                                    </div> */}
                                                                <div className="mt-2">
                                                                    <button data-dz-remove className="px-2 py-1.5 text-xs text-white bg-red-500 border-red-500 btn hover:text-white hover:bg-red-600 hover:border-red-600 focus:text-white focus:bg-red-600 focus:border-red-600 focus:ring focus:ring-red-100 active:text-white active:bg-red-600 active:border-red-600 active:ring active:ring-red-100 dark:ring-custom-400/20" onClick={() => {
                                                                        const newImages = [...selectfiles];
                                                                        newImages.splice(index, 1);
                                                                        setSelectfiles(newImages);
                                                                    }}>Delete</button>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    );
                                                })

                                            } </div>

                                        {validation.touched.image && validation.errors.image ? (
                                            <div id="image-error" className="mt-1 text-sm text-red-500">{validation.errors.image}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-body">
                                <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-12">
                                    <div className="xl:col-span-12 border-b border-dashed">
                                        <table className="w-full bg-custom-50 dark:bg-custom-500/10">
                                            <thead className="ltr:text-left rtl:text-right bg-custom-100 dark:bg-custom-500/10">
                                                <tr>
                                                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900" style={{ width: 350 }}>Attribute</th>
                                                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900" style={{ width: 350 }}>Value</th>
                                                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">Action</th>
                                                </tr>
                                                <tr>
                                                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900" >
                                                        <Select
                                                            className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                            options={productAttributes}
                                                            isSearchable={false} // To disable search
                                                            name="attributeSelect"
                                                            id="ageGroupSattributeSelectelect"
                                                            onChange={e => { handleProductAttributeChange(e) }}

                                                        />
                                                    </th>
                                                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900" >
                                                        <Select
                                                            className="border-slate-200 dark:border-zink-500 focus:outline-none focus:border-custom-500 disabled:bg-slate-100 dark:disabled:bg-zink-600 disabled:border-slate-300 dark:disabled:border-zink-500 dark:disabled:text-zink-200 disabled:text-slate-500 dark:text-zink-100 dark:bg-zink-700 dark:focus:border-custom-800 placeholder:text-slate-400 dark:placeholder:text-zink-200"
                                                            options={productAttributeValues}
                                                            isSearchable={true} // To disable search
                                                            name="attributeValueSelect"
                                                            id="attributeValueSelect"
                                                            ref={productAttributeValueRef}
                                                            onChange={e => { handleProductAttributeValueChange(e) }}


                                                        />

                                                    </th>
                                                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">
                                                        <button type="button" onClick={addProductAttributes} className="text-white bg-green-500 border-green-500 btn hover:text-white hover:bg-green-600 hover:border-green-600 focus:text-white focus:bg-green-600 focus:border-green-600 focus:ring focus:ring-green-100 active:text-white active:bg-green-600 active:border-green-600 active:ring active:ring-green-100 dark:ring-green-400/10">Add</button>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {attributes?.map((attribute: any) => {
                                                    return (<tr key={"tr_" + attribute.attributeValue.value} className="odd:bg-white even:bg-slate-50 dark:odd:bg-zink-700 dark:even:bg-zink-600">
                                                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">{attribute.attribute.label}</td>
                                                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">{attribute.attributeValue.label}</td>
                                                        <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                                                            <Link to="#!"
                                                                className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-red-500 bg-red-100 hover:text-white hover:bg-red-500 dark:bg-red-500/20 dark:hover:bg-red-500"
                                                                onClick={() => {
                                                                    onAttributeDelete(attribute.attributeValue.value);
                                                                }}><Trash2 className="inline-block size-4 ltr:mr-1 rtl:ml-1" /> </Link>
                                                        </td>
                                                    </tr>
                                                    )
                                                })}

                                            </tbody>
                                        </table>
                                    </div>

                                    {validation.touched.attributes && validation.errors.attributes ? (
                                        <div id="attributes-error" className="mt-1 text-sm text-red-500">{validation.errors.attributes}</div>
                                    ) : null}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-1 xl:grid-cols-12 gap-x-5">
                    <div className="xl:col-span-12">

                        <div className="card">
                            <div className="card-body">
                                <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-12">
                                    <div className="xl:col-span-12">
                                        <button type="button" onClick={handleAddProductVariant} className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                            {"Add Product Variants"}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <ProductVariant ProductVariants={productVariants} onProductVariantDelete={onProductVariantDelete} onProductVariantEdit={(productVariant: any) => onProductVariantEdit(productVariant)} />
                        productvariants
                        <div className="flex justify-end gap-2 mt-4">
                            <button type="reset" className="text-red-500 bg-white btn hover:text-red-500 hover:bg-red-100 focus:text-red-500 focus:bg-red-100 active:text-red-500 active:bg-red-100 dark:bg-zink-700 dark:hover:bg-red-500/10 dark:focus:bg-red-500/10 dark:active:bg-red-500/10">Reset</button>
                            {!isSaving ?
                                <button type="submit" className="text-white transition-all duration-200 ease-linear btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                    {!!isEdit ? "Update Product" : "Add Product"}
                                </button>
                                : <button type="button" disabled className="flex items-center text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                                    <Loader2 className="size-4 ltr:mr-2 rtl:ml-2 animate-spin" />
                                    Saving...
                                </button>
                            }


                        </div>
                    </div>

                </div>
            </form>

            {/* User Modal  */}
            <Modal show={showModal} onHide={toggle} id="defaultModal" modal-center="true"
                className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
                dialogClassName="w-screen md:w-[50rem] bg-white shadow rounded-md dark:bg-zink-600">
                {productVariant ? <AddProductVariant onSave={onProductVariantSave} data={productVariant} onHide={toggle} ProductSizes={productSizes} brandDiscount={brandDiscount} /> : <></>}
            </Modal>

        </React.Fragment>
    );
};

export default AddProduct;