import DeleteModal from "Common/DeleteModal";
import { deleteProductVariant } from "helpers/backend_helper";
import { Pencil, Trash2 } from "lucide-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

// ProductPrice props:
interface ProductPriceProps {
    ProductVariants: any[];
    //onProductVariantSave: (ProductVariantList: any[]) => void;
    onProductVariantDelete: (status: boolean) => void;
    onProductVariantEdit: (productVariant: any) => void;
}


export const ProductVariant: React.FC<ProductPriceProps> = ({ ProductVariants, onProductVariantDelete, onProductVariantEdit }: ProductPriceProps) => {

    const [productVariantList, setProductVariantList] = useState<any[]>([]);

    // Delete Modal
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const deleteToggle = () => setDeleteModal(!deleteModal);
    const [deleteItem, setDeleteItem] = useState<any>({});;

    useEffect(() => {
        if (ProductVariants && ProductVariants.length > 0) { setProductVariantList(ProductVariants); }
    }, [ProductVariants]);


    console.log('productVariantList-2', productVariantList);

    useEffect(() => {
        loadProductColors();
    }, [])

    const loadProductColors = () => {
        const defaultColors = [
            { color: '#0ea5e9', selected: false },
            { color: '#f97316', selected: false },
            { color: '#249782', selected: false },
            { color: '#a855f7', selected: false },
            { color: '#eab308', selected: false },
            { color: '#ef4444', selected: false },
            { color: '#64748b', selected: false },
            { color: '#000000', selected: false },
        ]
        // combine defaultColors and productData?.productColor to get unique colors
        // const uniqueColors = [...defaultColors, ...(productData?.productColor?.map((color: string) => ({ color, selected: true })) || [])].filter((obj, index, self) =>
        //     index === self.findIndex((t) => t.color === obj.color));

        //const array3 = defaultColors.filter((obj) => productData?.productColor.indexOf(obj.color) === -1);
        console.log('uniqueColors', defaultColors)
        // setProductColors(defaultColors);
    }

    const handleDeleteProductVariant = async () => {
        console.log('deleteProductVariant', deleteItem);

        try {
            if (deleteItem?.id) {
                const response = await deleteProductVariant(deleteItem);
                console.log('deleteProductVariant', response);
                onProductVariantDelete(true);
                // remove from productVariantList
                setProductVariantList(productVariantList => productVariantList.filter((item: any, idx) => item.id !== deleteItem.id));
                setDeleteModal(true);
                setDeleteItem({});

            }
        } catch (error) {
            onProductVariantDelete(false);
        }

    }

    const editProductVariant = (editItem: any, index: number) => {
        // update the productVariantList
        const prodVarList = productVariantList.map((item: any, idx: number) => {
            //    if (item.id == editItem.id) {
            if (index == idx) {
                return { ...item, isEdit: true };
            }
            return { ...item, isEdit: false };
        });

        setProductVariantList(prodVarList);
        onProductVariantEdit(editItem);
        console.log('editProductVariant', editItem);
    }


    // Delete Data
    const onClickDelete = (cell: any) => {
        setDeleteModal(true);
        if (cell.id) {
            setDeleteItem(cell);
        }
    };

    return (

        <div className="card">
            <DeleteModal show={deleteModal} onHide={deleteToggle} onDelete={handleDeleteProductVariant} />
            <div className="card-body">
                <div className="grid grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-12">
                    <div className="xl:col-span-12 border-b border-dashed">
                        <table className="w-full bg-custom-50 dark:bg-custom-500/10">
                            <thead className="ltr:text-left rtl:text-right bg-custom-100 dark:bg-custom-500/10">
                                <tr>
                                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900" >Product Size</th>
                                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900" >Unit Price</th>
                                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900" >Discount</th>
                                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900" >Net Price</th>
                                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900" >Pack Of/Set</th>
                                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900" >Set Price</th>

                                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900" >MRP</th>
                                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900" >Margin</th>
                                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900" >Inventory</th>
                                    {/* <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900" style={{ width: '150px' }}  >Colors</th> */}
                                    <th className="px-3.5 py-2.5 font-semibold border-b border-custom-200 dark:border-custom-900">Action</th>
                                </tr>

                            </thead>
                            <tbody>
                                {productVariantList?.map((item, index) => (
                                    <ProductVariantRow key={"tr_" + index} index={index} productVariant={item} onCopyProductVariant={() => editProductVariant(item, index)} onDeleteProductVariant={() => onClickDelete(item)} />
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    )

}




interface ProductVariantRowProps {
    productVariant: any;
    index: number;
    onCopyProductVariant: (item: any, index: number) => void;
    onDeleteProductVariant: (item: any) => void;
}



const ProductVariantRow: React.FC<ProductVariantRowProps> = ({ productVariant, index, onCopyProductVariant, onDeleteProductVariant }) => {

    const getNetPrice = (productPrice: any) => {
        const unitPrice = productPrice.unitPrice || 0;
        const discount = productPrice.discountValue || 0;
        const netPrice = unitPrice - discount;
        return netPrice;
    }

    const getSetPrice = (productPrice: any) => {
        const packOf = productPrice.packOf || 0;
        //const netPrice = Math.round((getNetPrice(productPrice)*packOf) * 100) / 100  ;
        
        const netPrice =  parseFloat(`${(getNetPrice(productPrice)*packOf).toFixed(2)}`).toFixed(2) 
        return netPrice;
    }


    return (
        <tr key={"tr_PV_" + index} className="odd:bg-white even:bg-slate-50 dark:odd:bg-zink-700 dark:even:bg-zink-600" style={productVariant.isEdit ? { border: 'red 2px solid' } : {}}>
            <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">{productVariant?.productsizesProductvariants?.name}</td>
            <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">{productVariant?.unitPrice}</td>
            <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">{productVariant?.discountValue}{' (' + productVariant?.discountPercent + '%)'}</td>
            <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">{getNetPrice(productVariant)}</td>

            <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">{productVariant?.packOf}</td>
            <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">{getSetPrice(productVariant)}</td>
            <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">{productVariant?.mrp}</td>
            <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">{productVariant?.margin}</td>
            <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">{productVariant?.inventory}</td>
            <td className="px-3.5 py-2.5 border-y border-custom-200 dark:border-custom-900">
                <div className="flex justify-start gap-2" >
                    <Link to="#!"
                        className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-red-500 bg-red-100 hover:text-white hover:bg-red-500 dark:bg-red-500/20 dark:hover:bg-red-500"
                        onClick={() => {
                            onDeleteProductVariant(productVariant)
                        }}>
                        <Trash2 className="inline-block size-4 ltr:mr-1 rtl:ml-1" />
                    </Link>
                    {(productVariant?.id &&
                        <Link data-modal-target="addUserModal" to="#!"
                            className="flex items-center justify-center size-8 transition-all duration-200 ease-linear rounded-md text-slate-500 bg-slate-100 hover:text-white hover:bg-slate-500 dark:text-zink-200 dark:bg-zink-600 dark:hover:text-white dark:hover:bg-zink-400"
                            onClick={() => {
                                onCopyProductVariant(productVariant, index)
                            }}>
                            <Pencil className="inline-block size-4 ltr:mr-1 rtl:ml-1" />
                        </Link>
                    )}
                </div>
            </td>

        </tr>
    )
};





