import BreadCrumb from 'Common/BreadCrumb'
import React, { Component } from 'react'

export const ProductColorList = () => {
    return (
        <React.Fragment>
            <BreadCrumb title='Manage Product Color' pageTitle='Manage Product Color' />
        </React.Fragment>
    )
}
