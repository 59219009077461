import Modal from "Common/Components/Modal";
import { Pencil } from "lucide-react";
import React from "react";
import { SwatchesPicker } from "react-color";
import { Link } from "react-router-dom";

interface ProductColorProps {
    productColors: any[];
    onStatusChange: (color: string) => void;
     onColorSaved: () => void;
    // onHide: () => void;
}

export const ColorPallet: React.FC<ProductColorProps> = ({ productColors, onStatusChange, onColorSaved }) => {
    return (
        <React.Fragment>
            <Modal.Header className="flex items-center justify-between p-4 border-b dark:border-zink-300/20"
                closeButtonClass="transition-all duration-200 ease-linear text-slate-400 hover:text-red-500">
                <Modal.Title className="text-16">{"Color Pallet"}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="max-h-[calc(theme('height.screen')_-_180px)] p-4 overflow-y-auto">
                <div className="flex items-center justify-end" style={{ flexDirection: 'row', width: 600, height: 180, display: 'flex', flexWrap: 'wrap' }}>
                    {productColors?.map((item: any, index: number) => (
                        <div key={"colorPallet_"+index} style={{ flexDirection: 'column', width: 100, height: 50, marginLeft: 20 }}>
                            <div className="flex items-center justify-center size-6 border rounded-md border-slate-200 dark:border-zink-500"
                                style={{ background: item.color, width: 100, cursor: 'pointer', height: 30, borderRadius: 3, textAlign: 'center', marginRight: 5, verticalAlign: 'center', color: (item.selected ? '#fff' : item.color) }}
                                onClick={() => onStatusChange(item.color)}
                                key={"div_color_" + index} >
                                         &#10004;
                            </div>
                            <div>{item.color}</div>
                        </div>
                    ))}
                </div> 
                <div className="flex justify-end gap-2 mt-4">
                    {/* <button type="reset" onClick={onHide} data-modal-close="addDocuments" className="text-red-500 transition-all duration-200 ease-linear bg-white border-white btn hover:text-red-600 focus:text-red-600 active:text-red-600 dark:bg-zink-500 dark:border-zink-500" >Cancel</button> */}
                    <button type="button" onClick={onColorSaved} className="flex items-center text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                        Save
                    </button>
                </div>
            </Modal.Body>
        </React.Fragment>
    )
}

