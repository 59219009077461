import { Pencil } from "lucide-react";
import { SwatchesPicker } from "react-color";
import { Link } from "react-router-dom";
import Modal from "./Modal";
import { ColorPallet } from "pages/Components/Landing/Product/ColorPallet";
import { useState } from "react";

interface ProductColorProps {
    productColors: any[];
    onStatusChange: (color: string) => void;
    onColorSaved: () => void;
    showColorPallet: boolean,
    id:string
}

export const ProductColor: React.FC<ProductColorProps> = ({ id, productColors, onStatusChange, onColorSaved, showColorPallet }) => {
    const [showModal, setShowModal] = useState(false);

    return (
        <div className="flex items-center justify-center" id={id}>
            {productColors?.map((item: any, index: number) => (
                //.filter(p => p.selected)?.
                // <div className="flex items-center justify-center size-6 border rounded-md border-slate-200 dark:border-zink-500"
                //     style={{ borderColor: (item.selected ? '#3b82f6' : '#eee'), borderWidth: 1, width: 28, height: 28, borderRadius: 10, marginRight: 5, textAlign:'center',verticalAlign:'center' }}
                // >
                <div className="flex items-center justify-center size-6 border rounded-md border-slate-200 dark:border-zink-500"
                    style={{ background: item.color, width: 21, cursor: 'pointer', height: 21, borderRadius: 3, textAlign: 'center', marginRight: 5, verticalAlign: 'center', color: (item.selected ? '#fff' : item.color) }}
                    onClick={() => onStatusChange(item.color)}
                    key={"div_color_" + index}
                >
                    &#10004;
                </div>
                // </div>
            ))}

            {showColorPallet && (
                <Link to="#!" className="flex items-center justify-center size-5 border rounded-sm border-slate-200 dark:border-zink-500" style={{ borderRadius: 3, height: 21, width: 21 }} >
                    <Pencil className="size-3" onClick={() => setShowModal(true)} />
                </Link>
            )}
            {/* {showColorPicker && (
                <div style={{ position: "absolute", zIndex: 2 }}>
                    <SwatchesPicker onChangeComplete={(newColor: any) => onAddProductColor(newColor)} />
                </div>
            )} */}

            {
                showModal && (
                    <Modal show={showModal} onHide={() => { setShowModal(false) }} id="defaultModal" modal-center="true"
                        className="fixed flex flex-col transition-all duration-300 ease-in-out left-2/4 z-drawer -translate-x-2/4 -translate-y-2/4"
                        dialogClassName="w-screen md:w-[40rem] bg-white shadow rounded-md dark:bg-zink-600">
                        <ColorPallet key="colorPalletModal"
                            productColors={productColors}
                            onStatusChange={onStatusChange}
                            onColorSaved={() => { setShowModal(false); onColorSaved() }}
                        ></ColorPallet>
                    </Modal>
                )}
        </div>

    )
}

