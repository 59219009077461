import React, { useEffect, useState } from "react";
import BreadCrumb from "Common/BreadCrumb";

// Images
import avatar4 from "assets/images/users/avatar-4.png";

import delivery1 from "assets/images/brand/delivery-1.png";

// icons
import { Truck, CreditCard, CircleDollarSign, Download } from "lucide-react";
import { Link, useParams } from "react-router-dom";
import { cancelOrder, getOrderById } from "helpers/backend_helper";
import { format } from "date-fns"; // Icons
import { OrderDetailResponse, OrderLine, OrderStatus } from "types/OrderType";

//PDF
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  Image,
} from "@react-pdf/renderer";

//Logo
import logo from "./logo.png";
import { Common } from "helpers/common";
import DeleteModal from "Common/DeleteModal";
import { ToastContainer } from "react-toastify";

const OrderDetail = () => {
  const { orderId } = useParams() || "";
  console.log("orderId", orderId);
  const [orderDetail, setOrderDetail] = useState<OrderDetailResponse>(
    {} as OrderDetailResponse
  );

  const commonHelper = new Common();
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const cancelToggle = () => setCancelModal(!cancelModal);

  // Get Data
  useEffect(() => {
    onGetOrderDetail();
  }, []);

  const onGetOrderDetail = async () => {
    try {
      const response = await getOrderById(orderId || "");
      console.log("Order Data", response.data);
      setOrderDetail(response.data[0]);
    } catch (err) {}
  };


  const handleCancelOrder = async() => {
    console.log("cancel", orderId);
    try {

      if(orderId){
      const response = await cancelOrder(orderId || "");
      console.log("Order Data", response);
      setCancelModal(false);

      commonHelper.showErrorMessage(
        `The order number ${orderDetail.orderHeader.orderNo} has been cancelled successfully.`,
      );
      await onGetOrderDetail();
      }
    } catch (err:any) {
console.log('Error',err.response)

    }
  }

  const styles = StyleSheet.create({
    page: {
      padding: 40,
      fontFamily: "Helvetica",
      fontSize: 12,
      lineHeight: 1.6,
      color: "#000",
    },
    header: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: 30,
    },
    logo: {
      width: 60,
      height: 60,
    },
    companyDetails: {
      textAlign: "right",
    },
    title: {
      fontSize: 24,
      marginBottom: 20,
    },
    section: {
      marginBottom: 20,
    },
    flexRow: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 10,
    },
    shipping: {
      flexDirection: "row",
      marginBottom: 10,
      justifyContent: "space-between",
      marginRight: 400,
    },
    table: {
      display: "flex",
      width: "auto",
    },
    tableRow: {
      flexDirection: "row",
    },
    tableColHeader: {
      width: "20%",
      padding: 5,
      fontWeight: "bold",
      backgroundColor: "#f2f2f2",
    },
    tableCol: {
      width: "20%",
      padding: 5,
    },
    totalContainer: {
      flexDirection: "column",
      alignItems: "flex-end",
      marginTop: 20,
      paddingRight: 100,
    },
    totalAmount: {
      fontSize: 14,
      textAlign: "right",
    },
    totalLabel: {
      fontSize: 12,
      textAlign: "right",
      marginTop: 5,
    },
  });

  const Invoice = () => (
    <Document>
      <Page size="A3" style={styles.page}>
        <View style={styles.header}>
          <Image style={styles.logo} src={logo} />
          <View>
            <Text>E Commerce</Text>
            <Text>IT Software Company</Text>
          </View>
          <View style={styles.companyDetails}>
            <Text>Email: fake@email.com</Text>
            <Text>Website: www.fakesite.com</Text>
            <Text>Contact No: +(01) 123 678 9654</Text>
            <Text>Legal Registration No: 2155420</Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.title}>Invoice</Text>
          <View style={styles.flexRow}>
            <Text>Order Number: #{orderDetail?.orderHeader?.orderNo}</Text>
            <Text>
              Date:{" "}
              {new Date(orderDetail?.orderHeader?.orderDate).toLocaleDateString(
                "en-US",
                {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                }
              )}
            </Text>
            <Text>
              Payment Status:{" "}
              {orderDetail?.orderHeader?.orderStatus === "PAID" ? "Paid" : "Unpaid"}
            </Text>
            <Text>Total Amount: ${orderDetail?.orderHeader?.totalAmount}</Text>
          </View>
        </View>
        <View style={styles.shipping}>
          <View>
            <Text>Shipping Address:</Text>
            <Text>{orderDetail?.shippingAddress?.contactPerson}</Text>
            <Text>{orderDetail?.shippingAddress?.addressLine1}</Text>
            <Text>{orderDetail?.shippingAddress?.addressLine2}</Text>
            <Text>
              {orderDetail?.shippingAddress?.city},{" "}
              {orderDetail?.shippingAddress?.state}
            </Text>
            <Text>{orderDetail?.shippingAddress?.pinCode}</Text>
          </View>
          <View>
            <Text>Billing Address:</Text>
            <Text>{orderDetail?.orderUser?.userName}</Text>
            <Text>{orderDetail?.orderUser?.email}</Text>
            <Text>123 Fake Street</Text>
            <Text>City, Country</Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableColHeader}>#</Text>
            <Text style={styles.tableColHeader}>Item Name</Text>
            <Text style={styles.tableColHeader}>Quantity</Text>
            <Text style={styles.tableColHeader}>Rate</Text>
            <Text style={styles.tableColHeader}>Amount</Text>
          </View>
          {orderDetail?.orderLines?.map((item, index) => (
            <View style={styles.tableRow} key={`orderline-${item.lineId}`}>
              <Text style={styles.tableCol}>{index + 1}</Text>
              <Text style={styles.tableCol}>{item?.productName}</Text>
              <Text style={styles.tableCol}>{item?.quantity}</Text>
              <Text style={styles.tableCol}>{item?.unitPrice}</Text>
              <Text style={styles.tableCol}>{item?.totalAmount}</Text>
            </View>
          ))}
        </View>
        <View style={styles.totalContainer}>
          <Text style={styles.totalAmount}>
            ${orderDetail?.orderHeader?.totalAmount}
          </Text>
          <Text style={styles.totalLabel}>Total Amount</Text>
        </View>
      </Page>
    </Document>
  );
  return (
    <React.Fragment>
      <BreadCrumb title="Order Overview" pageTitle="Ecommerce" />
      <ToastContainer closeButton={false} limit={1} />

      <DeleteModal
        show={cancelModal}
        onHide={cancelToggle}
        onDelete={handleCancelOrder}
        message="Are you sure you want to cancel this order?"
        buttonCaption="Yes, Cancel this Order!"
      />
      <div className="grid grid-cols-1 2xl:grid-cols-12 gap-x-5">
        <div className="2xl:col-span-3">
          <div className="card">
            <div className="card-body">
              <div className="flex items-center justify-center size-12 bg-purple-100 rounded-md dark:bg-purple-500/20 ltr:float-right rtl:float-left">
                <Truck className="text-purple-500 fill-purple-200 dark:fill-purple-500/30" />
              </div>
              <h6 className="mb-4 text-15">Shipping Details</h6>
              <h6 className="mb-1">
                {orderDetail?.shippingAddress?.name || orderDetail?.orderUser?.userName}
              </h6>
              <p className="mb-1 text-slate-500 dark:text-zink-200">
                {orderDetail?.shippingAddress?.addressLine1},{" "}
                {orderDetail?.shippingAddress?.addressLine2}
              </p>
              <p className="text-slate-500 dark:text-zink-200">
                {orderDetail?.shippingAddress?.landmark},
                {orderDetail?.shippingAddress?.city},
                {orderDetail?.shippingAddress?.district}
              </p>
              <p className="text-slate-500 dark:text-zink-200">
                {orderDetail?.shippingAddress?.state},
                {orderDetail?.shippingAddress?.pinCode}
              </p>
            </div>
          </div>
        </div>
        <div className="2xl:col-span-3">
          <div className="card">
            <div className="card-body">
              <div className="flex items-center justify-center size-12 bg-orange-100 rounded-md dark:bg-orange-500/20 ltr:float-right rtl:float-left">
                <CreditCard className="text-orange-500 fill-orange-200 dark:fill-orange-500/30" />
              </div>
              <h6 className="mb-4 text-15">Billing Details</h6>

              <h6 className="mb-1">
                {orderDetail?.businessDetails?.businessName}
              </h6>
              <p className="mb-1 text-slate-500 dark:text-zink-200">
                {orderDetail?.businessDetails?.addressLine1},{" "}
                {orderDetail?.businessDetails?.addressLine2}
              </p>
              <p className="text-slate-500 dark:text-zink-200">
                {orderDetail?.businessDetails?.landmark},
                {orderDetail?.businessDetails?.cityName},
                {orderDetail?.businessDetails?.districtName}
              </p>
              <p className="text-slate-500 dark:text-zink-200">
                {orderDetail?.businessDetails?.stateName},
                {orderDetail?.businessDetails?.pinCode}
              </p>
            </div>
          </div>
        </div>

        <div className="2xl:col-span-3">
          <div className="card">
            <div className="card-body">
              <div className="size-12 bg-yellow-100 rounded-md ltr:float-right rtl:float-left dark:bg-yellow-500/20">
                <img src={avatar4} alt="" className="h-12 rounded-md" />
              </div>
              <h6 className="mb-4 text-15">Customer Info</h6>

              <h6 className="mb-1">{orderDetail?.orderUser?.userName}</h6>
              <p className="mb-1 text-slate-500 dark:text-zink-200">
                {orderDetail?.orderUser?.email}
              </p>
              <p className="text-slate-500 dark:text-zink-200">
                {orderDetail?.orderUser?.phone}
              </p>
              <p className="text-slate-500 dark:text-zink-200">
               Member since : {orderDetail?.orderHeader.orderDate}
              </p>
            </div>
          </div>
        </div>

        <div className="2xl:col-span-3">
          <div className="card">
            <div className="card-body">
              <div className="flex items-center justify-center size-12 rounded-md ltr:float-right rtl:float-left bg-sky-100 dark:bg-sky-500/20">
                <CircleDollarSign className="text-sky-500 fill-sky-200 dark:fill-sky-500/30" />
              </div>
              <h6 className="mb-4 text-15">Payment Details</h6>

              {/* <h6 className="mb-1">ID: #TSD456DF41SD5</h6> */}
              <p className="mb-1 text-slate-500 dark:text-zink-200">
                Payment Method:{" "}
                <b>{orderDetail?.orderHeader?.paymentMethod ?? "COD"}</b>
              </p>
              {/* <p className="mb-1 text-slate-500 dark:text-zink-200">Card Number: <b>xxxx xxxx xxxx 1501</b></p> */}
            </div>
          </div>
        </div>

      </div>

      <div className="grid grid-cols-1 2xl:grid-cols-12 gap-x-5">
        <div className="2xl:col-span-9">
          <div className="grid grid-cols-1 2xl:grid-cols-5 gap-x-5">
            <div>
              <div className="card">
                <div className="text-center card-body">
                  <h6 className="mb-1 underline">
                    #{orderDetail?.orderHeader?.orderNo}
                  </h6>
                  <p className="uppercase text-slate-500 dark:text-zink-200">
                    Order #
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="card">
                <div className="text-center card-body">
                  <h6 className="mb-1">
                    {format(
                      orderDetail?.orderHeader?.orderDate == null
                        ? new Date()
                        : new Date(orderDetail?.orderHeader?.orderDate),
                      "dd MMM, yyyy"
                    )}
                  </h6>
                  <p className="uppercase text-slate-500 dark:text-zink-200">
                    Order Date
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="card">
                <div className="text-center card-body">
                  <h6 className="mb-1">
                    {orderDetail?.orderHeader?.deliveryDate == null
                      ? "Not available"
                      : format(
                          new Date(orderDetail?.orderHeader?.deliveryDate),
                          "dd MMM, yyyy"
                        )}
                  </h6>
                  <p className="uppercase text-slate-500 dark:text-zink-200">
                    Delivery Date
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="card">
                <div className="text-center card-body">
                  <h6 className="mb-1">
                    ₹ {orderDetail?.orderHeader?.totalAmount}
                  </h6>
                  <p className="uppercase text-slate-500 dark:text-zink-200">
                    Order Amount
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="card">
                <div className="text-center card-body">

                  {orderDetail?.orderHeader?.orderStatus === "Order Canceled" ? (
                    <span
                      className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border 
                                    bg-red-100 border-red-200 text-red-500 dark:bg-red-500/20 dark:border-red-500/20"
                    >
                      {orderDetail?.orderHeader?.orderStatus}
                    </span>
                  ) : (
                    <span
                      className="delivery_status px-2.5 py-0.5 text-xs inline-block font-medium rounded border 
                                    bg-red-100 border-red-200 text-red-500 dark:bg-red-500/20 dark:border-red-500/20"
                    >
                      {orderDetail?.orderHeader?.orderStatus}
                    </span>
                  )}
                 
                  <p className="uppercase text-slate-500 dark:text-zink-200">
                    Order Status
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="flex items-center gap-3 mb-4">
                                <h6 className="text-15 grow">Order Summary</h6>
                                <Link to="#!" onClick={()=>setCancelModal(true)} className="text-red-500 underline shrink-0">Cancel Order</Link>
                            </div>
              <div className="overflow-x-auto">
                <table className="w-full">
                  <tbody>
                    {orderDetail?.orderLines?.map((item: OrderLine) => (
                      <tr key={`orderline-${item.lineId}`}>
                        <td className="px-3.5 py-4 border-b border-dashed first:pl-0 last:pr-0 border-slate-200 dark:border-zink-500">
                          <div className="flex items-center gap-3">
                            <div className="flex items-center justify-center size-12 rounded-md bg-slate-100 shrink-0">
                              <img src={item.imageUrl} alt="" className="h-8" />
                            </div>
                            <div className="grow">
                              <h6 className="mb-1 text-15">
                                <Link
                                  to="/product-overview"
                                  className="transition-all duration-300 ease-linear hover:text-custom-500"
                                >
                                  {item?.brandName}, {item?.productName}
                                </Link>
                              </h6>
                              <p className="text-slate-500 dark:text-zink-200">
                                Size : {item.size}, Set of :{item.packOf} Pcs
                              </p>
                              <p className="text-slate-500 dark:text-zink-200">
                                ₹{`(${item.unitPrice}`}{" "}
                                {` - ${item.discountValue} ) x ${item.packOf}(Pcs) x ${item.quantity}(Qty)`}
                              </p>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  flexDirection: "row",
                                }}
                              >
                                <div
                                  key={`$color-${100}`}
                                  style={{
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginRight: 10,
                                  }}
                                  className="mr-2 mb-2"
                                >
                                  Colors:
                                </div>
                                {item?.color.map(
                                  (itemColor: any, idx: number, arr: any[]) => {
                                    const lastElement = idx === arr.length - 1;
                                    const marginRight = lastElement ? 0 : 4;
                                    return (
                                      <div
                                        key={`$color-${idx}`}
                                        style={{
                                          width: 16,
                                          height: 16,
                                          backgroundColor: itemColor,
                                          borderRadius: 10,
                                          justifyContent: "center",
                                          alignItems: "center",
                                          marginRight: marginRight,
                                        }}
                                        className="mr-2 mb-2"
                                      ></div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="px-3.5 py-4 border-b border-dashed first:pl-0 last:pr-0 border-slate-200 dark:border-zink-500 ltr:text-right rtl:text-left">
                          ₹
                          {(item.unitPrice - item.discountValue) *
                            item.quantity *
                            item.packOf}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td className="px-3.5 pt-4 pb-3 first:pl-0 last:pr-0 text-slate-500 dark:text-zink-200">
                        Sub Total
                      </td>
                      <td className="px-3.5 pt-4 pb-3 first:pl-0 last:pr-0 ltr:text-right rtl:text-left">
                        ₹{orderDetail?.orderHeader?.subTotal}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3.5 py-3 first:pl-0 last:pr-0 text-slate-500 dark:text-zink-200">
                        Tax (5%)
                      </td>
                      <td className="px-3.5 py-3 first:pl-0 last:pr-0 ltr:text-right rtl:text-left">
                        ₹{orderDetail?.orderHeader?.tax}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3.5 py-3 first:pl-0 last:pr-0 text-slate-500 dark:text-zink-200">
                        Item Discounts
                      </td>
                      <td className="px-3.5 py-3 first:pl-0 last:pr-0 ltr:text-right rtl:text-left">
                        -₹{orderDetail?.orderHeader?.discount}
                      </td>
                    </tr>
                    <tr>
                      <td className="px-3.5 py-3 first:pl-0 last:pr-0 text-slate-500 dark:text-zink-200">
                        Shipping Charge
                      </td>
                      <td className="px-3.5 py-3 first:pl-0 last:pr-0 ltr:text-right rtl:text-left">
                        ₹{orderDetail?.orderHeader?.shippingCharges}
                      </td>
                    </tr>
                    <tr className="font-semibold">
                      <td className="px-3.5 pt-3 first:pl-0 last:pr-0 text-slate-500 dark:text-zink-200">
                        Total Amount
                      </td>
                      <td className="px-3.5 pt-3 first:pl-0 last:pr-0 ltr:text-right rtl:text-left">
                        ₹{orderDetail?.orderHeader?.totalAmount}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="flex items-center gap-3 mb-4">
                <h6 className="text-15 grow">Order Status</h6>
                <div className="shrink-0">
                  {/* <Link to="#!" className="inline-block text-red-500 underline ltr:mr-2 rtl:ml-2">Cancelled Order</Link> */}
                  {/* <Link
                    to="#"
                    className="py-1 text-xs text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20"
                  >
                    <Download className="inline-block size-4 align-middle ltr:mr-1 rtl:ml-1" />
                    <span className="align-middle">Invoice</span>
                  </Link> */}
                
                </div>
              </div>
              <div>
                {orderDetail?.orderStatus?.map((item: OrderStatus, index) => (
                  <div
                    key={`order-status-${index}`}
                    className="relative ltr:pl-6 rtl:pr-6 before:absolute ltr:before:border-l rtl:before:border-r ltr:before:left-[0.1875rem] rtl:before:right-[0.1875rem] before:border-slate-200 [&.done]:before:border-custom-500 before:top-1.5 before:-bottom-1.5 after:absolute after:size-2 after:bg-white after:border after:border-slate-200 after:rounded-full ltr:after:left-0 rtl:after:right-0 after:top-1.5 pb-4 last:before:hidden [&.done]:after:bg-custom-500 [&.done]:after:border-custom-500 done"
                  >
                    <div className="flex gap-4">
                      <div className="grow">
                        <h6 className="mb-2 text-gray-800 text-15 dark:text-zink-50">
                          {item?.statusName}
                        </h6>
                        <p className="text-gray-400 dark:text-zink-200">
                          {item?.comment}
                        </p>
                      </div>
                      <p className="text-sm text-gray-400 dark:text-zink-200 shrink-0">
                        {!item?.statusDate
                          ? ""
                          : format(
                              new Date(item?.statusDate),
                              "dd MMM yyyy, hh:mm a"
                            )}
                      </p>
                    </div>
                  </div>
                ))}

                {/* <div className="relative ltr:pl-6 rtl:pr-6 before:absolute ltr:before:border-l rtl:before:border-r ltr:before:left-[0.1875rem] rtl:before:right-[0.1875rem] before:border-slate-200 [&.done]:before:border-custom-500 before:top-1.5 before:-bottom-1.5 after:absolute after:size-2 after:bg-white after:border after:border-slate-200 after:rounded-full ltr:after:left-0 rtl:after:right-0 after:top-1.5 pb-4 last:before:hidden [&.done]:after:bg-custom-500 [&.done]:after:border-custom-500 done">
                                    <div className="flex gap-4">
                                        <div className="grow">
                                            <h6 className="mb-2 text-gray-800 text-15 dark:text-zink-50">Order Processing</h6>
                                            <p className="text-gray-400 dark:text-zink-200">Once the order is received, it goes through the processing stage. During this time, the items are gathered, and the order is prepared for shipment.</p>
                                        </div>
                                        <p className="text-sm text-gray-400 dark:text-zink-200 shrink-0">02 Nov, 2023</p>
                                    </div>
                                </div>
                                <div className="relative ltr:pl-6 rtl:pr-6 before:absolute ltr:before:border-l rtl:before:border-r ltr:before:left-[0.1875rem] rtl:before:right-[0.1875rem] before:border-slate-200 [&.done]:before:border-custom-500 before:top-1.5 before:-bottom-1.5 after:absolute after:size-2 after:bg-white after:border after:border-slate-200 after:rounded-full ltr:after:left-0 rtl:after:right-0 after:top-1.5 pb-4 last:before:hidden [&.done]:after:bg-custom-500 [&.done]:after:border-custom-500 done">
                                    <div className="flex gap-4">
                                        <div className="grow">
                                            <h6 className="mb-2 text-gray-800 text-15 dark:text-zink-50">Shipped Order</h6>
                                            <p className="text-gray-400 dark:text-zink-200">The order is shipped out to the customer's designated delivery address.</p>
                                        </div>
                                        <p className="text-sm text-gray-400 dark:text-zink-200 shrink-0">04 Nov, 2023</p>
                                    </div>
                                </div>
                                <div className="relative ltr:pl-6 rtl:pr-6 before:absolute ltr:before:border-l rtl:before:border-r ltr:before:left-[0.1875rem] rtl:before:right-[0.1875rem] before:border-slate-200 [&.done]:before:border-custom-500 before:top-1.5 before:-bottom-1.5 after:absolute after:size-2 after:bg-white after:border after:border-slate-200 after:rounded-full ltr:after:left-0 rtl:after:right-0 after:top-1.5 pb-4 last:before:hidden [&.done]:after:bg-custom-500 [&.done]:after:border-custom-500 done">
                                    <div className="flex gap-4">
                                        <div className="grow">
                                            <h6 className="mb-2 text-gray-800 text-15 dark:text-zink-50">Out for Delivery</h6>
                                            <p className="text-gray-400 dark:text-zink-200">This status indicates that the order is currently out for delivery by the shipping or courier company.</p>
                                        </div>
                                        <p className="text-sm text-gray-400 dark:text-zink-200 shrink-0">06 Nov, 2023</p>
                                    </div>
                                </div>
                                <div className="relative ltr:pl-6 rtl:pr-6 before:absolute ltr:before:border-l rtl:before:border-r ltr:before:left-[0.1875rem] rtl:before:right-[0.1875rem] before:border-slate-200 [&.done]:before:border-custom-500 before:top-1.5 before:-bottom-1.5 after:absolute after:size-2 after:bg-white after:border after:border-slate-200 after:rounded-full ltr:after:left-0 rtl:after:right-0 after:top-1.5 pb-4 last:before:hidden [&.done]:after:bg-custom-500 [&.done]:after:border-custom-500 done">
                                    <div className="flex gap-4">
                                        <div className="grow">
                                            <h6 className="mb-2 text-gray-800 text-15 dark:text-zink-50">Delivered</h6>
                                            <p className="text-gray-400 dark:text-zink-200">Finally, when the order successfully reaches the customer's address and is handed over, the status changes to "Delivered."</p>
                                        </div>
                                        <p className="text-sm text-gray-400 dark:text-zink-200 shrink-0">09 Nov, 2023</p>
                                    </div>
                                </div>  */}
              </div>
            </div>
          </div>
        </div>
        <div className="2xl:col-span-3">
          <div className="card">
          <div className="card-body">
          <div className="flex items-center gap-3 mb-4">
                <h6 className="text-15 grow">Invoice</h6>
                <div style={{ textAlign: "center", marginTop: "0px" }}>
                    <PDFDownloadLink
                      document={<Invoice />}
                      fileName="invoice.pdf"
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? (
                          "Loading document..."
                        ) : (
                          <button className="py-1 text-xs text-white btn bg-custom-500 border-custom-500 hover:text-white hover:bg-custom-600 hover:border-custom-600 focus:text-white focus:bg-custom-600 focus:border-custom-600 focus:ring focus:ring-custom-100 active:text-white active:bg-custom-600 active:border-custom-600 active:ring active:ring-custom-100 dark:ring-custom-400/20">
                            Download Invoice
                          </button>
                        )
                      }
                    </PDFDownloadLink>
                  </div>
              </div>
              </div>

           
          </div>
          {/* <div className="card">
            <div className="card-body">
              <div className="flex items-center gap-3 mb-4">
                <h6 className="text-15 grow">Logistics Details</h6>
                <Link to="#!" className="underline text-custom-500 shrink-0">
                  Track Order
                </Link>
              </div>
              <div className="flex gap-4">
                <div className="shrink-0">
                  <img src={delivery1} alt="" className="h-10" />
                </div>
                <div className="grow">
                  <h6 className="mb-1 text-15">Express Delivery</h6>
                  <p className="text-slate-500 dark:text-zink-200">
                    ID: EDTW1400457854
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrderDetail;
